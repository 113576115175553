import { DataAccessService } from './../../data-access.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { navItems } from './../../_nav';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit,OnDestroy {
  isShow = true;
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  client_name;
  role;
  isSalesman = false;
  isAdmin = false;
  isPurchase = false;
  isAdministrator = false;
  isGeneral = false;

  aushadhmitra_card_no = '';
  customer_name = '';
  customer_address = '';
  customer_contact = '';

  available_points = 0;
  bill_amt = 0;
  earned_points = 0;
  redeem_points = 0;
  balance_points = 0;
  planType= '';
  constructor(private service: DataAccessService, private http: HttpClientModule) {
    this.client_name = localStorage.getItem('name');
    this.planType = localStorage.getItem('planType');
    this.role = localStorage.getItem('role');
    if (this.role === 'Admin') {
      this.isAdmin = true;
      this.isSalesman = false;
      this.isPurchase = false;
      if (localStorage.getItem('isgeneral') === 'yes') {
        this.isGeneral = true;
      }
    } else if (this.role === 'Salesman') {
      this.isSalesman = true;
      this.isPurchase = false;
      this.isAdmin = false;
    } else if (this.role === 'Purchase') {
      this.isPurchase = true;
      this.isSalesman = false;
      this.isAdmin = false;
    } else if (this.role === 'Administrator') {
      this.isAdministrator = true;
    }

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: [ 'class' ]
    });
  }

 
  private intervalId: any;

 
  ngOnInit(): void {
     this.startTimer();
     this.remDays();
   }

  startTimer() {
    this.intervalId = setInterval(() => {
      this.checkAndCallCalculation();
    }, 60 * 1000); // Check every minute
  }

  checkAndCallCalculation() {
    const now = new Date();
     if (now.getHours() === 11 && now.getMinutes() === 0) {
      this.remDays();
    }
  }





  remDays() {
    this.service.get('login.php?type=getRemeningDays').subscribe(response => {
      if (response['status'] === 'success') {
         this.remainingDays = response['remainingDays'];
      }  
    });
  }




 
  OpenPayment(){
    window.open('https://gmpsoftwareindia.com/pricing.html', '_blank');
  }




  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  logout() {
    this.service.logout();
  }



  remainingDays = 0;



   

  getPointDetails(value) {
    value = value.replace(/\s/g, '');
    this.aushadhmitra_card_no = value;
    const len = value.length;
    if (len >= 6) {
     this.service.get('aushadhmitra.php?type=getPointDetails&card_no=' + value).subscribe(response => {
        if (response['status'] === 'success') {
          this.available_points = (response['earned_points']).toFixed(2);
          this.customer_name = response['customer_name'];
          this.customer_contact = response['contact_no'];
          this.customer_address = response['address'];
        } else {
          this.aushadhmitra_card_no = '';
          alert('CARD NUMBER IS NOT VALID');
        }
     });
    }
  }

  calculation() {
    this.earned_points = parseFloat(((this.bill_amt * 30) / 100).toFixed(2));

    const point_redeem = parseFloat(((this.bill_amt * 12) / 100).toFixed(2));
    if (this.available_points >= point_redeem) {
      this.redeem_points = point_redeem;
    } else {
      this.redeem_points = this.available_points;
    }
    this.balance_points = this.earned_points + (this.available_points - this.redeem_points);
  }

  savePoints(data) {
    this.service.post('aushadhmitra.php?type=savePoints', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'success') {
        this.aushadhmitra_card_no = '';
        this.customer_name = '';
        this.customer_address = '';
        this.customer_contact = '';
        this.available_points = 0;
        this.bill_amt = 0;
        this.earned_points = 0;
        this.redeem_points = 0;
        this.balance_points = 0;
        alert('BILL HAS BEEN SAVED SUCCESSFULLY');
      } else {
        alert('AN ERROR OCCURED, PLEASE TRY AGAIN');
      }
    });
  }
}
