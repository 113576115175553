import { DataAccessService } from './../data-access.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectComponent } from 'ng-select';

@Component({
  selector: 'app-debit-note',
  templateUrl: './debit-note.component.html',
  styleUrls: ['./debit-note.component.scss']
})
export class DebitNoteComponent implements OnInit {
  @ViewChild('prod') selectComponent: SelectComponent;
  debitnotes;
  return_to = 'Debit Note';
  isFirst = true;
  products;
  batches;
  distributors;
  batch_nos = [];
  distributors1 = [];
  invoice_date;
  invoice_no;
  exp_date;
  t_qty = 0;

  returnProducts = [];
  index = 0;

  tempList = {};
  p_name;
  batch_no;
  distributor_name;
  return_qty = 0;
  reason = 'Expiry';
  isSecond = false;
  debitNotes1 = [];
  prodName = '';


  net_rate = 0;
  hsn = '';
  id = 0;
  pack_size = '';
  po_no = 0;
  gst_per = 0;
  wAvailableQty = 0;


 

 
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getDebitNotes();
    this.getDetails();
    
  }

 

 
  getDebitNotes() {
    this.service.get('return.php?type=getDebitNotes').subscribe(response => {
      this.debitnotes = response;
      this.debitNotes1 = this.debitnotes;   
     });
  }


 


  selectedResult=[];

  view(i){

    this.selectedResult = this.debitnotes[i];
    this.isView = true;

  }

  isView = false;


  getDetails() {
    this.distributor_name = '';
    this.batch_no = '';
    this.t_qty = 0;
    this.exp_date = '';
    this.invoice_date = '';
    this.invoice_no = '';
    this.service.get('return.php?type=getProducts').subscribe( response => {
      this.products = response;
    });
  }

  getBatches(event) {
    this.prodName = event.value;
    this.service.get('return.php?type=getBatches&p_name=' + event.value).subscribe(response => {
      this.batches = response;
    });
  }
  selectedBatch =[];
  getDistributors(index) {
    index = index - 1;

    this.selectedBatch = this.batches[index];
    this.t_qty = this.batches[index].wAvailableQty;
    this.exp_date = this.batches[index].expiry;
    this.net_rate = this.batches[index].net_rate;
    this.hsn = this.batches[index].hsn;
    this.id = this.batches[index].id;
    this.pack_size = this.batches[index].pack_size;
    this.po_no = this.batches[index].po_no;
    this.gst_per = this.batches[index].gst_per;
    this.wAvailableQty = this.batches[index].wAvailableQty;
    // tslint:disable-next-line:max-line-length
    this.service.get('return.php?type=getDistributors&p_name=' + this.prodName + '&batch_no=' + this.batches[index].batch_no).subscribe(response => {
      this.distributors = response;
    });
  }

  getInvoiceDetails(index) {
    index = index - 1;
    this.invoice_date = this.distributors[index].invoice_date;
    this.invoice_no = this.distributors[index].invoice_no;
    this.d_gst = this.distributors[index].d_gst;
    this.d_cont = this.distributors[index].d_cont;
    this.d_mail = this.distributors[index].d_mail;
    this.state = this.distributors[index].state;
    this.d_add = this.distributors[index].d_add;
    this.d_no = this.distributors[index].d_no;
  }

  netTotal= 0;
  sgst= 0;
  cgst= 0;
  igst= 0;
  tax= 0;
  taxable= 0;
  d_mail = '';
  d_gst = '';
  d_cont = '';
  state = '';
  d_no = '';
  d_add = '';

  

  checkQty(value){
    const enteredValue = Number(value);  // Force value to be treated as a number
    if(enteredValue > this.t_qty ){
      alert('Return Qty. Cant  Be Greater Than Sale Qty. !!!!!!!!!');
      this.return_qty = 0;
      return;
    }else{
      this.calculate();
    }
  }


  
  calculate(){
     this.taxable = parseFloat(((this.net_rate * this.return_qty)).toFixed(2));
    this.tax = parseFloat((this.taxable  * (this.gst_per / 100)).toFixed(2));
    if(this.state == localStorage.getItem('state')){
      this.igst =  0;
      this.sgst = this.tax / 2;
      this.cgst = this.tax / 2;
    }else{
      this.igst =  this.tax;
      this.sgst = 0;
      this.cgst = 0;
    }
    this.netTotal = parseFloat((this.taxable  + this.tax).toFixed(2));
  }

  isVerify = false;

  filterProduct(){

    const data = this.returnProducts.slice();   // Copy of the original data
    const filterData = [];
    const distributorMap = {};

    // Group products by distributor_name
    data.forEach(product => {
        const distributorName = product.distributor_name;

        // If distributor doesn't exist in the map, initialize it
        if (!distributorMap[distributorName]) {
            distributorMap[distributorName] = {
                distributor_name: product.distributor_name,
                state: product.state,
                d_cont: product.d_cont,
                d_gst: product.d_gst,
                d_mail: product.d_mail,
                d_add: product.d_add,
                d_no: product.d_no,
                products: [],
                taxable:0,
                tax:0,
                netTotal:0,
            };
        }
        // Push the product to the corresponding distributor's products array
        distributorMap[distributorName].products.push(product);

        distributorMap[distributorName].taxable += product.taxable || 0;
        distributorMap[distributorName].tax += product.tax || 0;
        distributorMap[distributorName].netTotal += product.netTotal || 0;
    });

    // Convert the distributor map to an array of objects
    for (const distributor in distributorMap) {
        filterData.push(distributorMap[distributor]);
    }

    console.log(filterData);
    this.finalData = filterData;
    this.isVerify = true;
  }

  finalData = [];







  addProduct() {
 
    this.tempList['invoice_date'] = this.invoice_date;
    this.tempList['invoice_no'] = this.invoice_no;
    this.tempList['distributor_name'] = this.distributor_name;
    this.tempList['p_name'] = this.p_name;
    this.tempList['batch_no'] = this.batch_no;
    this.tempList['net_rate'] = this.net_rate;
    this.tempList['saleQty'] = this.t_qty;
    this.tempList['availableQty'] = this.wAvailableQty;
    this.tempList['exp_date'] = this.exp_date;
    this.tempList['taxable'] = this.taxable;
    this.tempList['gst_per'] = this.gst_per;
    this.tempList['tax'] = this.tax;
    this.tempList['igst'] = this.igst;
    this.tempList['cgst'] = this.cgst;
    this.tempList['sgst'] = this.sgst;
    this.tempList['netTotal'] = this.netTotal;
    this.tempList['return_qty'] = this.return_qty;
    this.tempList['reason'] = this.reason;
    this.tempList['po_no'] = this.po_no;
    this.tempList['pack_size'] = this.pack_size;
    this.tempList['pID'] = this.id;
    this.tempList['hsn'] = this.hsn;
    this.tempList['state'] = this.state;
    this.tempList['d_cont'] = this.d_cont;
    this.tempList['d_gst'] = this.d_gst;
    this.tempList['d_mail'] = this.d_mail;
    this.tempList['d_add'] = this.d_add;
    this.tempList['d_no'] = this.d_no;
 
    this.returnProducts[this.index] = this.tempList;
    this.index++;

    this.tempList = {};
    this.p_name = '';
    this.batch_no = '';
    this.distributor_name = '';
    this.t_qty = 0;
    this.exp_date = '';
    this.invoice_date = '';
    this.invoice_no = '';
    this.hsn = '';
    this.pack_size = '';
    this.return_qty = 0;
    this.d_mail = '';
    this.d_gst = '';
    this.d_cont = '';
    this.state = '';
    this.d_no = '';
    this.d_add = '';
    this.id = 0;
    this.po_no = 0;
    this.sgst = 0;
    this.cgst = 0;
    this.igst = 0;
    this.tax = 0;
    this.gst_per = 0;
    this.taxable = 0;
    this.net_rate = 0;
    this.netTotal = 0;
    this.selectComponent.open();
  }

  saveReturnForm(index) {
    this.service.post('return.php?type=saveDebitReturn&entry_by='+localStorage.getItem('cl_saler_name'), 
    JSON.stringify(this.finalData[index]))
    .subscribe(response => {
      if (response['status'] === 'success') {
      
        this.index = 0;
        this.tempList = {};
        this.p_name = '';
        this.batch_no = '';
        this.distributor_name = '';
        this.t_qty = 0;
        this.exp_date = '';
        this.invoice_date = '';
        this.invoice_no = '';
        this.getDebitNotes();
         
        // this.isSecond = false;
        // this.isVerify = false;
        // this.isFirst = true;
        this.finalData.splice(index,1);

        alert("Debit Note Saved Successfully !!!!! "+response['order_no']);

      } else {
        alert(response['status']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  checkReturn(value) {
    if (value === 'Debit Note') {
      this.isFirst = true;
    } else {
      this.isFirst = false;
    }
  }

  filterTable(value) {
    this.debitNotes1 = [];
    let index = 0;
    const length = Object.keys(this.debitnotes).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.debitnotes[i].p_name + ' ' + this.debitnotes[i].batch_no + ' ' + this.debitnotes[i].distributor_name + ' ' + this.debitnotes[i].t_qty + ' ' + this.debitnotes[i].exp_date + ' ' + this.debitnotes[i].invoice_date + ' ' + this.debitnotes[i].invoice_no + ' ' + this.debitnotes[i].return_qty + ' ' + this.debitnotes[i].reason).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.debitNotes1[index] = this.debitnotes[i];
        index++;
      }
    }
  }

  showForm() {
    this.isFirst = false;
    this.isSecond = true;
    this.returnProducts = [];
    this.isVerify = false;
    this.index = 0;
  }

  closeForm() {
    this.isSecond = false;
    this.isVerify = false;
    this.isFirst = true;
    this.finalData = [];
  }

  deletProd(index) {
    this.returnProducts.splice(index, 1);
    this.index--;
  }

  viewOrder() {
    
    window.open(this.service.url1 + 'debitNote.php?order_no=' + this.selectedResult['order_no'] + '&id=' + localStorage.getItem('id') + '&user=' + localStorage.getItem('cl_saler_name'), '_blank');
  
  }









}
