import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
@Component({
  selector: 'app-myacc',
  templateUrl: './myacc.component.html',
  styleUrls: ['./myacc.component.css']
})
export class MyaccComponent implements OnInit {

  constructor(private service: DataAccessService) { }

  client_name = '';
  ngOnInit(): void {
    this.client_name = localStorage.getItem('name');

    this.getClientProfile();
  }




  clientData;
  getClientProfile() {
    this.service.get('master.php?type=getClientProfile')
    .subscribe(response => {
      this.clientData = response;
    });
  }


 
  saveClient(data) {

    if(!data.valid){
      alert("All Field Required !!!!!");
      return;
    }
    let temp =  data.value;

    this.service.post('master.php?type=saveClient', JSON.stringify(temp))
    .subscribe(response => {
      if (response['status'] === 'success') {
         
        this.getClientProfile();
        alert('Data Updated Successfully.');
      } else {
        alert(response['status']);
      }
      });
  }



}
