import { DataAccessService } from '../../../data-access.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SelectComponent } from 'ng-select';
declare var $: any;

@Component({
  selector: 'app-po-customize',
  templateUrl: './po-customize.component.html',
  styleUrls: ['./po-customize.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PoCustomizeComponent implements OnInit {
  @ViewChild('prod') selectComponent: SelectComponent;
  @ViewChild('distributor') selectComponent1: SelectComponent;

  isShow = true;
  isFirst = false;
  isAutoPO = false;
  isCustomize = false;

  finalResult;
  order_no;

  p_name;
  pack_size;
  batch_no;
  qty = 0;
  disc = 0;
  mrp = 0;
  pack_mrp = 0;
  shelf;
  p_cat;
  gst = 0;
  hsn = '';
  unit_net_rate = 0;
  expiry_date = '';
  templist = {};
  batch_qty = 0;
  total_qty = 0;

  taxable_total: number;
  disc_total: number;
  tax_total: number;
  net_total: number;
  profit_total: number;
  isCard = false;

  products;
  products1;
  patients;
  doctors;
  banks;
  productList = [];
  index = 0;
  patient_no = '';
  order_date;
  cash_credit = 'Cash';
  isCounter = false;
  patient_from = 'Regular';
  patient_email;
  patient_contact;
  patient_address;
  disc_per = 0;
  reminder_days = 0;
  patinet_name = '';
  doctor_name = '';
  d_name;
  po_type = 'Customize';
  dists;
  isDists = false;
  dist_name;
  isNewProduct = false;
  distributors;
  dist_name_auto;
  p_name_auto;
  qty_auto;
  sale_products;
  avgs;
  avg_days = 0;
  avg_sale_qty = 0;
  sales;
  sales1 = [];
  allProducts;
  allDists;
  custPo_d_name;
  custPo_p_name;
  custPo_qty;

  selectedProducts;
  selectedProduct = [];
  isNewPO = false;
  constructor(private service: DataAccessService, private modalService: NgbModal) {
   }

  ngOnInit() {
    // this.getDetails();
    this.getDistId();
    this.getAllDists();
    this.getAllProducts();
    this.getPurchaseDistributors();
  }

  showAUTO() {
    this.isShow = false;
    this.isAutoPO = true;
  }

  getPurchaseDistributors() {
    this.service.get('purchase.php?type=getPurchaseDistributors').subscribe(response => {
      this.distributors = response;
    });
  }

  checkProduct(event) {
    const length = Object.keys(this.allProducts).length;
    for (let i = 0; i < length; i++) {
      if (this.allProducts[i].p_name === event.p_name) {
        this.selectedProduct = this.allProducts[i];
        break;
      }
    }
  }

  getDetails() {
    this.service.get('purchase.php?type=getProducts')
    .subscribe(response => {
      this.allProducts = response;
    });
  }
  getAllDists() {
    this.service.get('master.php?type=getdistDetails')
    .subscribe(response => {
      this.allDists = response;
    });
  }
  getAllProducts() {
    this.service.get('master.php?type=getProductsDetails')
    .subscribe(response => {
      this.allProducts = response;
    });
  }
  getPOCustomise() {
    this.service.get('master.php?type=getPOCustomise').subscribe(data => {
      this.sales = data;
      this.sales1 = this.sales;
     // console.log('from pocustmize' + this.sales1);
    });
  }

  getDistId() {
    this.service.get('master.php?type=getDistributorProducts&distributor=abcd' ).subscribe(data => {
      this.selectedProducts = data;
    });
  }


  getAvgDetails(prod_name) {
    this.service.get('master.php?type=getAvgDetails&prod_name=' + prod_name).subscribe(data => {
      const days = data['sold_days'];
      this.avg_days = +days;
      this.avg_sale_qty = data['sold_qtys'];
    });
  }
  getProductDetails(value, index) {
    const length = Object.keys(this.products).length;
    for (let i = 0; i < length; i++) {
      if (this.products[i].p_name === value) {
        this.batch_no = this.products[i].batch_no;
        this.mrp = this.products[i].unit_mrp;
        this.p_cat = this.products[i].p_cat;
        this.shelf = this.products[i].shelf;
        this.gst = this.products[i].gst_per;
        this.batch_qty = this.products[i].t_qty;
        this.pack_size = this.products[i].pack_size;
        this.expiry_date = this.products[i].expiry;
        this.unit_net_rate = this.products[i].unit_net_rate;
        this.pack_mrp = this.products[i].mrp;
        this.hsn = this.products[i].hsn;
        break;
      }
    }
    index = index - 1;
    this.total_qty = this.products1[index].t_qty;
  }

  getProductDistributorDetails(p_name) {
    this.service.get('master.php?type=getDistsDetails&p_name=' + p_name)
    .subscribe(response => {
      this.isDists = true;
      this.dists = response;
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  checkCashCredit(value) {
    if (value === 'Card') {
      this.isCard = true;
    } else {
      this.isCard = false;
    }
  }

  checkevent(event) {
    // tslint:disable-next-line:max-line-length
    if (this.p_name !== '' && this.qty !== 0) {
      this.addProduct();
    }
  }

  checkevent_custPo() {
    if (this.custPo_p_name !== '' && this.custPo_qty > 0) {
      this.templist['custPo_p_name'] = this.custPo_p_name;
      this.templist['custPo_qty'] = this.custPo_qty;
      this.templist['custPo_d_name'] = this.custPo_d_name;
      this.templist['p_cat'] = this.selectedProduct['p_cat'];
      this.templist['pack_size'] = this.selectedProduct['pack_size'];
      this.templist['d_form'] = this.selectedProduct['d_form'];
      this.templist['mrp'] = this.selectedProduct['mrp'];
      this.templist['hsn'] = this.selectedProduct['hsn'];
      this.templist['m_name'] = this.selectedProduct['m_name'];
      this.templist['generic'] = this.selectedProduct['generic'];
      this.productList[this.index] = this.templist;
      this.index++;
      this.custPo_p_name = '';
      this.custPo_qty = 0;
      this.templist = {};
      this.selectedProduct = [];
      this.selectComponent.open();
    } else {
      alert('All fields are compulsory!');
    }
  }

  checkevent_auto(event) {
    console.log('click event auto');
   //  this.addProduct();
    // tslint:disable-next-line:max-line-length
    if (this.p_name_auto !== '' && this.qty_auto !== 0) {
      this.addProduct_auto();
    }
  }

  addProduct() {
    if (this.p_name === '' || this.qty === 0) {
      alert('All fields are compulsory');
      return;
    }
    if (this.qty > this.batch_qty) {
      const p_name = this.p_name;
      let qty = this.qty;
      const length = Object.keys(this.products).length;
      for (let i = 0; i < length; i++) {
        if (this.products[i].p_name === p_name) {
          console.log(this.products[i]);
          if (qty > this.products[i].t_qty) {
            this.qty = this.products[i].t_qty;
            qty = qty - this.products[i].t_qty;
          } else if (qty > 0 && qty <= this.products[i].t_qty) {
            this.qty = qty;
            qty = this.products[i].t_qty - qty;
          } else if (qty <= 0) {
            break;
          }
          this.p_name = p_name;
          this.batch_no = this.products[i].batch_no;
          this.dist_name = this.products[i].dist_name;
          this.mrp = this.products[i].unit_mrp;
          this.shelf = this.products[i].shelf;
          this.p_cat = this.products[i].p_cat;
          this.pack_size = this.products[i].pack_size;
          this.gst = this.products[i].gst_per;
          this.expiry_date = this.products[i].expiry;
          this.hsn = this.products[i].hsn;
          this.addToList();
        }
      }
    } else {
      this.addToList();
    }
  }

  addProduct_auto() {
    if (this.p_name_auto === '' || this.qty_auto === 0) {
      alert('All fields are compulsory');
      return;
    }
    if (this.qty_auto > this.batch_qty) {
      const p_name = this.p_name;
      let qty = this.qty;
      const length = Object.keys(this.products).length;
      for (let i = 0; i < length; i++) {
        if (this.products[i].p_name === p_name) {
          console.log(this.products[i]);
          if (qty > this.products[i].t_qty) {
            this.qty = this.products[i].t_qty;
            qty = qty - this.products[i].t_qty;
          } else if (qty > 0 && qty <= this.products[i].t_qty) {
            this.qty = qty;
            qty = this.products[i].t_qty - qty;
          } else if (qty <= 0) {
            break;
          }
          this.p_name = p_name;
          this.batch_no = this.products[i].batch_no;
          this.dist_name = this.products[i].dist_name;
          this.mrp = this.products[i].unit_mrp;
          this.shelf = this.products[i].shelf;
          this.p_cat = this.products[i].p_cat;
          this.pack_size = this.products[i].pack_size;
          this.gst = this.products[i].gst_per;
          this.expiry_date = this.products[i].expiry;
          this.hsn = this.products[i].hsn;
          this.addToList();
        }
      }
    } else {
      this.addToList();
    }
  }

  getCategory(value) {
    if (value === 'SCHEDULE H') {
      return 'green';
    } else if (value === 'SCHEDULE H1') {
      return 'red';
    }
  }

  savePO() {
   this.service.post('master.php?type=savePO', JSON.stringify(this.productList))
    .subscribe(response => {
      this.productList = [];
      this.dists = [];
      this.qty = 0;
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  savePO_custPO() {
    this.service.post('master.php?type=savePO', JSON.stringify(this.productList))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.productList = [];
        this.index = 0;
        this.custPo_p_name = '';
        this.custPo_qty = 0;
        this.closeNewForm();
        alert('Purchase Order saved successfully. PO NO: ' + response['po_no']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  getSelectedProduct_id(selected_id, e) {
    this.selectedProducts[selected_id].check_val = e.target.checked;
  }

  getChanged_req_qty(id, getVal) {
    this.selectedProducts[id].req_qty = getVal;
  }

  savePO_auto(index) {
    this.service.post('master.php?type=savePO_auto', JSON.stringify(this.finalData[index]))
    .subscribe(response => {
      if (response['status'] === 'success') {
        
        
        alert('Purchase Order saved successfully. PO NO: ' + response['po_no']);

        this.finalData.splice(index,1);


        if(this.finalData?.length == 0){
          this.closeNewAuto();
        }




      } else {
        alert('An error has occurred.');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  checkPOtype(value) {
    if (value === 'Customize') {
      this.isNewProduct = false;
      this.isCustomize = true;
    } else if (value === 'Auto') {
      this.isCustomize = false;
      this.isNewProduct = false;
    } else if (value === 'New Product') {
      this.isNewProduct = true;
      this.isCustomize = false;
    }
  }

  open(content) {
    this.modalService.open(content, { windowClass: 'my-class', backdrop: 'static' });
  }

  close() {
    delete this.finalResult;
    this.modalService.dismissAll();
  }

  print() {
    window.open(this.service.url1 + 'sales_print.php?id=' + this.order_no, '_blank');
    this.close();
    const element1 = document.getElementById('p_name') as HTMLElement;
    element1.focus();
  }

  checkQty() {
    if (this.total_qty < this.qty) {
      alert('Product Total Qty is ' + this.total_qty);
      this.qty = this.total_qty;
      const element1 = document.getElementById('qty') as HTMLElement;
      element1.focus();
    }
  }

  addToList() {
    this.templist['p_name'] = this.p_name;
    this.templist['dist_name'] = this.dist_name;
    this.templist['batch_no'] = this.batch_no;
    this.templist['qty'] = this.qty;
    this.templist['disc'] = this.disc;
    this.templist['mrp'] = this.pack_mrp;
    this.templist['shelf'] = this.shelf;
    this.templist['p_cat'] = this.p_cat;
    this.templist['pack_size'] = this.pack_size;
    this.templist['gst'] = this.gst;
    this.templist['expiry_date'] = this.expiry_date;
    this.templist['hsn'] = this.hsn;
    this.templist['taxable'] = ((this.mrp / (1 + (this.gst / 100))) * this.qty).toFixed(2);
    this.templist['disc_amt'] = ((this.qty * this.mrp) * (this.disc / 100)).toFixed(2);
    this.templist['tax'] = (((this.mrp / ( 1 + (this.gst / 100))) * this.qty) * (this.gst / 100)).toFixed(2);
    this.templist['sgst'] = (((this.mrp / ( 1 + (this.gst / 100))) * this.qty) * (this.gst / (100 * 2))).toFixed(2);
    this.templist['cgst'] = (((this.mrp / ( 1 + (this.gst / 100))) * this.qty) * (this.gst / (100 * 2))).toFixed(2);
    // tslint:disable-next-line:max-line-length
    this.templist['net'] = ((this.qty * this.mrp) - (this.qty * this.mrp) * (this.disc / 100)).toFixed(2);
    this.templist['profit'] = ((this.qty * this.mrp) - (this.qty * this.mrp) * (this.disc / 100)).toFixed(2);
    this.productList[this.index] = this.templist;
    this.index++;
    this.taxable_total = parseFloat((this.taxable_total + +this.templist['taxable']).toFixed(2));
    this.disc_total = parseFloat((this.disc_total + +this.templist['disc_amt']).toFixed(2));
    this.tax_total = parseFloat((this.tax_total + +this.templist['tax']).toFixed(2));
    this.net_total = parseFloat((+this.taxable_total - +this.disc_total + +this.tax_total).toFixed(2));

    this.p_name = '';
    this.batch_no = '';
    this.qty = 0;
    this.disc = 0;
    this.pack_mrp = 0;
    this.mrp = 0;
    this.shelf = '';
    this.p_cat = '';
    this.gst = 0;
    this.unit_net_rate = 0;
    this.pack_size = '';
    this.expiry_date = '';
    this.hsn = '';
    this.templist = {};

    const element1 = document.getElementById('p_name') as HTMLElement;
    element1.focus();
  }

  deleteProduct(index) {
    this.taxable_total = parseFloat((this.taxable_total - +this.productList[index].taxable).toFixed(2));
    this.disc_total = parseFloat((this.disc_total - +this.productList[index].disc_amt).toFixed(2));
    this.tax_total = parseFloat((this.tax_total - +this.productList[index].tax).toFixed(2));
    this.net_total = parseFloat((+this.taxable_total - +this.disc_total + +this.tax_total).toFixed(2));
    this.productList.splice(index, 1);
    this.index--;
  }
  deleteProduct_custPo(index) {
    this.productList.splice(index, 1);
    this.index--;
  }

  onValueChanges(result) {
    const length = Object.keys(this.products).length;
    const p_no = result.split('$');
    for (let i = 0; i < length; i++) {
      if (this.products[i].p_no === p_no[0]) {
        this.batch_no = p_no[1];
        this.p_name = this.products[i].p_name;
        this.mrp = this.products[i].unit_mrp;
        this.p_cat = this.products[i].p_cat;
        this.shelf = this.products[i].shelf;
        this.gst = this.products[i].gst_per;
        this.batch_qty = this.products[i].t_qty;
        this.pack_size = this.products[i].pack_size;
        this.expiry_date = this.products[i].expiry;
        this.unit_net_rate = this.products[i].unit_net_rate;
        this.pack_mrp = this.products[i].mrp;
        this.hsn = this.products[i].hsn;
        const element1 = document.getElementById('qty') as HTMLElement;
        element1.focus();
        break;
      }
    }

    const length1 = Object.keys(this.products1).length;
    for (let i = 0; i < length1; i++) {
      if (this.products1[i].p_name === this.p_name) {
        this.total_qty = this.products1[i].t_qty;
        break;
      }
    }
  }

  closeNewForm() {
    this.isFirst = false;
    this.isCustomize = false;
    this.isShow = true;
  }

  closeNewAuto() {
    this.isShow = true;
    this.isAutoPO = false;
    this.finalData = [];
    this.isVerify = false;
  }

  changeBox(value, index) {
    this.selectedProducts[index].checked = value;
  }

  changeReq(value, index) {
    this.selectedProducts[index].req_qty = value;
  }

  showCustomise() {
    this.isCustomize = true;
    this.isShow = false;
    this.selectComponent1.open();
  }

  
  isVerify = false;

  filterProduct(){

    const data = this.selectedProducts.slice();   // Copy of the original data
    const filterData = [];
    const distributorMap = {};

    // Group products by distributor_name
    data.forEach(product => {
      if( product.checked == true){
        const distributorName = product.distributor_name;
        if (!distributorMap[distributorName]) {
            distributorMap[distributorName] = {
                distributor_name: product.distributor_name,
                products: [],
            };
        }
        // Push the product to the corresponding distributor's products array
        distributorMap[distributorName].products.push(product);
      }
    });
    // Convert the distributor map to an array of objects
    for (const distributor in distributorMap) {
        filterData.push(distributorMap[distributor]);
    }

    console.log(filterData);
    this.finalData = filterData;

    this.isVerify = true;
   }

  finalData = [];






















}
