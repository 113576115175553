import { Component, OnInit, ViewChild } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
import { SelectComponent } from 'ng-select';

@Component({
  selector: 'app-stock-adjustment',
  templateUrl: './stock-adjustment.component.html',
  styleUrls: ['./stock-adjustment.component.scss']
})
export class StockAdjustmentComponent implements OnInit {
  @ViewChild('prod') selectComponent: SelectComponent;
  products;
  batches;
  productList = [];
  index = 0;

  product = '';
  batch_no = '';
  expiry_date = '';
  shelf = '';
  t_qty = 0;
  phisical_qty = 0;
  difference = 0;
  tempList = {};
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getPurchaseProducts();
  }

  getPurchaseProducts() {
    this.service.get('opening_stock.php?type=getPurchaseProducts').subscribe(response => {
      this.products = response;
      this.selectComponent.open();
    });
  }

  getBatches(event) {
    this.service.get('opening_stock.php?type=getProductBatches&p_name=' + event.p_name).subscribe(response => {
      this.batches = response;
    });
  }

  getMRP(event) {
    this.expiry_date = event.expiry_date;
    this.shelf = event.shelf;
    this.t_qty = event.t_qty;
  }

  calculateDifference() {
    this.difference = +this.phisical_qty - +this.t_qty;
  }

  addProduct() {
    if (this.product !== '' && this.batch_no !== '' && this.phisical_qty !== 0) {
      this.tempList['p_name'] = this.product;
      this.tempList['batch_no'] = this.batch_no;
      this.tempList['expiry_date'] = this.expiry_date;
      this.tempList['shelf'] = this.shelf;
      this.tempList['available_qty'] = this.t_qty;
      this.tempList['phisical_qty'] = this.phisical_qty;
      this.tempList['difference'] = this.difference;
      this.productList[this.index] = this.tempList;
      this.index++;
      this.clearData();
    }
  }

  clearData() {
    this.product = '';
    this.batch_no = '';
    this.expiry_date = '';
    this.shelf = '';
    this.t_qty = 0;
    this.phisical_qty = 0;
    this.difference = 0;
  }

  deleteProduct(index) {
    this.productList.splice(index, 1);
    this.index--;
  }

  adjustStock() {
    this.service.post('opening_stock.php?type=adjustStock', JSON.stringify(this.productList)).subscribe(reponse => {
      this.productList = [];
      this.index = 0;
      this.getPurchaseProducts();
      alert('Stock Adjustment Completed.');
    });
  }

}
