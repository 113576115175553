import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-distributor',
  templateUrl: './distributor.component.html',
  styleUrls: ['./distributor.component.scss']
})
export class DistributorComponent implements OnInit {
  isForm = false;
  isTable = true;
  distList;
  distributors1 = [];
  isGstType = false;
  isIgstType = false;

  ngOnInit() {
    this.getDist();
  }
  constructor(private service: DataAccessService) { }

  getDist() {
    this.service.get('master.php?type=getdistDetails')
    .subscribe(response => {
      this.distList = response;
      this.distributors1 = this.distList;
      const element1 = document.getElementById('isAddNew') as HTMLElement;
      element1.focus();
    });
  }

  saveDist(customerData) {
    this.service.post('master.php?type=saveDist', JSON.stringify(customerData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getDist();
        alert('Distributor Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }





   gstStateCodes = [
    { gst_igst_code: '01', state: 'Jammu & Kashmir' },
    { gst_igst_code: '02', state: 'Himachal Pradesh' },
    { gst_igst_code: '03', state: 'Punjab' },
    { gst_igst_code: '04', state: 'Chandigarh' },
    { gst_igst_code: '05', state: 'Uttarakhand' },
    { gst_igst_code: '06', state: 'Haryana' },
    { gst_igst_code: '07', state: 'Delhi' },
    { gst_igst_code: '08', state: 'Rajasthan' },
    { gst_igst_code: '09', state: 'Uttar Pradesh' },
    { gst_igst_code: '10', state: 'Bihar' },
    { gst_igst_code: '11', state: 'Sikkim' },
    { gst_igst_code: '12', state: 'Arunachal Pradesh' },
    { gst_igst_code: '13', state: 'Nagaland' },
    { gst_igst_code: '14', state: 'Manipur' },
    { gst_igst_code: '15', state: 'Mizoram' },
    { gst_igst_code: '16', state: 'Tripura' },
    { gst_igst_code: '17', state: 'Meghalaya' },
    { gst_igst_code: '18', state: 'Assam' },
    { gst_igst_code: '19', state: 'West Bengal' },
    { gst_igst_code: '20', state: 'Jharkhand' },
    { gst_igst_code: '21', state: 'Odisha' },
    { gst_igst_code: '22', state: 'Chhattisgarh' },
    { gst_igst_code: '23', state: 'Madhya Pradesh' },
    { gst_igst_code: '24', state: 'Gujarat' },
    { gst_igst_code: '25', state: 'Daman & Diu' },
    { gst_igst_code: '26', state: 'Dadra & Nagar Haveli' },
    { gst_igst_code: '27', state: 'Maharashtra' },
    { gst_igst_code: '28', state: 'Andhra Pradesh' },
    { gst_igst_code: '29', state: 'Karnataka' },
    { gst_igst_code: '30', state: 'Goa' },
    { gst_igst_code: '31', state: 'Lakshadweep' },
    { gst_igst_code: '32', state: 'Kerala' },
    { gst_igst_code: '33', state: 'Tamil Nadu' },
    { gst_igst_code: '34', state: 'Puducherry' },
    { gst_igst_code: '35', state: 'Andaman & Nicobar Islands' },
    { gst_igst_code: '36', state: 'Telangana' },
    { gst_igst_code: '37', state: 'Andhra Pradesh (New)' }, 
    { gst_igst_code: '38', state: 'Ladakh' }
  ];
  







  gst_igst_code = '';
  state = '';


  getType(index) {

    this.gst_igst_code = this.gstStateCodes[index].gst_igst_code
    
  }



  filterTable(value) {
    this.distributors1 = [];
    let index = 0;
    const length = Object.keys(this.distList).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.distList[i].d_name + ' ' + this.distList[i].d_cont + ' ' + this.distList[i].d_add + ' ' + this.distList[i].cont_per).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.distributors1[index] = this.distList[i];
        index++;
      }
    }
  }

  validateEmail(emailField) {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField) === false) {
        alert('Invalid Email Address');
        const element1 = document.getElementById('email') as HTMLElement;
        element1.focus();
    }
    return true;
  }

  checkValidation(value) {
    if ((value.toString().length < 10) || value.toString().length > 11) {
      alert('Invalid Contact Number. Please enter valid contact number!');
      const element1 = document.getElementById('contact') as HTMLElement;
      element1.focus();
    }
  }
}

