import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss']
})
export class LedgerComponent implements OnInit {
  data;
  distributor_name = '';
  distributors;
  start_date;
  end_date;

  total_debit = 0;
  total_credit = 0;
  constructor(private service: DataAccessService) {
    const today = new Date();
    let year = today.getFullYear();
    let year1 = year + 1;
    let month = today.getMonth();
    month++;
    if (month <= 3) {
      year = today.getFullYear() - 1;
      year1 = today.getFullYear();
    }
    this.start_date = '01/04/' + year;
    this.end_date = '31/03/' + year1;
  }

  ngOnInit() {
    this.getDistributors();
  }

  getLedger() {
    this.service.get('getdata.php?type=getLedger&distributor=' + this.distributor_name).subscribe(response => {
      this.data = response;

      const len = Object.keys(this.data).length;
      for (let  i = 0; i < len; i++) {
        this.total_debit += +this.data[i].debit;
        this.total_credit += +this.data[i].credit;
      }
    });
  }

  getDistributors() {
    this.service.get('getdata.php?type=getDistributors').subscribe(response => {
      this.distributors = response;
    });
  }

  downloadExcel() {
    const url = this.service.url1 + 'excel/ledger.php?distributor=' + this.distributor_name;
    window.open(url, '_blank');
  }

}
