import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wcustomerpayment',
  templateUrl: './wcustomerpayment.component.html',
  styleUrls: ['./wcustomerpayment.component.css']
})
export class WcustomerpaymentComponent implements OnInit {
  payments;
  selectedCustomer;
  isFirst = false;
  isCash = true;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getDuePayments();
  }

  getDuePayments() {
    this.service.get('payments.php?type=getWholesaleCustomerDuePayments').subscribe(response => {
      this.payments = response;
    });
  }

  getDetails(index) {
    this.selectedCustomer = this.payments[index];
    this.isFirst = true;
  }

  distributerAmount(data,i) {
    
   if(data.payment_amount > data.balance){
    data.payment_amount =  data.balance;
    data.balance_amount = parseFloat(((data.balance - data.payment_amount)).toFixed(2));
   }else{
    data.balance_amount = parseFloat(((data.balance - data.payment_amount)).toFixed(2));
   }

      

    this.selectedCustomer['details'][i] = data;
  }



  // distributerAmount(data1) {
  //   const temp1 = data1.value;
  //   if (temp1['mode'] === 'Cash') {
  //     this.isCash = true;
  //   } else {
  //     this.isCash = false;
  //   }
  //   let value = temp1['amount'];
  //   let temp = 0;
  //   const data = this.selectedCustomer['details'];
  //   const length = Object.keys(data).length;
  //   for (let  i = 0; i < length; i++) {
  //     if (+value <= +data[i].balance) {
  //       data[i].payment_amount = +value;
  //       data[i].balance_amount = data[i].balance - value;
  //       value = value - value;
  //       temp = 1;
  //     } else if (+value > +data[i].balance) {
  //       data[i].payment_amount = +data[i].balance;
  //       value = +value - +data[i].balance;
  //       data[i].balance_amount = 0;
  //     } else if (value === 0) {
  //       data[i].payment_amount = 0;
  //       if (temp === 0) {
  //         data[i].balance_amount = data[i].balance;
  //       }
  //     }
  //     data[i].payment_mode = temp1['mode'];
  //     data[i].transaction_no = temp1['transaction_no'];
  //   }
  //   this.selectedCustomer['details'] = data;
  // }

  

  saveCustomerPayment() {
 
    this.service.post('payments.php?type=saveCustomerPayments', JSON.stringify(this.selectedCustomer))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.getDuePayments();
        delete this.selectedCustomer;
        this.isFirst = false;
      } else {
        alert(response['status']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

}
