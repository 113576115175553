import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-purchase',
  templateUrl: './product-purchase.component.html',
  styleUrls: ['./product-purchase.component.scss']
})
export class ProductPurchaseComponent implements OnInit {
  from_date = '';
  to_date = '';
  p_name = '';
  products;
  products1;
  categories;
  isFirst = false;
  selectedOrder;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getPurchases();
  }

  getPurchases() {
    this.service.get('purchase.php?type=getProductWise&id=1')
    .subscribe(response => {
      this.products = response['products'];
      this.categories = response['categories'];
      this.products1 = this.products;
    });
  }

  filterResult() {
    this.products1 = [];
    let index = 0;
    const length = Object.keys(this.products).length;
    if (this.from_date !== '' && this.to_date === '' && this.p_name === '') {
      for (let  i = 0; i < length; i++) {
        if (this.products[i].invoice_date >= this.from_date) {
          this.products1[index] = this.products[i];
          index++;
        }
      }
    } else if (this.from_date === '' && this.to_date !== '' && this.p_name === '') {
      for (let  i = 0; i < length; i++) {
        if (this.products[i].invoice_date <= this.to_date) {
          this.products1[index] = this.products[i];
          index++;
        }
      }
    } else if (this.from_date === '' && this.to_date === '' && this.p_name !== '') {
      for (let  i = 0; i < length; i++) {
        if (this.products[i].p_name === this.p_name) {
          this.products1[index] = this.products[i];
          index++;
        }
      }
    } else if (this.from_date !== '' && this.to_date !== '' && this.p_name === '') {
      for (let  i = 0; i < length; i++) {
        if (this.products[i].invoice_date >= this.from_date && this.products[i].invoice_date <= this.to_date) {
          this.products1[index] = this.products[i];
          index++;
        }
      }
    } else if (this.from_date !== '' && this.to_date === '' && this.p_name !== '') {
      for (let  i = 0; i < length; i++) {
        if (this.products[i].invoice_date >= this.from_date && this.products[i].p_name === this.p_name) {
          this.products1[index] = this.products[i];
          index++;
        }
      }
    } else if (this.from_date === '' && this.to_date !== '' && this.p_name !== '') {
      for (let  i = 0; i < length; i++) {
        if (this.products[i].invoice_date <= this.to_date && this.products[i].p_name === this.p_name) {
          this.products1[index] = this.products[i];
          index++;
        }
      }
    } else {
      this.products1 = this.products;
    }
  }

  downloadExcel() {
    // tslint:disable-next-line:max-line-length
    window.open(this.service.url1 + 'purchase.php?type=productwisepurchase&p_name=' + this.p_name + '&from_date=' + this.from_date + '&to_date=' + this.to_date + '&id=1');
  }
}
