import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { DataAccessService } from './../../data-access.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { IOption as ngSelectOption, SelectComponent } from 'ng-select';
declare var $: any;

@Component({
  selector: 'app-general-sales',
  templateUrl: './general-sales.component.html',
  styleUrls: ['./general-sales.component.scss']
})
export class GeneralSalesComponent implements OnInit {
  @ViewChild('prod') selectComponent: SelectComponent;
  @ViewChild('customer') selectComponent1: SelectComponent;
  @ViewChild('doctors1') selectComponent2: SelectComponent;

  isSaveSales = 0;
  isFirst = false;
  finalResult;
  order_no;

  p_name;
  pack_size;
  sale_rate;
  qty = 0;
  disc = 0;
  mrp = 0;
  pack_mrp = 0;
  shelf;
  p_cat;
  gst = 0;
  hsn = '';
  m_name = '';
  unit_net_rate = 0;
  expiry_date = '';
  templist = {};
  batch_qty = 0;
  total_qty = 0;

  taxable_total: number;
  disc_total: number;
  tax_total: number;
  net_total: number;
  profit_total: number;
  payble_amt: number;
  isCard = false;

  products;
  patients;
  banks;
  productList = [];
  index = 0;
  patient_no = '';
  order_date;
  cash_credit = 'Cash';
  isCounter = false;
  patient_from = 'Regular';
  patient_email;
  patient_contact;
  patient_address;
  disc_per = 0;
  reminder_days = 0;
  patient_name = '';
  doctor_name = '';

  hsns;
  purchaseProd;
  purchaseProd1;

  purchaseMRP = 0;
  purchasePack_size = '';
  purchaseP_cat = '';
  purchaseGeneric = '';
  purchaseP_no = '';
  send_email = 'Yes';

  role;
  isSalesman = false;
  isAdmin = false;
  isPurchase = false;
  isMember = true;
  iscardtrue = false;
  card_no = '';
  total_points = 0;
  redeem_points = 0;
  // tslint:disable-next-line:max-line-length
  constructor(private service: DataAccessService, private _hotkeysService: HotkeysService, private router: Router, private http:  HttpClient) {
    this.taxable_total = 0.00;
    this.disc_total = 0.00;
    this.tax_total = 0.00;
    this.net_total = 0.00;
    this.profit_total = 0.00;
    this.payble_amt = 0.00;
    this.getTodaysDate();

    this.role = localStorage.getItem('role');
    if (this.role === 'Admin') {
      this.isAdmin = true;
      this.isSalesman = false;
      this.isPurchase = false;
    } else if (this.role === 'Salesman') {
      this.isSalesman = true;
      this.isPurchase = false;
      this.isAdmin = false;
    } else if (this.role === 'Purchase') {
      this.isPurchase = true;
      this.isSalesman = false;
      this.isAdmin = false;
      this.router.navigate(['/dashboard']);
    }

    this._hotkeysService.add(new Hotkey('ctrl+d', (event: KeyboardEvent): boolean => {
      const element1 = document.getElementById('bill_date') as HTMLElement;
      element1.focus();
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
   }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.service.get('sales.php?type=getGeneralInitDetails')
    .subscribe(response => {
      const data = response;
      this.products = data['products'];
      this.patients = data['patients'];
      this.banks = data['banks'];
      this.selectComponent.open();
    });
  }

  getProductDetails(event) {
    console.log(event);
    this.p_name = event.p_name;
    this.total_qty = event.t_qty;
    const length = Object.keys(this.products).length;
    for (let i = 0; i < length; i++) {
      if (this.products[i].p_name === event.p_name) {
        this.sale_rate = this.products[i].sale_rate;
        this.mrp = this.products[i].mrp;
        this.p_cat = this.products[i].p_cat;
        this.shelf = this.products[i].shelf;
        this.gst = this.products[i].gst_per;
        this.batch_qty = this.products[i].t_qty;
        this.pack_size = this.products[i].pack_size;
        this.expiry_date = this.products[i].expiry;
        this.unit_net_rate = this.products[i].unit_net_rate;
        this.pack_mrp = this.products[i].mrp;
        this.hsn = this.products[i].hsn;
        this.m_name = this.products[i].m_name;
        break;
      }
    }
  }

  checkCashCredit(value) {
    if (value === 'Card') {
      this.isCard = true;
    } else {
      this.isCard = false;
    }
  }

  addProduct() {
    if (this.p_name === '' || this.qty === 0) {
      return;
    }
    const length1 = Object.keys(this.products).length;
    for (let j = 0; j < length1; j++) {
      if (this.products[j].p_name === this.p_name) {
        this.products[j].t_qty = +this.products[j].t_qty - +this.qty;
        this.products[j].label = this.products[j].p_name + '(' + this.products[j].t_qty + ')';
      }
    }
    if (this.qty > this.batch_qty) {
      const p_name = this.p_name;
      let qty = this.qty;
      const length = Object.keys(this.products).length;
      for (let i = 0; i < length; i++) {
        if (this.products[i].p_name === p_name) {
          if (qty > this.products[i].t_qty) {
            this.qty = this.products[i].t_qty;
            qty = qty - this.products[i].t_qty;
          } else if (qty > 0 && qty <= this.products[i].t_qty) {
            this.qty = qty;
            qty = 0;
          } else if (qty <= 0) {
            break;
          }
          this.p_name = p_name;
          this.sale_rate = this.products[i].sale_rate;
          this.mrp = this.products[i].unit_mrp;
          this.pack_mrp = this.products[i].mrp;
          this.shelf = this.products[i].shelf;
          this.p_cat = this.products[i].p_cat;
          this.pack_size = this.products[i].pack_size;
          this.gst = this.products[i].gst_per;
          this.expiry_date = this.products[i].expiry;
          this.hsn = this.products[i].hsn;
          this.m_name = this.products[i].m_name;
          this.addToList();
        }
      }
    } else {
      this.addToList();
    }
  }

  getCategory(value) {
    if (value === 'SCHEDULE H') {
      return '#85C81A';
    } else if (value === 'SCHEDULE H1') {
      return '#F8B7B6';
    }
  }

  saveSales(data) {
    if (this.isSaveSales !== 0) {
      alert('Please Wait...');
      return;
    }
    if (this.index === 0) {
      this.isSaveSales = 0;
      alert('At least 1 product is required in Sale');
      return;
    }
    if (this.patient_from === 'Regular' && this.patient_name === '') {
      alert('Patient Name is Required');
      return;
    }
    this.isSaveSales = 1;
    const uploadData = new FormData();
    const temp = data.value;
    uploadData.append('patient_no', this.patient_no);
    uploadData.append('products', JSON.stringify(this.productList));
    uploadData.append('taxable', this.taxable_total + '');
    uploadData.append('discount', this.disc_total + '');
    uploadData.append('gst', this.tax_total + '');
    uploadData.append('net', this.net_total + '');
    uploadData.append('profit', this.profit_total + '');
    uploadData.append('patient_email', this.patient_email);
    uploadData.append('patient_contact', this.patient_contact);
    uploadData.append('patient_address', this.patient_address);
    uploadData.append('cash_credit', temp.cash_credit);
    uploadData.append('doctor_name', temp.doctor_name);
    uploadData.append('order_date', temp.order_date);
    uploadData.append('patient_from', temp.patient_from);
    uploadData.append('patient_name', this.patient_name);
    uploadData.append('reminder_days', temp.reminder_days);
    uploadData.append('send_email', temp.send_email);

    temp['patient_no'] = this.patient_no;
    temp['products'] = this.productList;
    temp['taxable'] = this.taxable_total;
    temp['discount'] = this.disc_total;
    temp['gst'] = this.tax_total;
    temp['net'] = this.net_total;
    temp['profit'] = this.profit_total;
    temp['patient_email'] = this.patient_email;
    temp['patient_contact'] = this.patient_contact;
    temp['patient_address'] = this.patient_address;
    this.finalResult = temp;
    this.service.post('sales.php?type=saveGeneralSales&email=' + localStorage.getItem('email'), uploadData)
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isSaveSales = 0;
        alert('Sale has been saved.');
        this.patient_from = 'Regular';
        this.patient_no = '';
        this.patient_name = '';
        this.doctor_name = '';
        this.patient_contact = '';
        this.patient_email = '';
        this.patient_address = '';
        this.cash_credit = 'Cash';
        this.disc_per = 0;
        this.reminder_days = 0;
        this.getTodaysDate();
        this.taxable_total = 0.00;
        this.disc_total = 0.00;
        this.tax_total = 0.00;
        this.net_total = 0.00;
        this.profit_total = 0.00;
        this.productList = [];
        this.templist = {};
        this.index = 0;
        this.order_no = response['order_no'];
        this.isFirst = true;
        const element1 = document.getElementById('printbtn') as HTMLElement;
        element1.focus();
      } else {
        alert(response['status']);
      }
      },
      (error: Response) => {
        if (error.status === 400) {
          alert('An error has occurred.');
        } else {
          alert('An error has occurred, http status:' + error.status);
        }
      });
  }

  getPatientNo(event) {
    const length = Object.keys(this.patients).length;
    for (let i = 0; i < length; i++) {
      if (this.patients[i].c_name === event.c_name) {
        this.patient_no = this.patients[i].c_no;
        this.patient_email = this.patients[i].c_mail;
        this.patient_contact = this.patients[i].c_cont;
        this.patient_address = this.patients[i].c_add;
        this.patient_name = this.patients[i].c_name;
      }
    }
  }

  showCustomerModal(value) {
    const ans = confirm('Add Patient into Master List ?');
    if (ans === true) {
      $('#exampleModal').modal('show');
    }
  }

  showDoctorModal(value) {
    const ans = confirm('Add Doctor into Master List ?');
    if (ans === true) {
      $('#doctorModal').modal('show');
    }
  }

  getTodaysDate() {
    const today = new Date();
    this.order_date = today.toISOString().substr(0, 10);
  }

  checkPatientFrom(value) {
    if (value === 'Regular') {
      this.isCounter = false;
    } else {
      this.isCounter = true;
    }
  }

  close() {
    delete this.finalResult;
    this.isFirst = false;
    this.getDetails();
  }

  print() {
    // tslint:disable-next-line:max-line-length
    window.open(this.service.url1 + 'sales_print1.php?order_no=' + this.order_no + '&id=' + localStorage.getItem('id') + '&user=' + localStorage.getItem('cl_saler_name'), '_blank');
    this.close();
    this.isFirst = false;
    this.getDetails();
  }

  checkQty() {
    if (this.total_qty < this.qty) {
      alert('Product Total Qty is ' + this.total_qty);
      this.qty = this.total_qty;
      const element1 = document.getElementById('qty') as HTMLElement;
      element1.focus();
    }
  }

  addToList() {
    this.selectComponent.open();
    this.templist['p_name'] = this.p_name;
    this.templist['sale_rate'] = this.sale_rate;
    this.templist['qty'] = this.qty;
    this.templist['disc'] = this.disc;
    this.templist['mrp'] = this.pack_mrp;
    this.templist['shelf'] = this.shelf;
    this.templist['p_cat'] = this.p_cat;
    this.templist['pack_size'] = this.pack_size;
    this.templist['gst'] = this.gst;
    this.templist['expiry_date'] = this.expiry_date;
    this.templist['hsn'] = this.hsn;
    this.templist['m_name'] = this.m_name;
    this.templist['taxable'] = ((this.sale_rate / (1 + (this.gst / 100))) * this.qty).toFixed(2);
    this.templist['disc_amt'] = ((this.qty * this.sale_rate) * (this.disc / 100)).toFixed(2);
    this.templist['tax'] = (((this.sale_rate / ( 1 + (this.gst / 100))) * this.qty) * (this.gst / 100)).toFixed(2);
    this.templist['sgst'] = (((this.sale_rate / ( 1 + (this.gst / 100))) * this.qty) * (this.gst / (100 * 2))).toFixed(2);
    this.templist['cgst'] = (((this.sale_rate / ( 1 + (this.gst / 100))) * this.qty) * (this.gst / (100 * 2))).toFixed(2);
    // tslint:disable-next-line:max-line-length
    this.templist['net'] = ((this.qty * this.sale_rate) - (this.qty * this.sale_rate) * (this.disc / 100)).toFixed(2);
    // tslint:disable-next-line:max-line-length
    this.templist['profit'] = parseFloat((((this.sale_rate * this.qty) - (this.unit_net_rate * this.qty)) - ((this.qty * this.sale_rate) * (this.disc / 100))).toFixed(2));
    this.productList[this.index] = this.templist;
    this.index++;
    this.taxable_total = parseFloat((this.taxable_total + +this.templist['taxable']).toFixed(2));
    this.disc_total = parseFloat((this.disc_total + +this.templist['disc_amt']).toFixed(2));
    this.tax_total = parseFloat((this.tax_total + +this.templist['tax']).toFixed(2));
    this.profit_total = parseFloat((this.profit_total + +this.templist['profit']).toFixed(2));
    this.net_total = parseFloat((+this.taxable_total - +this.disc_total + +this.tax_total).toFixed(2));

    this.p_name = '';
    this.sale_rate = '';
    this.qty = 0;
    this.disc = 0;
    this.pack_mrp = 0;
    this.mrp = 0;
    this.shelf = '';
    this.p_cat = '';
    this.gst = 0;
    this.unit_net_rate = 0;
    this.pack_size = '';
    this.expiry_date = '';
    this.hsn = '';
    this.templist = {};
  }

  deleteProduct(index) {
    const length1 = Object.keys(this.products).length;
    for (let j = 0; j < length1; j++) {
      if (this.products[j].p_name === this.productList[index].p_name) {
        this.products[j].t_qty = +this.products[j].t_qty + +this.productList[index].qty;
        this.products[j].label = this.products[j].p_name + '(' + this.products[j].t_qty + ')';
      }
    }
    this.taxable_total = parseFloat((this.taxable_total - +this.productList[index].taxable).toFixed(2));
    this.disc_total = parseFloat((this.disc_total - +this.productList[index].disc_amt).toFixed(2));
    this.tax_total = parseFloat((this.tax_total - +this.productList[index].tax).toFixed(2));
    this.net_total = parseFloat((+this.taxable_total - +this.disc_total + +this.tax_total).toFixed(2));
    this.productList.splice(index, 1);
    this.index--;
  }

  DiscountOnTotal(value) {
    this.payble_amt = parseFloat((this.net_total - +((this.net_total * value) / 100)).toFixed(2));
  }

  saveCustomer(customerData) {
    this.service.post('master.php?type=saveCustomer', JSON.stringify(customerData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        $('#exampleModal').modal('hide');
        this.service.get('sales.php?type=getInitDetails')
        .subscribe(response1 => {
          const data = response1;
          this.patients = data['patients'];
          this.selectComponent1.open();
        });
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  noProductFound(event) {
    const val = event;
    if (val.indexOf('$') >= -1) {
      const res = val.split('$');
      const length = Object.keys(this.products).length;
      for (let i = 0; i < length; i++) {
        if (this.products[i].p_name === res[0]) {
          this.sale_rate = res[1];
          this.p_name = this.products[i].p_name;
          this.mrp = this.products[i].unit_mrp;
          this.p_cat = this.products[i].p_cat;
          this.shelf = this.products[i].shelf;
          this.gst = this.products[i].gst_per;
          this.batch_qty = this.products[i].t_qty;
          this.pack_size = this.products[i].pack_size;
          this.expiry_date = this.products[i].expiry;
          this.unit_net_rate = this.products[i].unit_net_rate;
          this.pack_mrp = this.products[i].mrp;
          this.hsn = this.products[i].hsn;
          const element1 = document.getElementById('qty') as HTMLElement;
          element1.focus();
          break;
        }
      }

      const length1 = Object.keys(this.products).length;
      for (let i = 0; i < length1; i++) {
        if (this.products[i].p_name === this.p_name) {
          this.total_qty = this.products[i].t_qty;
          break;
        }
      }
    } else {
      const ans = confirm('Product Not Found! Add New Purchase ?');
      if (ans === true) {
        this.showPurchaseModal();
      }
    }
  }

  showPurchaseModal() {
    this.service.get('purchase.php?type=getInitDetails').subscribe(response => {
      const data = response;
      this.hsns = data['hsns'];
      this.purchaseProd = data['products'];
      this.purchaseProd1 = this.products;
    });
    $('#purchaseModal').modal('show');
  }

  savePurchase(data) {
    const temp = data.value;
    temp['pack_size'] = this.purchasePack_size;
    temp['p_no'] = this.purchaseP_no;
    temp['generic'] = this.purchaseGeneric;
    temp['p_cat'] = this.purchaseP_cat;
    temp['scheme'] = 0;
    temp['disc'] = 0;
    temp['shelf'] = '';
    temp['taxable'] = +temp['qty'] * +temp['purchase_rate'];
    temp['sgst'] = parseFloat((+temp['taxable'] * (temp['gst'] / (100 * 2))).toFixed(2));
    temp['cgst'] = parseFloat((+temp['taxable'] * (temp['gst'] / (100 * 2))).toFixed(2));
    temp['igst'] = 0;
    // tslint:disable-next-line:max-line-length
    temp['net_rate'] = parseFloat(((((+temp['purchase_rate'] + ((+temp['purchase_rate'] * +temp['gst']) / 100)) * temp['qty']) / (temp['qty'] + 0)) - ((temp['purchase_rate'] * 0) / 100)).toFixed(2));

    const temp1 = {};
    const temp2 = {};
    temp2[0] = temp;
    temp1['products'] = temp2;
    temp1['gross_total'] = temp['taxable'];
    temp1['disc_total'] = 0;
    temp1['tax_total'] = +temp['sgst'] + +temp['cgst'];
    temp1['net_total'] = +temp['taxable'] + +temp1['tax_total'];
    temp1['gst_type'] = 'GST';
    temp1['invoice_no'] = '';
    temp1['cash_disc'] = 'Cash';
    const today = new Date();
    temp1['invoice_date'] = today.toISOString().substr(0, 10);
    temp1['distributor_name'] = 'Dumy Distributor';

    this.service.post('purchase.php?type=savePurchase', JSON.stringify(temp1))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.service.get('sales.php?type=getInitDetails')
        .subscribe(response1 => {
          const data1 = response1;
          this.products = data1['products'];
          this.selectComponent.open();
        });
        $('#purchaseModal').modal('hide');
        data.reset();
      } else {
        alert(response['status']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  getPurchaseProdMRP(event) {
    this.purchaseMRP = +event.mrp;
    this.purchasePack_size = event.pack_size;
    this.purchaseP_cat = event.p_cat;
    this.purchaseGeneric = event.generic;
    this.purchaseP_no = event.p_no;
  }

  checkValidation(value, value1) {
    if (value1 === 'contact') {
       if ((value.toString().length < 10) || value.toString().length > 12) {
         alert('Invalid Contact Number. Please enter valid contact number!');
         return;
       }
     } else if (value1 === 'email') {
       const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
         if (reg.test(value) === false) {
             alert('Invalid Email Address');
             return false;
         }
         return true;
     }
   }

   checkEmail(value) {
     this.patient_email = value.toLowerCase();
   }

   validateMember() {
     this.http.get('http://demo.cyclosales.com/redeem/login.php?type=validatecard&card_no=' + this.card_no).subscribe(response => {
       if (response['valid']) {
         this.total_points = response['point_earned'];
         this.iscardtrue = true;
       } else {
         alert('Invalid Card Number or Mobile Number. Please try again.');
       }
     });
   }

   showMember() {
    $('#memberModel').modal('show');
   }

}
