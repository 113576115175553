import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';

@Component({
  selector: 'app-cheque',
  templateUrl: './cheque.component.html',
  styleUrls: ['./cheque.component.scss']
})
export class ChequeComponent implements OnInit {
  isForm = false;
  isTable = true;
  chequeList;
  bankdetails;
  isBankName = false;
  bankName;

  ngOnInit() {
    this.getCheque();
    this.getBank();
  }
  constructor(private service: DataAccessService) { }

  getCheque() {
    this.service.get('getdata.php?type=getCheques')
    .subscribe(response => {
      this.chequeList = response;
      const element1 = document.getElementById('isAddNew') as HTMLElement;
      element1.focus();
    });
  }

  getBank() {
    this.service.get('getdata.php?type=getBankDetails').subscribe(response => {
      this.bankdetails = response;
    });
  }

  getAcntId(ac_no) {
    const len = Object.keys(this.bankdetails).length;
    for (let i = 0; i < len; i++) {
      if (this.bankdetails[i].ac_no === ac_no) {
        this.bankName = this.bankdetails[i].bank_name;
      }
    }
  }

  saveCheque(bankData) {
    const temp = bankData.value;
    temp['bank_name'] = this.bankName;
    this.isForm = false;
    this.isTable = true;
    this.service.post('master.php?type=saveCheque', JSON.stringify(bankData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.getCheque();
        alert('Cheque Details Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  viewOrder(index, value) {
    if (value === 'delete') {
      this.service.get('master.php?type=deleteCheque&sr=' + this.chequeList[index].sr).subscribe(response => {
        if (response['status'] === 'success') {
          this.getCheque();
        }
      });
    }
  }
}


