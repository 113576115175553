import { Router } from '@angular/router';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  isCounter1 = 'display';
  isCounter2 = 'nodisplay';
  isCounter3 = 'nodisplay';
  isCounter4 = 'nodisplay';
  isCounter5 = 'nodisplay';
  isActive = 'counterActive';
  sales;
  role;
  isSalesman = false;
  isAdmin = false;
  isPurchase = false;

  clientType = '';
  clientType1 = '';

  card_no = '';
  isShow = false;
  email = '';
  constructor(private service: DataAccessService, private _hotkeysService: HotkeysService, private router: Router) {
    this.role = localStorage.getItem('role');
    this.clientType1 = localStorage.getItem('clientType');






    if (this.role === 'Admin') {
      this.isAdmin = true;
      this.isSalesman = false;
      this.isPurchase = false;
    } else if (this.role === 'Salesman') {
      this.isSalesman = true;
      this.isPurchase = false;
      this.isAdmin = false;
    } else if (this.role === 'Purchase') {
      this.router.navigate(['/new-purchase']);
    }
    this._hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {
      if (this.isCounter1 === 'display') {
        this.isCounter1 = 'nodisplay';
        this.isCounter2 = 'display';
        this.isCounter3 = 'nodisplay';
        this.isCounter4 = 'nodisplay';
        this.isCounter5 = 'nodisplay';
      } else if (this.isCounter2 === 'display') {
        this.isCounter1 = 'nodisplay';
        this.isCounter2 = 'nodisplay';
        this.isCounter3 = 'display';
        this.isCounter4 = 'nodisplay';
        this.isCounter5 = 'nodisplay';
      } else if (this.isCounter3 === 'display') {
        this.isCounter1 = 'nodisplay';
        this.isCounter2 = 'nodisplay';
        this.isCounter3 = 'nodisplay';
        this.isCounter4 = 'display';
        this.isCounter5 = 'nodisplay';
      } else if (this.isCounter4 === 'display') {
        this.isCounter1 = 'nodisplay';
        this.isCounter2 = 'nodisplay';
        this.isCounter3 = 'nodisplay';
        this.isCounter4 = 'nodisplay';
        this.isCounter5 = 'display';
      } else if (this.isCounter5 === 'display') {
        this.isCounter1 = 'display';
        this.isCounter2 = 'nodisplay';
        this.isCounter3 = 'nodisplay';
        this.isCounter4 = 'nodisplay';
        this.isCounter5 = 'nodisplay';
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this._hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {
      if (this.isCounter1 === 'display') {
        this.isCounter1 = 'nodisplay';
        this.isCounter2 = 'nodisplay';
        this.isCounter3 = 'nodisplay';
        this.isCounter4 = 'nodisplay';
        this.isCounter5 = 'display';
      } else if (this.isCounter2 === 'display') {
        this.isCounter1 = 'display';
        this.isCounter2 = 'nodisplay';
        this.isCounter3 = 'nodisplay';
        this.isCounter4 = 'nodisplay';
        this.isCounter5 = 'nodisplay';
      } else if (this.isCounter3 === 'display') {
        this.isCounter1 = 'nodisplay';
        this.isCounter2 = 'display';
        this.isCounter3 = 'nodisplay';
        this.isCounter4 = 'nodisplay';
        this.isCounter5 = 'nodisplay';
      } else if (this.isCounter4 === 'display') {
        this.isCounter1 = 'nodisplay';
        this.isCounter2 = 'nodisplay';
        this.isCounter3 = 'display';
        this.isCounter4 = 'nodisplay';
        this.isCounter5 = 'nodisplay';
      } else if (this.isCounter5 === 'display') {
        this.isCounter1 = 'nodisplay';
        this.isCounter2 = 'nodisplay';
        this.isCounter3 = 'nodisplay';
        this.isCounter4 = 'display';
        this.isCounter5 = 'nodisplay';
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
  }

  ngOnInit() {

    if(localStorage.getItem('clientType') == 'Both'){
      this.clientType = 'Retail';
    }
    else if(localStorage.getItem('clientType') == 'Retail'){
      this.clientType = 'Retail';
    }
    else if(localStorage.getItem('clientType') == 'Wholesale'){
      this.clientType = 'Wholesale';
    }
  }

  checkCounter(value) {
    if (value === 'counter1') {
      this.isCounter1 = 'display';
      this.isCounter2 = 'nodisplay';
      this.isCounter3 = 'nodisplay';
      this.isCounter4 = 'nodisplay';
      this.isCounter5 = 'nodisplay';
    } else if (value === 'counter2') {
      this.isCounter1 = 'nodisplay';
      this.isCounter2 = 'display';
      this.isCounter3 = 'nodisplay';
      this.isCounter4 = 'nodisplay';
      this.isCounter5 = 'nodisplay';
    } else if (value === 'counter3') {
      this.isCounter1 = 'nodisplay';
      this.isCounter2 = 'nodisplay';
      this.isCounter3 = 'display';
      this.isCounter4 = 'nodisplay';
      this.isCounter5 = 'nodisplay';
    } else if (value === 'counter4') {
      this.isCounter1 = 'nodisplay';
      this.isCounter2 = 'nodisplay';
      this.isCounter3 = 'nodisplay';
      this.isCounter4 = 'display';
      this.isCounter5 = 'nodisplay';
    } else if (value === 'counter5') {
      this.isCounter1 = 'nodisplay';
      this.isCounter2 = 'nodisplay';
      this.isCounter3 = 'nodisplay';
      this.isCounter4 = 'nodisplay';
      this.isCounter5 = 'display';
    }
  }

  showCardActivation() {
    this.isShow = false;
    this.card_no = '';
    $('#cardActivationModel').modal('show');
  }

  closeCardActivation() {
    this.isShow = false;
    this.card_no = '';
    $('#cardActivationModel').modal('hide');
  }

  validateEmail(emailField) {
    this.email = emailField.toLowerCase();
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField) === false) {
        alert('Invalid Email Address');
        const element1 = document.getElementById('email') as HTMLElement;
        element1.focus();
    }
    return true;
  }

  checkValidation(value) {
    if ((value.toString().length < 10) || value.toString().length > 11) {
      alert('Invalid Contact Number. Please enter valid contact number!');
      const element1 = document.getElementById('contact') as HTMLElement;
      element1.focus();
    }
  }

  checkCardAvailablity() {
    this.service.get('aushadhmitra.php?type=checkCardAvailablity&card_no=' + this.card_no).subscribe(response => {
      if (response['status'] === 'success') {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.card_no = '';
        alert('CARD is Not available or CARD number is already used!');
      }
    });
  }

  activateCard(data) {
    const temp = data.value;
    if (temp['customer_name'].length <= 0) {
      alert('Customer Name is Required');
      return;
    }
    if (temp['contact_no'].length <= 0) {
      alert('Contact No is Required');
      return;
    }
    if (temp['email_id'].length <= 0) {
      alert('Email Id is Required');
      return;
    }
    if (temp['gender'].length <= 0) {
      alert('Gender is Required');
      return;
    }
    if (temp['address'].length <= 0) {
      alert('Address is Required');
      return;
    }
    if (temp['card_no'].length <= 0) {
      alert('Card No is Required');
      return;
    }
    this.service.post('aushadhmitra.php?type=activateCard', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'success') {
        this.isShow = false;
        this.card_no = '';
        data.reset();
        $('#cardActivationModel').modal('hide');
        alert('Card has been Activated Successfully.');
      }
    });
  }
}
