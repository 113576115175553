import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adjust-gst',
  templateUrl: './adjust-gst.component.html',
  styleUrls: ['./adjust-gst.component.scss']
})
export class AdjustGSTComponent implements OnInit {
  constructor(private service: DataAccessService, private router: Router) {
  }

  ngOnInit() {
  }

  checkbutton(value) {
    if (value === 'purchase') {
      this.router.navigate(['/adjustPurchaseGST']);
    } else if (value === 'sales') {
      this.router.navigate(['/adjustSalesGST']);
    }
  }
}
