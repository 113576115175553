import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit {
  isForm = false;
  isTable = true;
  customerList;
  getCheckVal;
  isChronic;
  isOther;
  isEditForm = false;
  selectCustIds;
  customer_name;
  customer_contact;
  customer_email;
  customer_address;
  customer_chronic;
  customer_gst;
  sr;
  email;
  customers1 = [];

  isNew = false;
  ngOnInit() {
    this.getCustomer();
    this.getDiscount();
  }
  constructor(private service: DataAccessService) { }


  discList;
  getDiscount() {
    this.service.get('master.php?type=getCreditMargine')
    .subscribe(response => {
      this.discList = response;
    });
  }


  getCustomer() {
    this.service.get('master/customer.php?type=getCustomers')
    .subscribe(response => {
      this.customerList = response;
      this.customers1 = this.customerList;
      const element1 = document.getElementById('isAddNew') as HTMLElement;
      element1.focus();
    });
  }

  creditDays = 20;

  saveCustomer(data) {
    if (!data.valid) {
      alert('Please add mandatory fields!');
      return;
    }
    this.service.post('master/customer.php?type=saveCustomer', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'success') {
        this.email = '';
        this.isForm = false;
        this.isTable = true;
        this.getCustomer();
        alert('Customer Added Successfully.');
        this.creditDays = 20;

        const element1 = document.getElementById('isAddNew') as HTMLElement;
        element1.focus();
      } else {
        alert(response['status'] +' '+ response['msg']);
      }
    });
  }

  custType = 'Retail';

  UpdateCustomer(custData) {
   this.service.post('master/customer.php?type=updateCustomer', JSON.stringify(custData.value)).subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getCustomer();
        alert('Customer Updated Successfully.');
      } else {
        alert('Please Try Again');
      }
    });
  }

  checkpatienttype(value) {
    if (value === 'Yes') {
      this.isChronic = true;
    } else {
      this.isChronic = false;
      this.isOther = false;
    }
  }

  getOtherVal(val) {
    if (val === 'Others' && this.getCheckVal === true) {
      this.isOther = true;
    } else {
      this.isOther = false;
    }
  }

  viewOrder(index, value) {
    if (value === 'edit') {
      this.isTable = false;
      this.isEditForm = true;
      this.selectCustIds =  this.customerList[index];
      this.sr = this.selectCustIds.sr;
      this.customer_name = this.selectCustIds.c_name;
      this.customer_contact =  this.selectCustIds.c_cont;
      this.customer_email = this.selectCustIds.c_mail;
      this.customer_address = this.selectCustIds.c_add;
      this.customer_chronic = this.selectCustIds.chronic_val;
      this.customer_gst = this.selectCustIds.gst_no;
    } else if (value === 'delete') {
      const customer_id = this.customerList[index].sr;
      this.service.get('master.php?type=deleteCustomer&customer_id=' + customer_id).subscribe(response => {
        this.getCustomer();
      });
    }
 }

  filterTable(value) {
    this.customers1 = [];
    let index = 0;
    const length = Object.keys(this.customerList).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.customerList[i].c_name + ' ' + this.customerList[i].c_cont + ' ' + this.customerList[i].c_add + ' ' + this.customerList[i].c_mail + ' ' + this.customerList[i].chronic_val).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.customers1[index] = this.customerList[i];
        index++;
      }
    }
  }

  validateEmail(emailField) {
    this.email = emailField.toLowerCase();
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField) === false) {
        alert('Invalid Email Address');
        const element1 = document.getElementById('email') as HTMLElement;
        element1.focus();
    }
    return true;
  }

  checkValidation(value) {
    if ((value.toString().length < 10) || value.toString().length > 11) {
      alert('Invalid Contact Number. Please enter valid contact number!');
      const element1 = document.getElementById('contact') as HTMLElement;
      element1.focus();
    }
  }
}
