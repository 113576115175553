import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-dosage',
  templateUrl: './dosage.component.html',
  styleUrls: ['./dosage.component.scss']
})
export class DosageComponent implements OnInit {
  isForm = false;
  isTable = true;
  dosageList;
  dosages1 = [];

  ngOnInit() {
    this.getDOSAGE();
  }
  constructor(private service: DataAccessService) { }

  getDOSAGE() {
    this.service.get('master.php?type=getDOSAGEDetails')
    .subscribe(response => {
      this.dosageList = response;
      this.dosages1 = this.dosageList;
      const element1 = document.getElementById('isAddNew') as HTMLElement;
      element1.focus();
    });
  }

  saveDOSAGE(dosageData) {
    this.service.post('master.php?type=saveDOSAGE', JSON.stringify(dosageData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getDOSAGE();
        alert('Dosage Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  filterTable(value) {
    this.dosages1 = [];
    let index = 0;
    const length = Object.keys(this.dosageList).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.dosageList[i].form).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.dosages1[index] = this.dosageList[i];
        index++;
      }
    }
  }
}
