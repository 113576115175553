import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.scss']
})
export class ManufacturerComponent implements OnInit {
  isForm = false;
  isTable = true;
  manfList;
  manufacturers1 = [];

  ngOnInit() {
    this.getManf();
  }
  constructor(private service: DataAccessService) { }
  getManf() {
    this.service.get('master/manufacturer.php?type=getManufacturers').subscribe(response => {
      this.manfList = response;
      this.manufacturers1 = this.manfList;
      const element1 = document.getElementById('isAddNew') as HTMLElement;
      element1.focus();
    });
  }
  saveManf(customerData) {
    this.service.post('master/manufacturer.php?type=saveManufacturer', JSON.stringify(customerData.value)).subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getManf();
        alert('Manufacturer Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  filterTable(value) {
    this.manufacturers1 = [];
    let index = 0;
    const length = Object.keys(this.manfList).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.manfList[i].m_name + ' ' + this.manfList[i].s_code).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.manufacturers1[index] = this.manfList[i];
        index++;
      }
    }
  }
}

