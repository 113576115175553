import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss']
})
export class ExpenseComponent implements OnInit {
  expenses;
  isForm = false;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getExpenses();
  }

  getExpenses() {
    this.service.get('getdata.php?type=getExpenses').subscribe(response => {
      this.expenses = response;
    });
  }

  saveExpense(data) {
    this.service.post('savedata.php?type=saveExpense', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'success') {
        this.getExpenses();
        alert('Expnese saved Successfully.');
        this.isForm = false;
      } else {
        alert('An error occured, Please try again!');
      }
    });
  }

}
