import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailService } from '../email.service';
@Component({
  selector: 'app-bfetch',
  templateUrl: './bfetch.component.html',
  styleUrls: ['./bfetch.component.css']
})
export class BfetchComponent implements OnInit {
  csvData:any=[];
  isLoading = false;
  isView= false;
  isAddQuotation= false;
  constructor(private service: DataAccessService, private router: Router,private dataService: EmailService) {}

  ngOnInit(): void {
    this.fetchCsv()
  }
  fetchCsv() {
    this.isLoading = true;
    this.service.get1('mail/fetch.php')
    .subscribe(response => {
      this.csvData = response;
      this.isLoading = false;
      console.log(this.csvData);
    });
  }
  SelectedcsvData=[]
  csv_files_data	=[]
  Selectedcsv_file_data=[]
  csv_file_datass:any	=[]
  csv_file_datassss =[]
  csv_file_datassss_Gross =[]
  inv_headers =[]
  inv_headers_data =[]
  headers	=[]
  formatted_csv_Data:any	=[]
  csv_file_datasss:any	=[]
  csv_files_datasss = {
    csv_files_datasss_data: [],
    header: []
  };
  View(index){
    this.SelectedcsvData=this.csvData[index]
    this.csv_files_data	=this.SelectedcsvData['csv_files_data']
    this.isAddQuotation = true;
    console.log('this.csv_files_data :>> ', this.csv_files_data);
    // this.isView = true;
  }
  viewData(index){
    this.Selectedcsv_file_data=this.csv_files_data[index]
    this.csv_file_datass	=this.Selectedcsv_file_data['data']
    this.isAddQuotation = false;
    this.isView = true;
    this.inv_headers = [
      'distributor_name', 'batch_no', 'cgst', 'client_no', 'disc', 'disc_amt', 'expiry_date', 
      'gst', 'hsn', 'igst', 'invoice_date', 'invoice_no', 'm_name', 'mrp', 'net_rate', 
      'net_total', 'product', 'purchase_rate', 'qty', 'scheme_netrate', 'sgst', 'taxable'
    ];
        // this.headers=this.csv_file_datass[11]
    // this.inv_headers_data=this.csv_file_datass[12]
    
 
    this.csv_file_datasss=[];
    for (let i = 1; i < this.csv_file_datass.length; i++) {
      const row = this.csv_file_datass[i];
      // Only push to the array without reassignment
      this.csv_file_datasss.push(row);
    }

  
    let raw_data = this.csv_file_datasss; // Ensure raw_data is an array
    // //////////////////////////////////////////////////////////////////////////////////////
const formattedData = [];
 
let tempEntry = null;
 

raw_data.forEach(row => {
 
    // It's the main entry row
   
    tempEntry = {
      distributor_name: row[1],
      batch_no: '',
      cgst: row[62],
      client_no: '',
      disc: row[42],
      disc_amt: row[1],
      expiry_date: row[30],
      // generic: row[1],
      gst: Number(row[61])+Number(row[63]),
      hsn: row[58],
      igst: row[59],
      invoice_date: row[8],
      invoice_no: row[7],
      m_name: row[23],
      mrp: row[37],
      net_rate: Number([46])/Number([31]),
      // net_scheme_disc: row[1],
      // net_scheme_rate: row[1],
      net_total: row[46],
      // p_cat: row[1],
      // p_id: row[1],
      // p_no: row[1],
      // pack_size: row[1],
      product: row[25],
      purchase_rate: row[34],
      qty: row[31],
      // sch_per: row[1],
      // scheme: row[1],
      scheme_netrate: row[1],
      sgst: row[64],
      // shelf: row[1],
      // status: row[1],
      taxable: row[31]*row[34]
    };
    formattedData.push(tempEntry); // Push each entry within the loop
});
 

 
this.formatted_csv_Data=formattedData

console.log('non_formattedData==='+formattedData, null, 2);
console.log('formattedData==='+JSON.stringify(formattedData, null, 2));
 
 
console.log('formattedDataParsed_gross==='+JSON.stringify(this.formatted_csv_Data));

this.dataService.setData(this.formatted_csv_Data);
  }




  saveDist() {
    let temp={}
    temp['data']=this.formatted_csv_Data
    
    this.service.post('mail/purchase.php?type=saveCsv', JSON.stringify(temp))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.service.get('purchase.php?type=getInitDetails')
        .subscribe(response1 => {
          alert("Record Insserted Successfully !!")
        
        });
      } else {
        alert('An error has occurred.');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }
}
