import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investment-master',
  templateUrl: './investment-master.component.html',
  styleUrls: ['./investment-master.component.scss']
})
export class InvestmentMasterComponent implements OnInit {
  investments;
  isForm = false;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getInvestments();
  }

  getInvestments() {
    this.service.get('getdata.php?type=getInvestments').subscribe(response => {
      this.investments = response;
    });
  }

  saveInvestments(data) {
    this.service.post('savedata.php?type=saveInvestments', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'success') {
        alert('Investment has been successfully saved.');
        this.isForm = false;
        data.reset();
        this.getInvestments();
      }
    });
  }

}
