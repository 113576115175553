export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: any;
  title?: boolean;
  children?: any;
  variant?: string;
  attributes?: object;
  divider?: boolean;
  class?: string;
}

export const navItems: NavData[] = [
  {
    name: 'Home',
    url: '/dashboard',
    icon: 'icon-home'
  },
  {
    name: 'Masters',
    url: '/base',
    icon: 'icon-grid',
    children: [
      {
        name: 'Patient',
        url: '/base/cards',
        icon: 'icon-puzzle',
        variant: 'danger',
      },
      {
        name: 'Manufactuer',
        url: '/base/carousels',
        icon: 'icon-puzzle',
        variant: 'danger',
      },
      {
        name: 'Distributor',
        url: '/base/collapses',
        icon: 'icon-puzzle',
        variant: 'danger',
      },
      {
        name: 'Products',
        url: '/base/forms',
        icon: 'icon-puzzle',
        variant: 'danger',
      },
      {
        name: 'Shelf',
        url: '/base/paginations',
        icon: 'icon-puzzle',
        variant: 'danger',
      },
      {
        name: 'HSN',
        url: '/base/popovers',
        icon: 'icon-puzzle',
        variant: 'danger',
      },
      {
        name: 'Dosage',
        url: '/base/progress',
        icon: 'icon-puzzle',
        variant: 'danger',
      },
      {
        name: 'Therapeutic',
        url: '/base/switches',
        icon: 'icon-puzzle',
        variant: 'danger',
      },
      {
        name: 'Generic',
        url: '/base/tables',
        icon: 'icon-puzzle',
        variant: 'danger',
      },
      {
        name: 'Accounts',
        url: '/base/tabs',
        icon: 'icon-puzzle',
        variant: 'danger',
      }
    ],
    variant: 'success'
  },
  {
    name: 'Sales',
    url: '/charts',
    icon: 'icon-magnifier-remove',
    variant: 'primary'
  },
  {
    name: 'Purchase',
    url: '/charts',
    icon: 'icon-magnifier-add',
    variant: 'primary'
  },
  {
    name: 'Purchase Order',
    url: '/charts',
    icon: 'icon-speech',
    variant: 'danger'
  },
  {
    name: 'Account',
    url: '/charts',
    icon: 'icon-calculator',
    class: 'mt-auto',
    variant: 'danger'
  },
  {
    name: 'Payment',
    url: '/charts',
    icon: 'icon-wallet',
    variant: 'danger'
  },
  {
    name: 'Sales Reports',
    url: '/charts',
    icon: 'icon-printer',
    variant: 'success'
  },
  {
    name: 'Purchase Report',
    url: '/charts',
    icon: 'icon-printer',
    variant: 'success'
  },
  {
    name: 'Account Reports',
    url: '/charts',
    icon: 'icon-calculator',
    variant: 'success'
  },
  {
    name: 'GST Reports',
    url: '/charts',
    icon: 'icon-info',
    variant: 'success'
  },
  {
    name: '',
    url: '/',
    icon: '',
    variant: 'success'
  },
  {
    name: '',
    url: '/',
    icon: '',
    variant: 'success'
  },
  {
    divider: true
  }
];
