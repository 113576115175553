import { Router } from '@angular/router';
import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';
declare var $: any; 
@Component({
  selector: 'app-new-all-report',
  templateUrl: './new-all-report.component.html',
  styleUrls: ['./new-all-report.component.css']
})
export class NewAllReportComponent implements OnInit {
  public myModal;
  isPurchaseOrder = false;
  from_date = '';
  to_date = '';
  saleFromDate = '';
  saleToDate = '';
  p_name = '';
  action1 = '';
  action2 = '';
  purchaseProducts;
  purchaseCategories;
  mfg_name = '';
  patients;
  patient_name = '';
  constructor(private service: DataAccessService, private router: Router) { }

  ngOnInit() {
    this.getPurchaseProducts();
    this.getPurchaseCategories();
    this.getManufacturers();
    this.getDists();
    this.getSalesCategories();
    this.getSalesProducts();
    this.getSalesDoctor();
  }

  purchaseProductsCategory;
  distributer;
  Manufacturer;


  getPurchaseProducts(){
    this.service.get('purchase.php?type=getPurchaseProducts').subscribe(response => {
      this.purchaseProducts = response;
    });
  }

  getPurchaseCategories(){
    this.service.get('purchase.php?type=getPurchaseCategories').subscribe(response => {
      this.purchaseProductsCategory = response;
    });
  }

  getManufacturers(){
    this.service.get('purchase.php?type=getManufacturers').subscribe(response => {
      this.Manufacturer = response;
    });
  }

  getDists(){
    this.service.get('purchase.php?type=getDists').subscribe(response => {
      this.distributer = response;
    });
  }
  getSalesCategories(){
    this.service.get('sales.php?type=getSalesCategories').subscribe(response => {
      this.saleCategory = response;
    });
  }
  getSalesProducts(){
    this.service.get('sales.php?type=getSalesProducts').subscribe(response => {
      this.salesProducts = response;
    });
  }
  getSalesDoctor(){
    this.service.get('sales.php?type=getSalesDoctor').subscribe(response => {
      this.saleDoctor = response;
    });
  }
  saleDoctor;
  salesProducts;
  saleCategory;

  reportType = 'Date Wise';
  saleReportType = 'Date Wise';
  stockReportType = 'All Products';
  accReportType = 'Purchase GST';
  p_cat = '';
  p_dist = '';
  p_manu = '';

  checkSelection(value1, value2) {
    this.action1 = value2;
    this.action2 = value1;
    if (value1 == 'purchaseProduct') {
      this.service.get('purchase.php?type=getPurchaseProducts').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'purchaseCategory') {
      this.service.get('purchase.php?type=getPurchaseCategories').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'purchaseManufacturer') {
      this.service.get('purchase.php?type=getManufacturers').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'saleManufacturer') {
      this.service.get('sales.php?type=getManufacturers').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'saleTally') {
      this.service.get('purchase.php?type=getPurchaseProducts1').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'purchaseDists') {
      this.service.get('purchase.php?type=getDists').subscribe(response => {
        this.purchaseProducts = response;
      });
    }  else if (value1 == 'accountPurchase') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountSales') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountDistributorPayment') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountPatientBalance') {
      this.service.get('sales.php?type=getSalesPatients').subscribe(response => {
        this.patients = response;
      });
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountDistributorBalance') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountBankTransaction') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountDatewiseReceipt') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'poCustomise') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'salesCategory') {
      this.service.get('sales.php?type=getSalesCategories').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'salesProduct') {
      this.service.get('sales.php?type=getSalesProducts').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'salesDoctor') {
      this.service.get('sales.php?type=getSalesDoctor').subscribe(response => {
        this.purchaseProducts = response;
      });
    }
  }

  action() {
 
     if (this.reportType == 'Product Wise') {
 
        window.open(this.service.url1 + 'pdf/purchaseProductwise.php?from_date=' + this.from_date + '&to_date=' + this.to_date +
           '&p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
    
    }else if (this.reportType == 'Category Wise') {
      
    
        window.open(this.service.url1 + 'pdf/purchaseCategorywise.php?from_date=' + this.from_date + '&to_date=' + this.to_date +
           '&p_cat=' + this.p_cat + '&id=' + localStorage.getItem('id'));
     
    } else if (this.reportType == 'Manufacturer Wise') {
      
        window.open(this.service.url1 + 'pdf/purchaseManufacturerwise.php?from_date=' + this.from_date + '&to_date=' +
           this.to_date + '&m_name=' + this.p_name + '&id=' + localStorage.getItem('id') + '&mfg_name=' + this.p_manu);
     
    }
    else  if (this.reportType == 'Date Wise') {

       window.open(this.service.url1 + 'pdf/purchaseDatewisepdf.php?from_date=' + this.from_date + '&to_date=' + this.to_date +
         '&id=' + localStorage.getItem('id'));

    } 
    else if (this.reportType == 'Distributor Wise') {
 
        window.open(this.service.url1 + 'pdf/purchaseDistributorwise.php?from_date=' + this.from_date + '&to_date=' + this.to_date + 
          '&p_name=' + this.p_dist + '&id=' + localStorage.getItem('id'));
    
    }
     
    this.from_date = '';
    this.to_date = '';
    this.p_name = '';
    this.p_cat = '';
    this.p_dist = '';
    this.p_manu = '';
  }


  SaleAction() {
 
     if (this.saleReportType == 'Product Wise') {
 
      window.open(this.service.url1 + 'pdf/salesProductwise.php?from_date=' + this.saleFromDate + 
        '&to_date=' + this.saleToDate + '&p_name=' + this.ps_name + '&id=' + localStorage.getItem('id'));

    
    }else if (this.saleReportType == 'Category Wise') {
      
    
      window.open(this.service.url1 + 'pdf/salesCategorywise.php?from_date=' + this.saleFromDate +
         '&to_date=' + this.saleToDate + '&p_cat=' + this.ps_cat + '&id=' + localStorage.getItem('id'));

     
    } else if (this.saleReportType == 'Manufacturer Wise') {
      
      window.open(this.service.url1 + 'pdf/salesManufacturewise.php?from_date=' + this.saleFromDate + 
        '&to_date=' + this.saleToDate + '&m_name=' + this.ps_manu + '&id=' + localStorage.getItem('id') + '&mfg_name=' + this.mfg_name);
     
    }
    else  if (this.saleReportType == 'Date Wise') {

      window.open(this.service.url1 + 'pdf/salesDatewise.php?from_date=' + this.saleFromDate + 
        '&to_date=' + this.saleToDate + '&id=' + localStorage.getItem('id'));


    } 
    else if (this.saleReportType == 'Doctor Wise') {
 
      window.open(this.service.url1 + 'pdf/salesDoctorwise.php?from_date=' + this.saleFromDate + 
        '&to_date=' + this.saleToDate + '&p_name=' + this.ps_doct + '&id=' + localStorage.getItem('id'));

    
    }
    else if (this.saleReportType == 'Product Tally') {
 
        window.open(this.service.url1 + 'pdf/purchaseDistributorwise.php?from_date=' + this.saleFromDate + 
          '&to_date=' + this.saleToDate + 
          '&p_name=' + this.p_dist + '&id=' + localStorage.getItem('id'));
    
    }
     
    this.saleToDate = '';
    this.saleFromDate = '';
    this.ps_name = '';
    this.ps_cat = '';
    this.ps_doct = '';
    this.ps_manu = '';
  }


  StockAction() {
 
     if (this.stockReportType == 'Product Wise') {
 
      window.open(this.service.url1 + 'reports.php?type=stockproductwisepdf&p_name=' + this.stp_name + '&id=' + localStorage.getItem('id'));

    
    }
    else if (this.stockReportType == 'All Products') {
      
      window.open(this.service.url1 + 'reports.php?type=stockallproductspdf&id=' + localStorage.getItem('id'));

    }  
    else if (this.stockReportType == 'Distributor Wise') {
      
      window.open(this.service.url1 + 'reports.php?type=stockdistributorwisepdf&dist_name=' + this.st_dist + '&id=' + localStorage.getItem('id'));

    }  
    else if (this.stockReportType == 'Expired Stock') {
      
      window.open(this.service.url1 + 'reports.php?type=stockexpiredpdf&id=' + localStorage.getItem('id'));

    }  
    else if (this.stockReportType == 'Near Expiry Stock') {
      
      window.open(this.service.url1 + 'reports.php?type=stocknearexpirypdf&id=' + localStorage.getItem('id'));

    }  

 
    this.stp_name = '';
    this.st_dist = '';
    
  }



  accAction() {
 
     if (this.accReportType == 'Purchase GST') {
 
       window.open(this.service.url1 + 'reports.php?type=accountpurchasegstpdf&from_date=' +
         this.accfrom_date + '&to_date=' + this.accto_date + '&id=' + localStorage.getItem('id'));
    
    }
    else if (this.accReportType == 'Sales GST') {
 
      window.open(this.service.url1 + 'reports.php?type=accountsalesgstpdf&from_date=' +
        this.accfrom_date + '&to_date=' + this.accto_date + '&id=' + localStorage.getItem('id'));
    
    }
    else if (this.accReportType == 'Day Wise Expense') {
      
      window.open(this.service.url1 + 'reports.php?type=stockallproductspdf&id=' + localStorage.getItem('id'));

    }  
    else if (this.accReportType == 'Distributor Payments') {
      
      window.open(this.service.url1 + 'reports.php?type=accountdistributorpaymentpdf&from_date=' +
        this.accfrom_date + '&to_date=' + this.accto_date + '&id=' + localStorage.getItem('id'));
    }  
    else if (this.accReportType == 'Patient Balance') {
      
     
      window.open(this.service.url1 + 'reports.php?type=accountpatientbalancepdf&from_date=' +
        this.accfrom_date + '&to_date=' + this.accto_date + '&id=' + localStorage.getItem('id'));
    }  
    else if (this.accReportType == 'Datewise Receipt') {
      
      window.open(this.service.url1 + 'reports.php?type=accountDatewiseReceiptpdf&from_date=' +
        this.accfrom_date + '&to_date=' + this.accto_date + '&id=' + localStorage.getItem('id'));
    }  

 
    this.acc_dist = '';
    this.accPat = '';
    this.accfrom_date = '';
    this.accto_date = '';
    
  }


  ps_name = '';
  ps_cat = '';
  ps_doct = '';
  ps_manu = '';
  stp_name = '';
  st_dist = '';
  accPat = '';
  acc_dist = '';
  accfrom_date = '';
  accto_date = '';




 
 
 
  
  accountDistributorBalance() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountdistributorbalancepdf&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountdistributorbalanceprint&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  accountBankTransaction() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountBankTransactionpdf&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountBankTransactionprint&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  accountDatewiseReceipt() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountDatewiseReceiptpdf&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountDatewiseReceiptprint&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  purchaseOrder() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'pdf/purchaseOrder.php?id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'pdf/purchaseOrder.php?id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  adjustGST() {
    this.router.navigate(['/adjustGST']);
  }
  getValue (event) {
    this.mfg_name = event.label;
  }
  downloadTallyReport() {
    $('#tallyModal').modal('hide');
    window.open(this.service.url1 + 'pdf/tally.php?p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
  }

}
