import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from '../../data-access.service';

@Component({
  selector: 'app-margin',
  templateUrl: './margin.component.html',
  styleUrls: ['./margin.component.css']
})
export class MarginComponent implements OnInit {

  constructor(private router: Router,private service: DataAccessService) { }

  ngOnInit() {
    this.getDiscount();
    this.getSHELF();
    this.getMaxDiscount();
    this.getCategoryDetails();
    this.getStandardDiscount();
  }


  margineList;
  pts = 0;

  getSHELF() {
    this.service.get('master.php?type=getRetailMargin')
    .subscribe(response => {
      this.margineList = response;
    });
  }
  discList;

  getDiscount() {
    this.service.get('master.php?type=getCreditMargine')
    .subscribe(response => {
      this.discList = response;
    });
  }
  
  categories;
  getCategoryDetails() {
    this.service.get('master.php?type=getCategoryDetails')
    .subscribe(response => {
      this.categories = response;
    });
  }


  getMaxDiscount() {
    this.service.get('master.php?type=getMaxDiscount')
    .subscribe(response => {
      this.maxDiscount1 = response['maxDiscount'];
    });
  }


  saveSHELF(shelfData) {
    this.service.post('master.php?type=saveMargin', JSON.stringify(shelfData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        shelfData.reset();
        this.getSHELF();
        alert('Margin Added Successfully.');
      } else {
        alert(response['status']);
      }
      });
  }
 
  saveCredit(shelfData) {
    this.service.post('master.php?type=saveCredit', JSON.stringify(shelfData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        shelfData.reset();
        this.getDiscount();
        alert('Credit Added Successfully.');
        this.pts = 0;

      } else {
        alert(response['status']);
      }
      });
  }


  maxDiscount1 = 0;
  maxDiscount = 0;
  cashDiscount = 0;


  checkDisc(){
 
    if(this.cashDiscount > this.maxDiscount1){
      alert(' Cash Discount Cant Be Greater Than Max Discount !!!!!!!!!!!!');
      this.cashDiscount = this.maxDiscount1;
    }
     
  }

  formType = 'ADD MARGIN';


  saveMAxDiscount() {

    let temp ={};
    this.service.post('master.php?type=saveMaxDiscount&maxDiscount='+this.maxDiscount, JSON.stringify(temp))
    .subscribe(response => {
      if (response['status'] === 'success') {
         
        this.getMaxDiscount();
        alert('Max Doscount Saved Successfully.');
      } else {
        alert(response['status']);
      }
      });
  }
 


  StdDiscount = 0 ;



  saveStddiscount(data) {

    if(!data.valid){
      alert('All Field Required !!!!!!');
      return;
    }
    let temp ={};
    this.service.post('master.php?type=saveStdDiscount&StdDiscount='+this.StdDiscount, JSON.stringify(temp))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.getStandardDiscount();
        alert('Standard Doscount Saved Successfully.');
      } else {
        alert(response['status']);
      }
      });
  }



  getStandardDiscount() {
    this.service.get('master.php?type=getStandardDiscount')
    .subscribe(response => {
      this.StdDiscount = response['StdDiscount'];
    });
  }
  




}
