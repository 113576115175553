import { Router } from '@angular/router';
import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';
declare var $: any; 
@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.scss']
})
export class AllReportsComponent implements OnInit {
  public myModal;
  isPurchaseOrder = false;
  from_date = '';
  to_date = '';
  p_name = '';
  action1 = '';
  action2 = '';
  purchaseProducts;
  purchaseCategories;
  mfg_name = '';
  patients;
  patient_name = '';
  constructor(private service: DataAccessService, private router: Router) { }

  ngOnInit() {
  }

  checkSelection(value1, value2) {
    this.action1 = value2;
    this.action2 = value1;
    if (value1 == 'purchaseProduct') {
      this.service.get('purchase.php?type=getPurchaseProducts').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'purchaseCategory') {
      this.service.get('purchase.php?type=getPurchaseCategories').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'purchaseManufacturer') {
      this.service.get('purchase.php?type=getManufacturers').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'saleManufacturer') {
      this.service.get('sales.php?type=getManufacturers').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'saleTally') {
      this.service.get('purchase.php?type=getPurchaseProducts1').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'purchaseDists') {
      this.service.get('purchase.php?type=getDists').subscribe(response => {
        this.purchaseProducts = response;
      });
    }  else if (value1 == 'accountPurchase') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountSales') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountDistributorPayment') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountPatientBalance') {
      this.service.get('sales.php?type=getSalesPatients').subscribe(response => {
        this.patients = response;
      });
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountDistributorBalance') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountBankTransaction') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'accountDatewiseReceipt') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'poCustomise') {
      $('#accountPurchaseModal').modal('show');
    } else if (value1 == 'salesCategory') {
      this.service.get('sales.php?type=getSalesCategories').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'salesProduct') {
      this.service.get('sales.php?type=getSalesProducts').subscribe(response => {
        this.purchaseProducts = response;
      });
    } else if (value1 == 'salesDoctor') {
      this.service.get('sales.php?type=getSalesDoctor').subscribe(response => {
        this.purchaseProducts = response;
      });
    }
  }

  action() {

    console.log(this.action2);

    //this.myModal.hide();
    if (this.action2 == 'purchaseProduct') {
      if (this.action1 == 'pdf') {
        window.open(this.service.url1 + 'pdf/purchaseProductwise.php?from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
      } else if (this.action1 == 'print') {
        window.open(this.service.url1 + 'reports.php?type=productwisepurchaseprint&from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
      }
    } else if (this.action2 == 'purchaseCategory') {
      if (this.p_name == '') {
        alert('Category Name is Required');
        return;
      }
      if (this.action1 == 'pdf') {
        window.open(this.service.url1 + 'pdf/purchaseCategorywise.php?from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_cat=' + this.p_name + '&id=' + localStorage.getItem('id'));
      } else if (this.action1 == 'print') {
        window.open(this.service.url1 + 'reports.php?type=categorywisepurchaseprint&from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_cat=' + this.p_name + '&id=' + localStorage.getItem('id'));
      }
    } else if (this.action2 == 'purchaseManufacturer') {
      if (this.p_name == '') {
        alert('Manufacturer Name is Required');
        return;
      }
      if (this.action1 == 'pdf') {
        window.open(this.service.url1 + 'pdf/purchaseManufacturerwise.php?from_date=' + this.from_date + '&to_date=' + this.to_date + '&m_name=' + this.p_name + '&id=' + localStorage.getItem('id') + '&mfg_name=' + this.mfg_name);
        this.mfg_name = '';
      } else if (this.action1 == 'print') {
        window.open(this.service.url1 + 'reports.php?type=manufacturerwisepurchaseprint&from_date=' + this.from_date + '&to_date=' + this.to_date + '&m_name=' + this.p_name + '&id=' + localStorage.getItem('id') + '&mfg_name=' + this.mfg_name);
        this.mfg_name = '';
      }
    } else if (this.action2 == 'saleManufacturer') {
      if (this.p_name == '') {
        alert('Manufacturer Name is Required');
        return;
      }
      if (this.action1 == 'pdf') {
        window.open(this.service.url1 + 'pdf/salesManufacturewise.php?from_date=' + this.from_date + '&to_date=' + this.to_date + '&m_name=' + this.p_name + '&id=' + localStorage.getItem('id') + '&mfg_name=' + this.mfg_name);
      } else if (this.action1 == 'print') {
        window.open(this.service.url1 + 'reports.php?type=manufacturerwisesalesprint&from_date=' + this.from_date + '&to_date=' + this.to_date + '&m_name=' + this.p_name + '&id=' + localStorage.getItem('id') + '&mfg_name=' + this.mfg_name);
      }
    } else if (this.action2 == 'saleManufacturer') {
      if (this.p_name == '') {
        alert('Manufacturer Name is Required');
        return;
      }
      if (this.action1 == 'print') {
        window.open(this.service.url1 + 'reports.php?type=stockallproductspdf&id=' + localStorage.getItem('id'));
      }
    } else  if (this.action2 == 'purchaseDatewise') {


      console.log(this.action2);


      if (this.action1 == 'pdf') {
        console.log('pdf in purchase datewise');
        window.open(this.service.url1 + 'pdf/purchaseDatewisepdf.php?from_date=' + this.from_date + '&to_date=' + this.to_date + '&id=' + localStorage.getItem('id'));
      } else if (this.action1 == 'print') {
        console.log('print in purchase datewise');
        window.open(this.service.url1 + 'reports.php?type=purchaseDatewisePrint&from_date=' + this.from_date + '&to_date=' + this.to_date + '&id=' + localStorage.getItem('id'));
      }
    } else if (this.action2 == 'purchaseDists') {
      if (this.action1 == 'pdf') {
        window.open(this.service.url1 + 'pdf/purchaseDistributorwise.php?from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
      } else if (this.action1 == 'print') {
        window.open(this.service.url1 + 'reports.php?type=purchaseDistsprint&from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
      }
    } else  if (this.action2 == 'salesDatewise') {
      if (this.action1 == 'pdf') {
        console.log('pdf in sales datewise');
        window.open(this.service.url1 + 'pdf/salesDatewise.php?from_date=' + this.from_date + '&to_date=' + this.to_date + '&id=' + localStorage.getItem('id'));
      } else if (this.action1 == 'print') {
        window.open(this.service.url1 + 'reports22.php?type=salesDatewisePrint&from_date=' + this.from_date + '&to_date=' + this.to_date + '&id=' + localStorage.getItem('id'));
      }
    } else if (this.action2 == 'salesCategory') {
      if (this.p_name == '') {
        alert('Category Name is Required');
        return;
      }
      if (this.action1 == 'pdf') {
        window.open(this.service.url1 + 'pdf/salesCategorywise.php?from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_cat=' + this.p_name + '&id=' + localStorage.getItem('id'));
      } else if (this.action1 == 'print') {
        window.open(this.service.url1 + 'reports22.php?type=categorywisesalesprint&from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_cat=' + this.p_name + '&id=' + localStorage.getItem('id'));
      }
    } else if (this.action2 == 'salesProduct') {
      if (this.action1 == 'pdf') {
        window.open(this.service.url1 + 'pdf/salesProductwise.php?from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
      } else if (this.action1 == 'print') {
        window.open(this.service.url1 + 'reports22.php?type=productwisesalesprint&from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
      }
    }  else if (this.action2 == 'salesDoctor') {
      if (this.action1 == 'pdf') {
        window.open(this.service.url1 + 'pdf/salesDoctorwise.php?from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
      } else if (this.action1 == 'print') {
        window.open(this.service.url1 + 'reports22.php?type=doctorwisesalesprint&from_date=' + this.from_date + '&to_date=' + this.to_date + '&p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
      }
    }
    this.from_date = '';
    this.to_date = '';
    this.p_name = '';
  }

  stockAllProducts(value) {
    if (value == 'pdf') {
      window.open(this.service.url1 + 'reports.php?type=stockallproductspdf&id=' + localStorage.getItem('id'));
    } else if (value == 'print') {
      window.open(this.service.url1 + 'reports.php?type=stockallproductsprint&id=' + localStorage.getItem('id'));
    }
  }

  stockProductwise(value) {
    if (value == 'pdf') {
      window.open(this.service.url1 + 'reports.php?type=stockproductwisepdf&id=' + localStorage.getItem('id'));
    } else if (value == 'print') {
      window.open(this.service.url1 + 'reports.php?type=stockproductwiseprint&id=' + localStorage.getItem('id'));
    }
  }

  stockCategorywise(value) {
    if (value == 'pdf') {
      window.open(this.service.url1 + 'reports.php?type=stockcategorywisepdf&id=' + localStorage.getItem('id'));
    } else if (value == 'print') {
      window.open(this.service.url1 + 'reports.php?type=stockcategorywiseprint&id=' + localStorage.getItem('id'));
    }
  }
  stockDistributorwise(value) {
    if (value == 'pdf') {
      window.open(this.service.url1 + 'reports.php?type=stockdistributorwisepdf&id=' + localStorage.getItem('id'));
    } else if (value == 'print') {
      window.open(this.service.url1 + 'reports.php?type=stockdistributorwiseprint&id=' + localStorage.getItem('id'));
    }
  }
  stockExpired(value) {
    if (value == 'pdf') {
      window.open(this.service.url1 + 'reports.php?type=stockexpiredpdf&id=' + localStorage.getItem('id'));
    } else if (value == 'print') {
      window.open(this.service.url1 + 'reports.php?type=stockexpiredprint&id=' + localStorage.getItem('id'));
    }
  }
  stockNearExpiry(value) {
    if (value == 'pdf') {
      window.open(this.service.url1 + 'reports.php?type=stocknearexpirypdf&id=' + localStorage.getItem('id'));
    } else if (value == 'print') {
      window.open(this.service.url1 + 'reports.php?type=stocknearexpiryprint&id=' + localStorage.getItem('id'));
    }
  }
  stockMRPwise(value) {
    if (value == 'pdf') {
      window.open(this.service.url1 + 'reports.php?type=stockmrpwisepdf&id=' + localStorage.getItem('id'));
    } else if (value == 'print') {
      window.open(this.service.url1 + 'reports.php?type=stockmrpwiseprint&id=' + localStorage.getItem('id'));
    }
  }
  stockPurchaseRatewise(value) {
    if (value == 'pdf') {
      window.open(this.service.url1 + 'reports.php?type=stockpurchaseratewisepdf&id=' + localStorage.getItem('id'));
    } else if (value == 'print') {
      window.open(this.service.url1 + 'reports.php?type=stockpurchaseratewiseprint&id=' + localStorage.getItem('id'));
    }
  }
  accountPurchase() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountpurchasegstpdf&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountpurchasegstprint&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'excel') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'excel/purchasegst.php?id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  accountSales() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountsalesgstpdf&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountsalesgstprint&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'excel') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'excel/salesgst.php?id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  accountDistributorPayment() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountdistributorpaymentpdf&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountdistributorpaymentprint&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  accountPatientBalance() {
    $('#accountPurchaseModal').modal('hide');
    if (this.action1 == 'pdf') {
      // tslint:disable-next-line:max-line-length
      window.open(this.service.url1 + 'reports.php?type=accountpatientbalancepdf&id=' + localStorage.getItem('id') + '&patient_name=' + this.patient_name);
    } else if (this.action1 == 'print') {
      // tslint:disable-next-line:max-line-length
      window.open(this.service.url1 + 'reports.php?type=accountpatientbalanceprint&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
    }
    this.patient_name = '';
  }
  accountDistributorBalance() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountdistributorbalancepdf&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountdistributorbalanceprint&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  accountBankTransaction() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountBankTransactionpdf&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountBankTransactionprint&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  accountDatewiseReceipt() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountDatewiseReceiptpdf&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'reports.php?type=accountDatewiseReceiptprint&id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  purchaseOrder() {
    if (this.from_date !== '' && this.to_date !== '') {
      $('#accountPurchaseModal').modal('hide');
      if (this.action1 == 'pdf') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'pdf/purchaseOrder.php?id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      } else if (this.action1 == 'print') {
        // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + 'pdf/purchaseOrder.php?id=' + localStorage.getItem('id') + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
      }
    } else {
      alert('From Date OR To Date is Required');
      return;
    }
  }
  adjustGST() {
    this.router.navigate(['/adjustGST']);
  }
  getValue (event) {
    this.mfg_name = event.label;
  }
  downloadTallyReport() {
    $('#tallyModal').modal('hide');
    window.open(this.service.url1 + 'pdf/tally.php?p_name=' + this.p_name + '&id=' + localStorage.getItem('id'));
  }

}
