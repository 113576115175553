import { Router } from '@angular/router';
import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-expiry',
  templateUrl: './expiry.component.html',
  styleUrls: ['./expiry.component.css']
})
export class ExpiryComponent implements OnInit {

  month = 0;
  to_date = '';
  p_cat = '';
  products;
  products1;
  categories;
  isFirst = false;
  selectedOrder;
  constructor(private service: DataAccessService, private router: Router) { }



  openInNewTab(purchase: any) {
    // Generate the URL you want to open. You can customize the route and add necessary parameters
   // const url = this.router.serializeUrl(this.router.createUrlTree(['/debitNote'], { queryParams: { id: purchase.id }}));
    this.router.navigate(['/debitNote']);

    // Open the generated URL in a new tab
   // window.open(url, '_blank');
  }




  ngOnInit() {
    this.getPurchases();
  }

  getPurchases() {
    this.service.get('purchase.php?type=getExpriyData')
    .subscribe(response => {
      this.products = response['nearExpProduct'];;
       this.products1 = response['nearExpProduct'];;
    });
  }

  filterResult() {
    // Get the current date
    const currentDate = new Date();

    // Filter products based on the selected month
    this.products1 = this.products.filter(product => {
        // Parse the expiry date in yyyy-mm format
        const [year, month] = product.expiry.split('-'); // Split the yyyy-mm string
        const expiryDate = new Date(year, month - 1, 1);  // Create a date object (month is 0-indexed)

        console.log(expiryDate);
        
        // Calculate the difference in months between current date and expiry date
        const monthDiff = (expiryDate.getFullYear() - currentDate.getFullYear()) * 12 + (expiryDate.getMonth() - currentDate.getMonth());

        // Check if the product expiry falls within the selected range
        return monthDiff <= this.month && monthDiff >= 0;
    });
}



  clearFilter(){
    this.products1 =  this.products;
  }



  isWithinThreeMonths(expiry: string): boolean {
    const monthsToExpiry = this.calculateMonthDifference(expiry);
    return monthsToExpiry <= 3; // Returns true if the expiry is within 3 months
  }



  calculateMonthDifference(expiryDateStr: string): number {
    const currentDate = new Date();
    const expiryDate = new Date(expiryDateStr + '-01'); // 'YYYY-MM' -> convert to 'YYYY-MM-01' to form a valid date

    const yearDiff = expiryDate.getFullYear() - currentDate.getFullYear();
    const monthDiff = expiryDate.getMonth() - currentDate.getMonth();

    return yearDiff * 12 + monthDiff;  // Total difference in months
  }

  // Function to get the appropriate row class based on the expiry
  getRowClass(expiry: string): string {
    const monthsToExpiry = this.calculateMonthDifference(expiry);

    if (monthsToExpiry <= 0 ) {
      return 'expired';  // Red
    } 
    else if (monthsToExpiry <= 3 && monthsToExpiry > 0) {
      return 'expiring-soon';  // Red
    } 
    else if (monthsToExpiry > 3 && monthsToExpiry <= 6) {
      return 'expiring-medium';  // Yellow
    } 
    else if (monthsToExpiry > 3 && monthsToExpiry <= 6) {
      return 'expiring-medium';  // Yellow
    } 
    else {
      return 'expiring-later';  // Green
    }
  }




 
}
