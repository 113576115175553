import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';


@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.css']
})
export class HospitalComponent implements OnInit {

  isCollapsed = false;
  iconCollapse = 'icon-arrow-up';
  isForm = false;
  isTable = true;
  hospList;
  purchases: any;
  shelfs1 = [];

  ngOnInit() {
    this.getHospitalMaster();
  }


  constructor(private service: DataAccessService) { }

  getHospitalMaster() {
    this.service.get('master.php?type=getHospitalMaster')
    .subscribe(response => {
      this.hospList = response;
    });
  }


  saveHospitalMaster(shelfData) {
    this.service.post('master.php?type=saveHospitalMaster', JSON.stringify(shelfData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getHospitalMaster();
        alert('Hospital Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }


  scmPercentage = 0;
  freeQty =0;
  quantity =0;

  checkScmPer(){
    this.scmPercentage = 0;

    this.scmPercentage = parseFloat(((this.freeQty * 100) / this.quantity).toFixed(2));

  }
 

 
}
