import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-assign',
  templateUrl: './user-assign.component.html',
  styleUrls: ['./user-assign.component.scss']
})
export class UserAssignComponent implements OnInit {
  users;
  salesmans = 0;
  purchases = 0;
  isNewUser = false;
  role1 = '';
  cl_saler_name = '';
  cl_reg_no = '';
  cl_pass = '';
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.service.get('login.php?type=getUsers').subscribe(response => {
      this.users = response['users'];
      this.salesmans = response['Salesman'];
      this.purchases = response['Purchase'];
    });
  }

  saveUser() {
    if (this.role1 === '') {
      alert('Role is Required');
      return;
    }
    if (this.cl_saler_name === '') {
      alert('Name is Required');
      return;
    }
    if (this.cl_reg_no === '') {
      alert('Registration No is Required');
      return;
    }
    if (this.cl_pass === '') {
      alert('Password is Required');
      return;
    }
    const data = {};
    data['role'] = this.role1;
    data['cl_saler_name'] = this.cl_saler_name;
    data['cl_reg_no'] = this.cl_reg_no;
    data['cl_pass'] = this.cl_pass;
    this.service.post('login.php?type=saveUser', JSON.stringify(data)).subscribe(response => {
      this.role1 = '';
      this.cl_saler_name = '';
      this.cl_reg_no = '';
      this.cl_pass = '';
      this.ngOnInit();
      this.isNewUser = false;
    });
  }
}
