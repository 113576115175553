import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  role;
  isSalesman = false;
  isAdmin = false;
  isPurchase = false;
  constructor(private hostkey: HotkeysService, private router: Router) {
    this.role = localStorage.getItem('role');
    this.hostkey.add(new Hotkey('alt+s', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/sales']);
    return false;
  }, ['INPUT', 'SELECT', 'TEXTAREA']));
    this.hostkey.add(new Hotkey('alt+s', (event: KeyboardEvent): boolean => {
      if (this.role == 'Admin' || this.role == 'Salesman') {
        this.router.navigate(['/sales']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+p', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin' || this.role === 'Purchase') {
        this.router.navigate(['/new-purchase']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+r', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin') {
        this.router.navigate(['/allreports']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+m', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/patient']);
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+o', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin' || this.role === 'Purchase') {
        this.router.navigate(['/purchaseOrder']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+z', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin'  || this.role === 'Salesman') {
        this.router.navigate(['/product']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+c', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin'  || this.role === 'Salesman') {
        this.router.navigate(['/patient']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+d', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin'  || this.role === 'Purchase') {
        this.router.navigate(['/distributor']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+n', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin') {
        this.router.navigate(['/debitNote']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('esc', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/dashboard']);
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+w', (event: KeyboardEvent): boolean => {
      this.router.navigate(['/openingstock']);
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+x', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin') {
        this.router.navigate(['/payments']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+y', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin') {
        this.router.navigate(['/doctor']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+f', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin') {
        this.router.navigate(['/manufacturer']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+g', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin') {
        this.router.navigate(['/generic']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+j', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin') {
        this.router.navigate(['/dosage']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+h', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin') {
        this.router.navigate(['/hsn']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+k', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin') {
        this.router.navigate(['/shelf']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+v', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin' || this.role === 'Purchase') {
        this.router.navigate(['/purchase']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+q', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin' || this.role === 'Salesman') {
        this.router.navigate(['/salesreport']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+b', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin' || this.role === 'Salesman') {
        this.router.navigate(['/bank']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+u', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin' || this.role === 'Salesman') {
        this.router.navigate(['/cheque']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+a', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin' || this.role === 'Salesman') {
        this.router.navigate(['/redeemHistory']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+l', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin' || this.role === 'Salesman') {
        this.router.navigate(['/dailyclosing']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));

    this.hostkey.add(new Hotkey('alt+i', (event: KeyboardEvent): boolean => {
      if (this.role === 'Admin' || this.role === 'Salesman') {
        this.router.navigate(['/account']);
      }
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
   }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
