import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-distributor-payment',
  templateUrl: './distributor-payment.component.html',
  styleUrls: ['./distributor-payment.component.scss']
})
export class DistributorPaymentComponent implements OnInit {
  isFirst = false;
  payments;
  selectedDistributor;
  category = 'Distributor';
  isDistributor = true;
  paymentHistory;
  paymentHistory1 = [];
  isPayment = false;
  distributors = [];
  distributors1 = [];
  payments1 = [];
  isCheck = false;
  cheques;
  advance_payment = 0;
  available_balance = 0;

  distributor_name = '';
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getDuePayments();
    this.getPaymentHistory();
    this.getCheques();
  }

  getCheques() {
    this.service.get('getdata.php?type=getCheques').subscribe(response => {
      this.cheques = response;
    });
  }

  getAvailableBalance() {
    this.service.get('getdata.php?type=getAvailableBalance&d_name=' + this.selectedDistributor['distributor_name']).subscribe(response => {
      this.available_balance = response['available_balance'];
    });
  }

  checkCategory(value) {
    if (value === 'Distributor') {
      this.isDistributor = true;
    } else {
      this.isDistributor = false;
    }
  }

  getDistributors1() {
    let index = 0;
    const len = Object.keys(this.payments).length;
    for (let i = 0; i < len; i++) {
      let flag = 0;
      const len1 = Object.keys(this.distributors1).length;
      for (let j = 0; j < len1; j++) {
        if (this.distributors1[j] === this.payments[i].distributor_name) {
          flag = 1;
        }
      }
      if (flag === 0) {
        this.distributors1[index] = this.payments[i].distributor_name;
        index++;
      }
    }
  }

  getDistributors() {
    let index = 0;
    const len = Object.keys(this.paymentHistory).length;
    for (let i = 0; i < len; i++) {
      let flag = 0;
      const len1 = Object.keys(this.distributors).length;
      for (let j = 0; j < len1; j++) {
        if (this.distributors[j] === this.paymentHistory[i].distributor_name) {
          flag = 1;
        }
      }
      if (flag === 0) {
        this.distributors[index] = this.paymentHistory[i].distributor_name;
        index++;
      }
    }
  }

  getPaymentHistory() {
    this.service.get('purchase.php?type=getPaymentHistory').subscribe(response => {
      this.paymentHistory = response;
      this.paymentHistory1 = this.paymentHistory;
      this.getDistributors();
    });
  }

  getDuePayments() {
    this.service.get('payments.php?type=getDistributorDuePayments').subscribe(response => {
      this.payments = response;
      this.payments1 = this.payments;
      this.getDistributors1();
    });
  }

  getDetails(index) {
    this.selectedDistributor = this.payments[index];
    this.getAvailableBalance();
    this.isFirst = true;
  }

  distributerAmount(data1) {
    const temp1 = data1.value;
    if (temp1['mode'] === 'Cheque') {
      this.isCheck = true;
    } else {
      this.isCheck = false;
    }
    let value = temp1['amount'];
    let temp = 0;
    const data = this.selectedDistributor['details'];
    const length = Object.keys(data).length;
    for (let  i = 0; i < length; i++) {
      if (+value <= +data[i].balance) {
        data[i].payment_amount = Math.round(+value);
        data[i].balance_amount = Math.round(data[i].balance - value);
        value = value - value;
        temp = 1;
      } else if (+value > +data[i].balance) {
        data[i].payment_amount = Math.round(+data[i].balance);
        value = +value - +data[i].balance;
        data[i].balance_amount = 0;
      } else if (value === 0) {
        data[i].payment_amount = 0;
        if (temp === 0) {
          data[i].balance_amount = Math.round(data[i].balance);
        }
      }
      if (value > 0) {
        this.advance_payment = value;
      }
      data[i].payment_mode = temp1['mode'];
      data[i].transaction_no = temp1['transaction_no'];
    }
    this.selectedDistributor['details'] = data;
  }

  saveCustomerPayment(data) {
    const temp = data.value;
    this.selectedDistributor['amount'] = temp.amount;
    this.selectedDistributor['mode'] = temp.mode;
    this.selectedDistributor['transaction_no'] = temp.transaction_no;
    this.selectedDistributor['advance_payment'] = this.advance_payment;
    this.service.post('payments.php?type=saveDistributorPayments', JSON.stringify(this.selectedDistributor))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.advance_payment = 0;
        data.reset();
        this.getDuePayments();
        delete this.selectedDistributor;
        this.isFirst = false;
        this.isCheck = false;
        this.getPaymentHistory();
        this.getCheques();
      } else {
        alert(response['status']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  filterHistory(value) {
    this.paymentHistory1 = [];
    let index = 0;
    const len = Object.keys(this.paymentHistory).length;
    for (let i = 0; i < len; i++) {
      const temp = this.paymentHistory[i].distributor_name;
      if (temp === value) {
        this.paymentHistory1[index] = this.paymentHistory[i];
        index++;
      }
    }
  }

  filterHistory1(value) {
    this.payments1 = [];
    let index = 0;
    const len = Object.keys(this.payments).length;
    for (let i = 0; i < len; i++) {
      const temp = this.payments[i].distributor_name;
      if (temp === value) {
        this.payments1[index] = this.payments[i];
        index++;
      }
    }
  }

  downloadPaymentHistory() {
    window.open(this.service.url1 + 'excel/paymentHistory.php?distributor_name=' + this.distributor_name, '_blank');
  }

}
