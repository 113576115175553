import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-po-report-auto',
  templateUrl: './po-report-auto.component.html',
  styleUrls: ['./po-report-auto.component.scss']
})
export class PoReportAutoComponent implements OnInit {
  sales;
  sales1 = [];
  from_date = '';
  to_date = '';
  customer_name = '';
  doctor_name = '';
  selectedOrder;
  isFirst = false;
  poNo;
  poProducts;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getPOAuto();
  }

  getPOAuto() {
    this.service.get('master.php?type=getPOAuto').subscribe(data => {
      this.sales = data;
      this.sales1 = this.sales;
      $(document).ready(function() {
        $('#example').DataTable();
      });
    });
  }

  viewOrder(index, value) {
    this.selectedOrder = this.sales[index];
    this.poNo = this.sales[index].po_no;
    this.service.get('master.php?type=getPOAutopoNo&poNo=' + this.poNo)
    .subscribe(response => {
      this.poProducts = response;
      console.log('res is ' + this.poProducts);
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
    if (value === 'view') {
      this.isFirst = true;
    } else if (value === 'pdf') {
      const id = this.selectedOrder['po_no'];
      const mfg_name = this.selectedOrder['d_name'];
      window.open(this.service.url1 + '/master.php?type=orderPDFAUTO&id=' + id + '&mfg_name=' + mfg_name,  '_blank');
    } else if (value === 'print') {
      const id = this.selectedOrder['po_no'];
      const mfg_name = this.selectedOrder['d_name'];
      window.open(this.service.url1 + '/poAuto_print.php?id=' + id + '&mfg_name=' + mfg_name, '_blank');
    }
   /*  this.selectedOrder = this.sales[index]; */
  }

  filterResult() {
    this.sales1 = [];
    let index = 0;
      const length = Object.keys(this.sales).length;
      if (this.from_date !== '' && this.to_date === '' && this.customer_name === '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          if (this.sales[i].order_date >= this.from_date) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          if (this.sales[i].order_date <= this.to_date) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date === '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date === '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          if (this.sales[i].order_date >= this.from_date && this.sales[i].order_date <= this.to_date) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date === '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (this.sales[i].order_date >= this.from_date && test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date === '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (this.sales[i].order_date >= this.from_date && test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date !== '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (this.sales[i].order_date <= this.to_date && test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (this.sales[i].order_date <= this.to_date && test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date === '' && this.customer_name !== '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          const test1 = this.sales[i].doctor_name;
          if (test1.includes(this.customer_name) && test1.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date !== '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (this.sales[i].order_date >= this.from_date && this.sales[i].order_date <= this.to_date && test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (this.sales[i].order_date >= this.from_date && this.sales[i].order_date <= this.to_date && test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else {
        this.sales1 = this.sales;
      }
  }
}

