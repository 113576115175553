import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

export class DataAccessService {



  ////   only For Production

  url1 = 'https://retail.smartpharmacy.co.in/server/';


  // Only For Devlopment
   //  url1 = 'https://retail.smartpharmacy.co.in/serverdev/';

  constructor(private http: HttpClient, private router: Router) {
    this.validateLogin();
   }  

  get(url) {
    url = this.url1 + url + '&net=OFFLINE' + '&client_no=' + localStorage.getItem('id') + '&isgeneral=' + localStorage.getItem('isgeneral');
    return this.http.get(url); 
  }
  get1(url) {
    url = this.url1 + url ;
    return this.http.get(url); 
  }

  post(url, postData) {
    url = this.url1 + url + '&net=OFFLINE' + '&client_no=' + localStorage.getItem('id') + '&isgeneral=' + localStorage.getItem('isgeneral');
    return this.http.post(url, postData);
  }

  logout() {
    localStorage.clear();
    const full = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    window.location.href = full;
  }

  validateLogin() {
    const temp = {};
    if (localStorage.getItem('id') !== null) {
      temp['id'] = localStorage.getItem('id');
      temp['email'] = localStorage.getItem('email');
      temp['role'] = localStorage.getItem('role');
      this.http.post(this.url1 + 'login.php?type=validateUser', JSON.stringify(temp)).subscribe(response => {
        if (response['status'] === 'success') {
          if (localStorage.getItem('role') === 'Salesman') {
            this.router.navigate(['/sales']);
          } else if (localStorage.getItem('role') === 'Purchase') {
            this.router.navigate(['/new-purchase']);
          } else {
            this.router.navigate(['/']);
          }
        } else {
          this.logout();
          this.router.navigate(['/login']);
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }
}
