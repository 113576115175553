import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailService } from '../email.service';
@Component({
  selector: 'app-fetch',
  templateUrl: './fetch.component.html',
  styleUrls: ['./fetch.component.css']
})
export class FetchComponent implements OnInit {
  csvData:any=[];
  isLoading = false;
  isView= false;
  isAddQuotation= false;
  constructor(private service: DataAccessService, private router: Router,private dataService: EmailService) {}

  ngOnInit(): void {
    this.fetchCsv()
  }
  fetchCsv() {
    this.isLoading = true;
    this.service.get1('mail/fetch.php')
    .subscribe(response => {
      this.csvData = response;
      this.isLoading = false;
      console.log(this.csvData);
    });
  }
  SelectedcsvData=[]
  csv_files_data	=[]
  Selectedcsv_file_data=[]
  csv_file_datass	=[]
  csv_file_datassss =[]
  csv_file_datassss_Gross =[]
  inv_headers =[]
  inv_headers_data =[]
  headers	=[]
  formatted_csv_Data:any	=[]
  csv_files_datasss = {
    csv_files_datasss_data: [],
    header: []
  };
  View(index){
    this.SelectedcsvData=this.csvData[index]
    this.csv_files_data	=this.SelectedcsvData['csv_files_data']
    this.isAddQuotation = true;
    console.log('this.csv_files_data :>> ', this.csv_files_data);
    // this.isView = true;
  }
  viewData(index){
    this.Selectedcsv_file_data=this.csv_files_data[index]
    this.csv_file_datass	=this.Selectedcsv_file_data['data']
    this.isAddQuotation = false;
    this.isView = true;
    this.inv_headers=this.csv_file_datass[10]
    this.headers=this.csv_file_datass[11]
    this.inv_headers_data=this.csv_file_datass[12]
    // this.csv_file_datassss = [];  // Initialize as an empty array
    // for (let i = 12; i < this.csv_file_datass.length; i++) {
    //   // Push each row starting from index 12 into csv_file_datassss
    //   this.csv_file_datassss.push(this.csv_file_datass[i]);
    // }
    // Initialize the array
this.csv_file_datassss = [];
this.csv_file_datassss_Gross = [];

// Regular expression to match date in d/m/yyyy or dd/mm/yyyy format
// const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;

// List of words to exclude (case-insensitive)
const excludedWords = ['gross', 'net','----------','--------------------------------------------------------------------------------'];
const IncludedWords = ['Gross'];

// Regular expression to match more than 3 consecutive dashes (e.g., ----, -----, ------)


for (let i = 12; i < this.csv_file_datass.length; i++) {
  const row = this.csv_file_datass[i];

   const containsExcludedWords = row.some(cell => {
    return excludedWords.some(word => cell.toLowerCase().includes(word.toLowerCase()));
   });
   const containsIncludedWords = row.some(cell => {
     return IncludedWords.some(word => cell.toLowerCase().includes(word.toLowerCase()));
  });

   if (!containsExcludedWords) {
    this.csv_file_datassss.push(row);
  }
   if (containsIncludedWords) {
    this.csv_file_datassss_Gross.push(row);
  }
}

console.log('this.csv_file_datassss :>> ', this.csv_file_datassss);
console.log('this.csv_file_datassss_Gross :>> ', this.csv_file_datassss_Gross);

let raw_data=this.csv_file_datassss;
let raw_data1=this.csv_file_datassss_Gross;

// //////////////////////////////////////////////////////////////////////////////////////
const formattedData = [];
const formattedData1 = [];
let tempEntry = null;
let tempEntry1 = null;

raw_data.forEach(row => {
  if (row[0].includes('/')) {
    // It's the main entry row
    if (tempEntry) {
      formattedData.push(tempEntry);
    }
    tempEntry = {
      date: row[0],
      name: row[1],
      code: row[2],
      IHQ_code: row[3],
      other_code: row[4],
      empty_fields: row.slice(5),
      csv_files_datasss_data: []
    };
  } else if (tempEntry) {
    // It's a data row, push to csv_files_datasss_data
    tempEntry.csv_files_datasss_data.push(row);
  }
});
raw_data1.forEach(row => {
  if (row[0].includes('Gross')) {
    // It's the main entry row
    if (tempEntry1) {
      formattedData1.push(tempEntry1);
    }
    const match = row[0].match(/Gross:\s*([\d.]+)\s*Disc:\s*([\d.]+)\s*VAT:\s*([\d.]+)\s*D\/N:\s*([\d.]+)/);
    if (match) {
      tempEntry1 = {
        gross: parseFloat(match[1]),
        disc: parseFloat(match[2]),
        vat: parseFloat(match[3]),
        dn: parseFloat(match[4]),
        net: row[2],
        
      };
    }
  }  
});

// Add the last entry
if (tempEntry) {
  formattedData.push(tempEntry);
}
if (tempEntry1) {
  formattedData1.push(tempEntry1);
}
this.formatted_csv_Data=formattedData

console.log('non_formattedData==='+formattedData, null, 2);
console.log('formattedData==='+JSON.stringify(formattedData, null, 2));
console.log('formattedDataParsed_gross==='+JSON.stringify(formattedData1));
formattedData1.forEach((grossData, index) => {
  if (this.formatted_csv_Data[index]) {
    // Add the gross data to the respective index in formatted_csv_Data
    this.formatted_csv_Data[index] = { 
      ...this.formatted_csv_Data[index],
      ...grossData
    };
  }
});
console.log('formattedDataParsed_gross==='+JSON.stringify(this.formatted_csv_Data));

this.dataService.setData(this.formatted_csv_Data);
  }




  saveDist() {
    let temp={}
    temp['data']=this.formatted_csv_Data
    
    this.service.post('mail/purchase.php?type=saveCsv', JSON.stringify(temp))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.service.get('purchase.php?type=getInitDetails')
        .subscribe(response1 => {
          alert("Record Insserted Successfully !!")
        
        });
      } else {
        alert('An error has occurred.');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }
}
