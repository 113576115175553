import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.scss']
})
export class DoctorComponent implements OnInit {
  isForm = false;
  isTable = true;
  doctorList;
  isEditForm = false;
  selectCustIds;
  sr;
  doctor_name;
  doctor_contact;
  doctor_email;
  doctor_address;
  doctors1 = [];

  ngOnInit() {
    this.getDoctor();
  }
  constructor(private service: DataAccessService) { }

  getDoctor() {
    this.service.get('master.php?type=getDoctorDetails')
    .subscribe(response => {
      this.doctorList = response;
      this.doctors1 = this.doctorList;
      const element1 = document.getElementById('isAddNew') as HTMLElement;
      element1.focus();
    });
  }

  saveDoctor(doctorData) {
    this.service.post('master.php?type=saveDoctor', JSON.stringify(doctorData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getDoctor();
        alert('Doctor Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  UpdateDoctor(doctorData) {
    this.service.post('master.php?type=updateDoctor', JSON.stringify(doctorData.value))
     .subscribe(response => {
       if (response['status'] === 'success') {
         this.isEditForm = false;
         this.isForm = false;
         this.isTable = true;
         this.getDoctor();
         alert('Doctor Updated Successfully.');
       } else {
         alert('Please Try Again');
       }
       },
     (error: Response) => {
       if (error.status === 400) {
         alert('An error has occurred.');
       } else {
         alert('An error has occurred, http status:' + error.status);
       }
     });
   }

  viewOrder(index, value) {
    if (value === 'edit') {
        this.isTable = false;
        this.isEditForm = true;
        this.selectCustIds =  this.doctors1[index];
        this.sr = this.selectCustIds.sr;
        this.doctor_name = this.selectCustIds.dc_name;
        this.doctor_contact =  this.selectCustIds.dc_cont;
        this.doctor_email = this.selectCustIds.dc_mail;
        this.doctor_address = this.selectCustIds.dc_add;
    } else if (value === 'delete') {
        this.selectCustIds =  this.doctors1[index];
        this.sr = this.selectCustIds.sr;
        this.service.get('master.php?type=deleteDoctor&sr=' + this.sr)
        .subscribe(response => {
            if (response['status'] === 'success') {
              this.getDoctor();
              alert('Doctor Deleted Successfully.');
            } else {
              alert('Please Try Again');
            }
          },
        (error: Response) => {
          if (error.status === 400) {
            alert('An error has occurred.');
          } else {
            alert('An error has occurred, http status:' + error.status);
          }
        });
    }
 }

  filterTable(value) {
    this.doctors1 = [];
    let index = 0;
    const length = Object.keys(this.doctorList).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.doctorList[i].dc_name + ' ' + this.doctorList[i].dc_cont + ' ' + this.doctorList[i].dc_mail).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.doctors1[index] = this.doctorList[i];
        index++;
      }
    }
  }

  validateEmail(emailField) {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField) === false) {
        alert('Invalid Email Address');
        const element1 = document.getElementById('email') as HTMLElement;
        element1.focus();
    }
    return true;
  }

  checkValidation(value) {
    if ((value.toString().length < 10) || value.toString().length > 11) {
      alert('Invalid Contact Number. Please enter valid contact number!');
      const element1 = document.getElementById('contact') as HTMLElement;
      element1.focus();
    }
  }

}
