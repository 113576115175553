import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { EmailService } from '../email.service';


@Component({
  selector: 'app-mail-files',
  templateUrl: './mail-files.component.html',
  styleUrls: ['./mail-files.component.css']
})
export class MailFilesComponent implements OnInit {
  csvData:any=[];
  isLoading = false;
  isView= false;
  isAddQuotation= false;
  constructor(private service: DataAccessService, private router: Router) {}

  ngOnInit(): void {
    this.fetchCsv()
  }
  fetchCsv() {
    this.isLoading = true;
    this.service.get1('mail/fetch.php')
    .subscribe(response => {
      this.csvData = response;
      this.isLoading = false;
      console.log(this.csvData);
    });
  }
  SelectedcsvData=[]
  csv_files_data	=[]
  Selectedcsv_file_data=[]
  csv_file_datass	=[]
  csv_file_datassss =[]
  headers	=[]
  View(index){
    this.SelectedcsvData=this.csvData[index]
    this.csv_files_data	=this.SelectedcsvData['csv_files_data']
    this.isAddQuotation = true;
    console.log('this.csv_files_data :>> ', this.csv_files_data);
    // this.isView = true;
  }
  viewData(index){
    this.Selectedcsv_file_data=this.csv_files_data[index]
    this.csv_file_datass	=this.Selectedcsv_file_data['data']
    this.isAddQuotation = false;
    this.isView = true;
    this.headers=this.csv_file_datass[11]
    // this.csv_file_datassss = [];  // Initialize as an empty array
    // for (let i = 12; i < this.csv_file_datass.length; i++) {
    //   // Push each row starting from index 12 into csv_file_datassss
    //   this.csv_file_datassss.push(this.csv_file_datass[i]);
    // }
    // Initialize the array
this.csv_file_datassss = [];

// Regular expression to match date in d/m/yyyy or dd/mm/yyyy format
const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/;

// List of words to exclude (case-insensitive)
const excludedWords = ['gross', 'net','----------','--------------------------------------------------------------------------------'];

// Regular expression to match more than 3 consecutive dashes (e.g., ----, -----, ------)


for (let i = 12; i < this.csv_file_datass.length; i++) {
  const row = this.csv_file_datass[i];

  // Check if the first column (or any other column) contains a date
  const isDate = dateRegex.test(row[0]); // Change the column index if the date is not in the first column

  // Check if any cell in the row contains more than 3 consecutive dashes or any excluded words (case-insensitive)
  const containsExcludedWords = row.some(cell => {
    return excludedWords.some(word => cell.toLowerCase().includes(word.toLowerCase()));
  });

  // If it's not a date and does not contain excluded words or excessive dashes, push the row
  if (!isDate && !containsExcludedWords) {
    this.csv_file_datassss.push(row);
  }
}

// this.EmailService.setData(this.csv_file_datassss);
    
  }
}
