import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-upload-purchase',
  templateUrl: './upload-purchase.component.html',
  styleUrls: ['./upload-purchase.component.scss']
})
export class UploadPurchaseComponent implements OnInit {
  purchase_file;
  tempName;
  selectedFile: File;
  isUploadPrescription = 0;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
  }

  saveUpload() {
    const uploadData = new FormData();
    if (this.isUploadPrescription === 1) {
      uploadData.append('prescription', this.selectedFile, this.selectedFile.name);
    }
    this.service.post('master.php?type=getCustomers', uploadData)
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.tempName = response['nameFile'];
        this.purchase_file = '';
        this.isUploadPrescription = 0;
        alert('Uploaded Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    this.isUploadPrescription = 1;
  }
}
