import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redeem-history',
  templateUrl: './redeem-history.component.html',
  styleUrls: ['./redeem-history.component.scss']
})
export class RedeemHistoryComponent implements OnInit {
  redeemHistory;
  constructor(private service: DataAccessService, private router: Router) {
  }

  ngOnInit() {
    this.getRedeemHistory();
  }

  getRedeemHistory() {
    this.service.get('sales.php?type=getRedeemHistory').subscribe(response => {
      this.redeemHistory = response;
    });
  }

  newCardActivation() {
    this.router.navigate(['/cardActivation']);
  }

  cardDetails() {
    this.router.navigate(['/cardDetails']);
  }

  medicalStores() {
    this.router.navigate(['/medicalStores']);
  }

  purchaseOrder() {
    this.router.navigate(['/aushadhmitrapurchase']);
  }

  receivedOrders() {
    this.router.navigate(['/receivedOrders']);
  }

  merchantStores() {
    this.router.navigate(['/merchants']);
  }

}
