//import { MerchantStoresComponent } from './aushadhmitra/merchant-stores/merchant-stores.component';
import { PaymentsComponent } from './forms/payments/payments.component';
import { AssetsMasterComponent } from './account/assets-master/assets-master.component';
import { InvestmentMasterComponent } from './account/investment-master/investment-master.component';
import { ExpenseComponent } from './account/expense/expense.component';
//import { ReceivedOrdersComponent } from './aushadhmitra/received-orders/received-orders.component';
import { GeneralPurchaseViewComponent } from './reports/general-purchase-view/general-purchase-view.component';
import { GeneralSalesViewComponent } from './reports/general-sales-view/general-sales-view.component';
import { GeneralComponent } from './forms/general/general.component';
import { GeneralDistributorsComponent } from './master/general-distributors/general-distributors.component';
import { GeneralSalesComponent } from './forms/general-sales/general-sales.component';
import { GeneralPurchaseComponent } from './forms/general-purchase/general-purchase.component';
import { AdjustSalesGSTComponent } from './account/adjust-sales-gst/adjust-sales-gst.component';
import { AdjustPurchaseGSTComponent } from './account/adjust-purchase-gst/adjust-purchase-gst.component';
import { AdjustGSTComponent } from './account/adjust-gst/adjust-gst.component';
import { AccountComponent } from './account/account.component';
import { StockAdjustmentComponent } from './forms/stock-adjustment/stock-adjustment.component';
import { DoctorComponent } from './master/doctor/doctor.component';
import { AllReportsComponent } from './reports/all-reports/all-reports.component';
import { ChequeComponent } from './master/cheque/cheque.component';
import { BankComponent } from './master/bank/bank.component';
import { GenericComponent } from './master/generic/generic.component';
import { DosageComponent } from './master/dosage/dosage.component';
import { DistributorComponent } from './master/distributor/distributor.component';
import { ManufacturerComponent } from './master/manufacturer/manufacturer.component';
import { SalesmanComponent } from './master/salesman/salesman.component';
import { ShelfComponent } from './master/shelf/shelf.component';
import { SchememasterComponent } from './master/schememaster/schememaster.component';

import { SalesFormComponent } from './forms/sales-form/sales-form.component';
import { WsalesComponent } from './forms/wsales/wsales.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { PurchaseFormComponent } from './forms/purchase-form/purchase-form.component';
import { HsnComponent } from './master/hsn/hsn.component';
import { PatientComponent } from './master/patient/patient.component';
import { ProductComponent } from './master/product/product.component';
import { MasterDashboardComponent } from './master/master-dashboard/master-dashboard.component';
import { PoCustomizeComponent } from './master/purchase_order/po-customize/PoCustomizeComponent';
import { PoReportCustomiseComponent } from './master/po-report-customise/po-report-customise.component';
import { PoReportAutoComponent } from './master/po-report-auto/po-report-auto.component';
import { SalesReportComponent } from './reports/sales-report/sales-report.component';
import { PurchaseReportComponent } from './reports/purchase-report/purchase-report.component';
import { CategoryPurchaseComponent } from './reports/category-purchase/category-purchase.component';
import { ProductPurchaseComponent } from './reports/product-purchase/product-purchase.component';
import { ProductSaleComponent } from './reports/product-sale/product-sale.component';
import { CategorySalesComponent } from './reports/category-sales/category-sales.component';
import { DistributorPaymentComponent } from './distributor-payment/distributor-payment.component';
import { CustomerPaymentComponent } from './customer-payment/customer-payment.component';
import { DebitNoteComponent } from './debit-note/debit-note.component';
import { SalesReturnComponent } from './sales-return/sales-return.component';
import { DailyClosingComponent } from './daily-closing/daily-closing.component';
import { UserAssignComponent } from './user-assign/user-assign.component';
import { OpeningStockComponent } from './forms/opening-stock/opening-stock.component';
import { UploadPurchaseComponent } from './forms/upload-purchase/upload-purchase.component';
import { RedeemHistoryComponent } from './forms/redeem-history/redeem-history.component';
// import { CardActivationComponent } from './aushadhmitra/card-activation/card-activation.component';
// import { CardDetailsComponent } from './aushadhmitra/card-details/card-details.component';
// import { MedicalStoresComponent } from './aushadhmitra/medical-stores/medical-stores.component';
// import { PurchaseOrderComponent } from './aushadhmitra/purchase-order/purchase-order.component';
// import { UploadProductComponent } from './aushadhmitra/upload-product/upload-product.component';
import { LoanMasterComponent } from './account/loan-master/loan-master.component';
import { LedgerComponent } from './account/ledger/ledger.component';
import { HospitalComponent } from './master/hospital/hospital.component';
import { IpdComponent } from './forms/ipd/ipd.component';
import { NewAllReportComponent } from './reports/new-all-report/new-all-report.component';
import { MarginComponent } from './master/margin/margin.component';
import { WsalesreturnComponent } from './wsalesreturn/wsalesreturn.component';
import { DebitnoteComponent } from './debitnote/debitnote.component';
import { RetmarginComponent } from './retmargin/retmargin.component';

import { DemodashComponent } from './demodash/demodash.component';

import { MyaccComponent } from './master/myacc/myacc.component';

import { ExpiryComponent } from './reports/expiry/expiry.component';

import { WcustomerpaymentComponent } from './wcustomerpayment/wcustomerpayment.component';
import { SalesComponent } from './sales/sales.component';
import { MailFilesComponent } from './mail-files/mail-files.component';
import { FetchComponent } from './fetch/fetch.component';
import { MpurchaseComponent } from './mpurchase/mpurchase.component';
import { BfetchComponent } from './bfetch/bfetch.component';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full', },
  { path: '404', component: P404Component },
  { path: '500', component: P500Component },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  {
    path: '', component: DefaultLayoutComponent,
    children: [
      { path: 'MailFiles', component: MailFilesComponent},
      { path: 'sales', component: SalesComponent},
      { path: 'bfetch', component: BfetchComponent},
      { path: 'fetch', component: FetchComponent},
      { path: 'ipdCounter', component: IpdComponent},
      { path: 'hospitalMaster', component: HospitalComponent},
      { path: 'purchase', component: PurchaseComponent},
      { path: 'mpurchase', component: MpurchaseComponent},
      { path: 'new-purchase', component: PurchaseFormComponent },
      { path: 'new-sales', component: SalesFormComponent },
      { path: 'wsales', component: WsalesComponent },
      { path: 'patient', component: PatientComponent },
      { path: 'manufacturer', component: ManufacturerComponent },
      { path: 'distributor', component: DistributorComponent },
      { path: 'product', component: ProductComponent },
      { path: 'dosage', component: DosageComponent },
      { path: 'hsn', component: HsnComponent },
      { path: 'shelf', component: ShelfComponent},
      { path: 'clientAcc', component: MyaccComponent},
      { path: 'schemeMaster', component: SchememasterComponent},
      { path: 'generic', component: GenericComponent },
      { path: 'purchaseOrder', component: PoCustomizeComponent },
      { path: 'bank', component: BankComponent },
      { path: 'cheque', component: ChequeComponent },
      { path: 'poReportCutomise', component: PoReportCustomiseComponent },
      { path: 'poReportAuto', component: PoReportAutoComponent },
      { path: 'salesreport', component: SalesReportComponent },
      { path: 'purchasereport', component: PurchaseReportComponent },
      { path: 'categorypurchase', component: CategoryPurchaseComponent },
      { path: 'productpurchase', component: ProductPurchaseComponent },
      { path: 'debitNoteDash', component: DebitnoteComponent },
      { path: 'wSalesReturn', component: WsalesreturnComponent },
      { path: 'MasterDashboardComponent', component: MasterDashboardComponent },
      { path: 'productsales', component: ProductSaleComponent },
      { path: 'categorysales', component: CategorySalesComponent },
      { path: 'distributorPayment', component: DistributorPaymentComponent },
      { path: 'customerPayment', component: CustomerPaymentComponent },
      { path: 'debitNote', component: DebitNoteComponent },
      { path: 'salesReturn', component: SalesReturnComponent },
      { path: 'dailyclosing', component: DailyClosingComponent },
      { path: 'userassignment', component: UserAssignComponent },
      { path: 'wCustPaymant', component: WcustomerpaymentComponent },
      { path: 'poauto', component: PoReportAutoComponent },
      { path: 'newAllReport', component: AllReportsComponent },
      { path: 'openingstock', component: OpeningStockComponent },
      { path: 'stockadjustment', component: StockAdjustmentComponent },
      { path: 'account', component: AccountComponent },
      { path: 'doctor', component: DoctorComponent },
      { path: 'uploadPurchase', component: UploadPurchaseComponent },
      { path: 'adjustGST', component: AdjustGSTComponent },
      { path: 'adjustPurchaseGST', component: AdjustPurchaseGSTComponent },
      { path: 'adjustSalesGST', component: AdjustSalesGSTComponent },
      { path: 'redeemHistory', component: RedeemHistoryComponent },
      // { path: 'cardActivation', component: CardActivationComponent },
      // { path: 'cardDetails', component: CardDetailsComponent },
      // { path: 'medicalStores', component: MedicalStoresComponent },
      // { path: 'aushadhmitrapurchase', component: PurchaseOrderComponent },
      // { path: 'uploadProduct', component: UploadProductComponent },
      { path: 'generalPurchase', component: GeneralPurchaseComponent },
      { path: 'generalSales', component: GeneralSalesComponent },
      { path: 'generalSalesview', component: GeneralSalesViewComponent },
      { path: 'generalPurchaseview', component: GeneralPurchaseViewComponent },
      { path: 'generalDistributors', component: GeneralDistributorsComponent },
      { path: 'general', component: GeneralComponent },
      //{ path: 'receivedOrders', component: ReceivedOrdersComponent },
      { path: 'expenseMaster', component: ExpenseComponent },
      { path: 'loanMaster', component: LoanMasterComponent },
      { path: 'investmentMaster', component: InvestmentMasterComponent },
      { path: 'assetsMaster', component: AssetsMasterComponent },
      { path: 'ledger', component: LedgerComponent },
      { path: 'salesman', component: SalesmanComponent },
      { path: 'margine', component: MarginComponent },
      { path: 'returnMargin', component: RetmarginComponent },
      { path: 'payments', component: PaymentsComponent },
     // { path: 'merchants', component: MerchantStoresComponent },
      { path: 'allreports', component: NewAllReportComponent },
      { path: 'demodash', component: DemodashComponent },
      { path: 'expiryManagement', component: ExpiryComponent },
      
      { path: 'dashboard', loadChildren: './views/dashboard/dashboard.module#DashboardModule' },
      //{ path: 'aushadhmitra', loadChildren: './aushadhmitra/aushadhmitra.module#AushadhmitraModule'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
