import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-payment',
  templateUrl: './customer-payment.component.html',
  styleUrls: ['./customer-payment.component.scss']
})
export class CustomerPaymentComponent implements OnInit {
  payments;
  selectedCustomer;
  isFirst = false;
  isCash = true;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getDuePayments();
  }

  getDuePayments() {
    this.service.get('payments.php?type=getCustomerDuePayments').subscribe(response => {
      this.payments = response;
    });
  }

  getDetails(index) {
    this.selectedCustomer = this.payments[index];
    this.isFirst = true;
  }

  distributerAmount(data1) {
    const temp1 = data1.value;
    if (temp1['mode'] === 'Cash') {
      this.isCash = true;
    } else {
      this.isCash = false;
    }
    let value = temp1['amount'];
    let temp = 0;
    const data = this.selectedCustomer['details'];
    const length = Object.keys(data).length;
    for (let  i = 0; i < length; i++) {
      if (+value <= +data[i].balance) {
        data[i].payment_amount = +value;
        data[i].balance_amount = data[i].balance - value;
        value = value - value;
        temp = 1;
      } else if (+value > +data[i].balance) {
        data[i].payment_amount = +data[i].balance;
        value = +value - +data[i].balance;
        data[i].balance_amount = 0;
      } else if (value === 0) {
        data[i].payment_amount = 0;
        if (temp === 0) {
          data[i].balance_amount = data[i].balance;
        }
      }
      data[i].payment_mode = temp1['mode'];
      data[i].transaction_no = temp1['transaction_no'];
    }
    this.selectedCustomer['details'] = data;
  }

  saveCustomerPayment(data) {
    const temp = data.value;
    this.selectedCustomer['amount'] = temp.amount;
    this.selectedCustomer['mode'] = temp.mode;
    this.selectedCustomer['transaction_no'] = temp.transaction_no;
    this.service.post('payments.php?type=saveCustomerPayments', JSON.stringify(this.selectedCustomer))
    .subscribe(response => {
      if (response['status'] === 'success') {
        data.reset();
        this.getDuePayments();
        delete this.selectedCustomer;
        this.isFirst = false;
      } else {
        alert(response['status']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

}
