import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-general-distributors',
  templateUrl: './general-distributors.component.html',
  styleUrls: ['./general-distributors.component.scss']
})
export class GeneralDistributorsComponent implements OnInit {
  isForm = false;
  isTable = true;
  distList;
  distributors1 = [];
  isGstType = false;
  isIgstType = false;

  ngOnInit() {
    this.getDist();
  }
  constructor(private service: DataAccessService) { }
  getDist() {
    this.service.get('master.php?type=getGeneralDistributors')
    .subscribe(response => {
      this.distList = response;
      this.distributors1 = this.distList;
      const element1 = document.getElementById('isAddNew') as HTMLElement;
      element1.focus();
    });
  }
  saveDist(customerData) {
    this.service.post('master.php?type=saveGeneralDist', JSON.stringify(customerData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getDist();
        alert('Distributor Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }
  getType(type) {
    if (type === 'GST') {
      this.isIgstType = false;
      this.isGstType = true;
    } else  if (type === 'IGST') {
      this.isGstType = false;
      this.isIgstType = true;
    }
  }

  checkValidation(value, value1) {
    if (value1 === 'contact') {
       if ((value.toString().length < 10) || value.toString().length > 12) {
         alert('Invalid Contact Number. Please enter valid contact number!');
         return;
       }
     } else if (value1 === 'email') {
       const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
         if (reg.test(value) === false) {
             alert('Invalid Email Address');
             return false;
         }
         return true;
     }
   }

  filterTable(value) {
    this.distributors1 = [];
    let index = 0;
    const length = Object.keys(this.distList).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.distList[i].d_name + ' ' + this.distList[i].d_cont + ' ' + this.distList[i].d_add + ' ' + this.distList[i].cont_per).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.distributors1[index] = this.distList[i];
        index++;
      }
    }
  }
}
