import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-daily-closing',
  templateUrl: './daily-closing.component.html',
  styleUrls: ['./daily-closing.component.scss']
})
export class DailyClosingComponent implements OnInit {
  todays_date;
  sales_cash = 0;
  sales_card = 0;
  sales_credit = 0;
  total_sales = 0;
  payment_cash = 0;
  payment_card = 0;
  payment_check = 0;
  total_payment = 0;
  debit_cash = 0;
  debit_card = 0;
  debit_check = 0;
  total_cash = 0;

  available_cash = 0;
  constructor(private service: DataAccessService) {
    const today = new Date();
    this.todays_date = today.toISOString().substr(0, 10);
  }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.service.get('dailyclosing.php?type=getDetails&todays_date=' + this.todays_date).subscribe(response => {
      this.sales_cash = +(response['sales_cash']);
      this.sales_card = +(response['sales_card']);
      this.sales_credit = +(response['sales_credit']);
      this.payment_cash = +(response['payment_cash']);
      this.payment_card = +(response['payment_card']);
      this.payment_check = +(response['payment_check']);
      this.debit_cash = +(response['debit_cash']);
      this.debit_card = +(response['debit_card']);
      this.debit_check = +(response['debit_check']);
      this.total_sales = +(response['total_sales']);
      this.total_cash = +(response['total_cash']);
      this.total_payment = +(response['total_payment']);
    });
  }

  dayend() {
    // tslint:disable-next-line:max-line-length
    this.service.get('getdata.php?type=day_end&available_cash=' + this.available_cash + '&total_sale=' + this.total_cash + '&todays_date=' + this.todays_date).subscribe(response => {
      if (response['status'] === 'success') {
        this.available_cash = 0;
        const element1 = document.getElementById('date') as HTMLElement;
        element1.focus();
        alert('Day End value saved Successful');
      }
    });
  }

}
