import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-sales-return',
  templateUrl: './sales-return.component.html',
  styleUrls: ['./sales-return.component.scss']
})
export class SalesReturnComponent implements OnInit {
  sales;
  isFirst = false;
  finalResult = [];
  exp_date;
  sales1 = [];
  constructor(private service: DataAccessService) {
    const month = new Date();
    const mon = month.getMonth() + 1;
    this.exp_date = month.getFullYear() + '-' + mon;
   }

  ngOnInit() {
    this.getSales();
  }

  getSales() {
    this.service.get('sales.php?type=getSalesForReturn').subscribe(data => {
      this.sales = data;
      this.sales1 = this.sales;
      const element1 = document.getElementById('search') as HTMLElement;
      element1.focus();
    });
  }

  getDetails(index) {
    this.finalResult = this.sales[index];
    //this.finalResult['return_total'] = 0;
    this.isFirst = true;
  }

  updateBill(qty, index, bill_date) {
    const month = new Date();
    const mon = month.getMonth() + 1;
    const yyyy = month.getFullYear();
    const temp = this.finalResult['products'];

    // if(qty > temp[index].qty){
    //   alert('Return Qty Cant Be Greater Than Purchase Qty !!!!!');
    //   temp[index].return_qty = 0;
    //   return;
    // }


    bill_date = bill_date.split('-');
    // tslint:disable-next-line:radix
    const year = parseInt(bill_date[0]);
    // tslint:disable-next-line:radix
    bill_date = parseInt(bill_date[1]);
    if (bill_date === mon && year === yyyy) {
      const return_amt = (temp[index].unit_mrp * +qty).toFixed(2);
      temp[index].return_amt = return_amt;
      temp[index].return_qty = qty;
      this.finalResult['products'] = temp;
      const value = parseFloat((+this.finalResult['return_total'] + +return_amt).toFixed(2));
      this.finalResult['return_total'] = value;
    } else {
      const return_amt = parseFloat(((temp[index].unit_mrp * +qty) / (1 + (temp[index].gst / 100))).toFixed(2));
      temp[index].return_amt = return_amt;
      temp[index].return_qty = qty;
      this.finalResult['products'] = temp;
      const value = parseFloat((+this.finalResult['return_total'] + +return_amt).toFixed(2));
      this.finalResult['return_total'] = value;
    }
  }

  saveReturnBill() {
      
      this.service.post('return.php?type=saveReturnBill', JSON.stringify(this.finalResult))
      .subscribe(response => {
        if (response['status'] === 'success') {
          this.finalResult = [];
          this.isFirst = false;
          this.getSales();
        } else {
          alert(response['status']);
        }
        },
      (error: Response) => {
        if (error.status === 400) {
          alert('An error has occurred.');
        } else {
          alert('An error has occurred, http status:' + error.status);
        }
      });
    
  }

  filterTable(value) {
    this.sales1 = [];
    let index = 0;
    const length = Object.keys(this.sales).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      let tempstring = (this.sales[i].order_no + ' ' + this.sales[i].order_date + ' ' + this.sales[i].patient_name + ' ' + this.sales[i].doctor_name + ' ' + this.sales[i].total_payble + ' ' + this.sales[i].paid + ' ' + this.sales[i].balance + ' ' + this.sales[i].return_total).toLowerCase();
      value = value.toLowerCase();
      if (tempstring.indexOf(value) > -1 ) {
        this.sales1[index] = this.sales[i];
        index++;
      }
    }
  }
}
