import { Component, OnInit, ViewChild } from '@angular/core';
import { DataAccessService } from './../../data-access.service';
import { SelectComponent } from 'ng-select';
declare var $: any;

@Component({
  selector: 'app-general-purchase',
  templateUrl: './general-purchase.component.html',
  styleUrls: ['./general-purchase.component.scss']
})
export class GeneralPurchaseComponent implements OnInit {
  @ViewChild('prod') selectComponent: SelectComponent;
  @ViewChild('dist') selectComponent1: SelectComponent;
  @ViewChild('hsn1') selectComponent2: SelectComponent;
  newProductIndex = 0;
  hsn_index = 0;
  hsns;
  distributors;
  shelfs;
  templist = {};
  productList = [];
  index = 0;
  gross_total = 0;
  disc_total = 0;
  tax_total = 0;
  net_total = 0;
  cash_disc = 0;
  total_payble = 0;
  total_disc_bill = 0;
  invoice_no = '';
  cash_credit = 'Credit';
  scheme_netrate = 'Scheme';
  entercount = 0;

  hsn = '';
  product = '';
  sale_rate = '';
  expiry_date = '';
  qty = 0;
  scheme = 0;
  disc = 0;
  purchase_rate = 0;
  gst_type = 'GST';
  gst = 12;
  sgst = 0;
  cgst = 0;
  igst = 0;
  mrp = 0;
  p_cat = '';
  taxable = 0;
  shelf = '';
  generic = '';
  pack_size = '';
  order_no = '';
  p_no = '';
  m_name = '';
  lowest_rate = 0;
  highest_rate = 0;
  disc_amt: number;
  isScheme = true;
  scheme_qty = 0;
  scheme_f_qty = 0;
  net_rate: number;
  net_taxable: number;
  net_discount: number;
  sch_per: number;

  isgst_type = true;
  invoice_date;
  availableDist;
  distributor_name;
  isNewProduct = false;
  isGstType = true;
  isIgstType = false;
  isInit = false;
  isSavePurchase = 0;
  isVerify = false;
  temp;
  constructor(private service: DataAccessService) {
    this.net_rate = 0;
    this.net_taxable = 0;
    this.net_discount = 0;
    this.sch_per = 0;
    this.disc_amt = 0;
    this.getTodaysDate();
   }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.isInit = false;
    this.availableDist = Array();
    this.service.get('purchase.php?type=getGeneralInitDetails')
    .subscribe(response => {
      const data = response;
      this.distributors = data['distributors'];
      this.isInit = true;
      this.hsns = data['hsns'];
      this.shelfs = data['shelfs'];
    });
  }

  addProduct() {
    if (this.product === '' || this.qty === 0) {
      alert('All fields are compulsory');
      return;
    }
    this.templist['p_no'] = this.p_no;
    this.templist['hsn'] = this.hsn;
    this.templist['product'] = this.product;
    this.templist['sale_rate'] = this.sale_rate;
    this.templist['m_name'] = this.m_name;
    this.templist['expiry_date'] = this.expiry_date;
    this.templist['qty'] = this.qty;
    this.templist['scheme'] = this.scheme;
    this.templist['disc'] = this.disc;
    this.templist['gst'] = this.gst;
    this.templist['shelf'] = this.shelf;
    this.templist['scheme_netrate'] = this.scheme_netrate;
    if (this.scheme_netrate === 'Scheme') {
      this.templist['taxable'] = this.taxable;
    } else {
      this.templist['taxable'] = this.net_taxable;
      this.templist['net_scheme_rate'] = this.net_rate;
      this.templist['net_scheme_disc'] = this.net_discount;
    }
    this.templist['disc_amt'] = this.disc_amt;
    this.templist['purchase_rate'] = this.purchase_rate;
    if (this.gst_type === 'GST') {
      this.templist['sgst'] = parseFloat((this.templist['taxable'] * (this.gst / (100 * 2))).toFixed(2));
      this.templist['cgst'] = parseFloat((this.templist['taxable'] * (this.gst / (100 * 2))).toFixed(2));
      this.templist['igst'] = 0;
    } else {
      this.templist['sgst'] = 0;
      this.templist['cgst'] = 0;
      this.templist['igst'] = parseFloat((this.templist['taxable'] * (this.gst / 100)).toFixed(2));
    }
    this.templist['mrp'] = this.mrp;
    this.templist['p_cat'] = this.p_cat;
    this.templist['generic'] = this.generic;
    this.templist['pack_size'] = this.pack_size;
// tslint:disable-next-line: max-line-length
    const temp1 = parseFloat((((this.purchase_rate * this.qty) / (this.purchase_rate * (this.qty + this.scheme))) * this.purchase_rate).toFixed(2));
    this.templist['net_rate'] = temp1 + ((temp1 * this.disc) / 100);
    // this.templist['net_rate'] = parseFloat((this.templist['taxable'] + ((this.templist['taxable'] * this.gst) / 100)).toFixed(2));
    // tslint:disable-next-line:max-line-length
    this.templist['net_total'] = parseFloat((this.net_taxable + this.templist['sgst'] + this.templist['cgst'] + this.templist['igst']).toFixed(2));
    this.productList[this.index] = this.templist;
    this.gross_total = this.gross_total + this.taxable;
    this.disc_total = parseFloat((this.disc_total + +this.templist['disc_amt']).toFixed(2));
    this.tax_total = parseFloat((this.tax_total + this.templist['sgst'] + this.templist['cgst'] + this.templist['igst']).toFixed(2));
    this.net_total = this.gross_total + this.tax_total;
    this.total_payble = parseFloat((this.net_total - ((this.net_total * this.cash_disc) / 100)).toFixed(2));
    this.total_disc_bill = parseFloat((this.disc_total + ((this.net_total * this.cash_disc) / 100)).toFixed(2));
    this.index++;
    this.templist = {};
    this.selectComponent2.open();
    this.hsn = '';
    this.product = '';
    this.sale_rate = '';
    this.expiry_date = '';
    this.qty = 0;
    this.scheme = 0;
    this.generic = '';
    this.disc = 0;
    this.purchase_rate = 0;
    this.gst = 12;
    this.mrp = 0;
    this.p_cat = '';
    this.shelf = '';
    this.sgst = 0;
    this.cgst = 0;
    this.igst = 0;
    this.taxable = 0;
    this.net_taxable = 0;
    this.pack_size = '';
    this.p_no = '';
    this.net_rate = 0;
    this.scheme_qty = 0;
    this.sch_per = 0;
    this.scheme_f_qty = 0;
    this.net_discount = 0;
  }

  calculateGST() {
    if (this.scheme_netrate === 'Scheme') {
      if (this.gst_type === 'GST') {
        this.sgst = parseFloat((this.taxable * (this.gst / (100 * 2))).toFixed(2));
        this.cgst = parseFloat((this.taxable * (this.gst / (100 * 2))).toFixed(2));
        this.igst = 0;
      } else {
        this.igst = parseFloat((this.taxable * (this.gst / 100)).toFixed(2));
        this.sgst = 0;
        this.cgst = 0;
      }
    } else {
      if (this.gst_type === 'GST') {
        this.sgst = parseFloat((this.net_taxable * (this.gst / (100 * 2))).toFixed(2));
        this.cgst = parseFloat((this.net_taxable * (this.gst / (100 * 2))).toFixed(2));
        this.igst = 0;
      } else {
        this.igst = parseFloat((this.net_taxable * (this.gst / 100)).toFixed(2));
        this.sgst = 0;
        this.cgst = 0;
      }
    }
  }

  getGSTtype(event) {
    this.distributor_name = event.d_name;
    this.gst_type = event.gst_type;
    if (this.gst_type === 'GST') {
      this.isgst_type = true;
    } else {
      this.isgst_type = false;
    }
  }

  showDistributorModal() {
    const ans = confirm('Add this Distributor in List ?');
    this.distributor_name = '';
    if (ans === true) {
      $('#exampleModal').modal('show');
    } else {
      const element1 = document.getElementById('distributor_name') as HTMLElement;
      element1.focus();
    }
  }

  getMRP(event) {
    this.product = event.p_name;
    this.p_no = event.p_no;
    this.mrp = event.mrp;
    this.p_cat = event.p_cat;
    this.generic = event.generic;
    this.pack_size = event.pack_size;
    this.m_name = event.m_name;
    this.lowest_rate = event.lowest_rate;
    this.highest_rate = event.highest_rate;
  }

  showProductModal() {
    if (this.newProductIndex === 0) {
      const ans = confirm('Add this Product in List ?');
      this.product = '';
      if (ans === true) {
        $('#productModal').modal('show');
      }
    }
  }

  checkevent(event) {
    // tslint:disable-next-line:max-line-length
    if (this.hsn !== '' && this.product !== '' && this.qty !== 0 && this.purchase_rate !== 0) {
      if (this.qty <= 0) {
        alert('Invalid Quantity');
        this.qty = 0;
        const element1 = document.getElementById('qty') as HTMLElement;
        element1.focus();
        return;
      }
      this.addProduct();
    }
  }

  calculateTaxable() {
    if (this.scheme_netrate === 'Scheme') {
      this.taxable = parseFloat(((this.qty * +this.purchase_rate) - (((this.qty * +this.purchase_rate) * this.disc) / 100)).toFixed(2));
      this.disc_amt = parseFloat((((this.qty * +this.purchase_rate) * this.disc) / 100).toFixed(2));
    } else {
      this.calculateNetRate();
    }
  }

  discountTotalBill() {
    this.total_payble = parseFloat((this.net_total - ((this.net_total * this.cash_disc) / 100)).toFixed(2));
    this.total_disc_bill = parseFloat((this.disc_total + ((this.net_total * this.cash_disc) / 100)).toFixed(2));
  }

  checkExpiry(value) {
    const date1 = new Date();
    const date2 = new Date(value);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (diffDays < 180) {
      return 'red';
    }
  }

  savePurchase(data) {
    const temp = data.value;
    temp['products'] = this.productList;
    temp['gross_total'] = this.gross_total;
    temp['disc_total'] = this.total_disc_bill;
    temp['tax_total'] = this.tax_total;
    temp['net_total'] = this.total_payble;
    temp['gst_type'] = this.gst_type;
    if (temp['invoice_no'] === '') {
      alert('Invoice No is Required');
      return;
    }
    if (temp['invoice_date'] === '') {
      alert('Invoice Date is Required');
      return;
    }
    if (temp['distributor_name'] === '') {
      alert('Distributer Name is Required');
      return;
    }
    if (this.index === 0) {
      alert('At least 1 product is Required in Product List');
      return;
    }
    this.temp = temp;
    this.isVerify = true;
  }

  verifyPurchase() {
    this.service.post('purchase.php?type=saveGeneralPurchase', JSON.stringify(this.temp))
    .subscribe(response => {
      this.isSavePurchase = 0;
      if (response['status'] === 'success') {
        alert('Purchase No:' + response['po_no']);
        this.index = 0;
        this.productList = [];
        this.templist = {};
        this.taxable = 0;
        this.gross_total = 0;
        this.disc_total = 0;
        this.tax_total = 0;
        this.net_total = 0;
        this.total_payble = 0;
        this.total_disc_bill = 0;
        this.isgst_type = true;
        this.cash_disc = 0;
        this.invoice_no = '';
        this.cash_credit = 'Credit';
        this.distributor_name = '';
        this.cash_credit = 'Credit';
        this.hsn = '';
        this.product = '';
        this.sale_rate = '';
        this.expiry_date = '';
        this.shelf = '';
        this.scheme = 0;
        this.purchase_rate = 0;
        this.disc = 0;
        this.gst = 12;
        this.mrp = 0;
        this.qty = 0;
        this.lowest_rate = 0;
        this.highest_rate = 0;
        this.getTodaysDate();
        this.getDetails();
        this.order_no = response['order_no'];
      } else {
        alert(response['status']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  editPurchase() {
    this.isVerify = false;
  }

  getTodaysDate() {
    const today = new Date();
    this.invoice_date = today.toISOString().substr(0, 10);
  }

  getType(type) {
    if (type === 'GST') {
      this.isIgstType = false;
      this.isGstType = true;
    } else  if (type === 'IGST') {
      this.isGstType = false;
      this.isIgstType = true;
    }
  }

  saveDist(customerData) {
    this.service.post('master.php?type=saveGeneralDist', JSON.stringify(customerData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.service.get('purchase.php?type=getInitDetails')
        .subscribe(response1 => {
          $('#exampleModal').modal('hide');
          customerData.reset();
          this.distributors = response1['distributors'];
          this.selectComponent1.open();
        });
      } else {
        alert('An error has occurred.');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  saveProduct(productData) {
    this.service.post('master.php?type=saveProduct', JSON.stringify(productData.value))
     .subscribe(response => {
       if (response['status'] === 'success') {
        productData.reset();
        this.product = '';
       } else {
          alert('An error has occurred.');
       }
       },
     (error: Response) => {
       if (error.status === 400) {
         alert('An error has occurred.');
       } else {
         alert('An error has occurred, http status:' + error.status);
       }
     });
   }

   checkValidation(value, value1) {
    if (value1 === 'contact') {
       if ((value.toString().length < 10) || value.toString().length > 12) {
         alert('Invalid Contact Number. Please enter valid contact number!');
         return;
       }
     } else if (value1 === 'email') {
       const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
         if (reg.test(value) === false) {
             alert('Invalid Email Address');
             return false;
         }
         return true;
     }
   }

   deleteProduct(index) {
    this.gross_total = this.gross_total - this.productList[index].taxable;
    // tslint:disable-next-line:max-line-length
    this.disc_total = parseFloat((this.disc_total - +(((this.productList[index].qty * +this.productList[index].purchase_rate) * this.productList[index].disc) / 100)).toFixed(2));
    // tslint:disable-next-line:max-line-length
    this.tax_total = parseFloat((this.tax_total - this.productList[index].sgst - this.productList[index].cgst - this.productList[index].igst).toFixed(2));
    this.net_total = this.gross_total + this.tax_total;
    this.total_payble = parseFloat((this.net_total - ((this.net_total * this.cash_disc) / 100)).toFixed(2));
    this.total_disc_bill = parseFloat((this.disc_total + ((this.net_total * this.cash_disc) / 100)).toFixed(2));
    this.productList.splice(index, 1);
    this.index--;
   }

   checkScheme(value) {
     if (value === 'Scheme') {
       this.isScheme = true;
     } else {
       this.isScheme = false;
     }
     this.calculateTaxable();
   }

   showHSNModal() {
    if (this.hsn_index === 0) {
      this.hsn_index = 1;
      const hsn_code = prompt('Enter HSN Code:');
      if (hsn_code !== null) {
        this.service.get('purchase.php?type=saveHSN&hsn_code=' + hsn_code).subscribe(response => {
          this.hsns = response;
        });
      }
    }
   }

   calculateNetRate() {
     if (this.scheme_netrate === 'Net Rate') {
      if (this.scheme_qty === 0 || this.scheme_f_qty === 0) {
        this.net_rate = 0;
      } else {
        this.net_rate = parseFloat(((this.purchase_rate * this.scheme_qty) / (this.scheme_qty + this.scheme_f_qty)).toFixed(2));
      }
      this.net_discount = parseFloat(((this.qty * +this.purchase_rate) - (this.qty * this.net_rate)).toFixed(2));
      this.net_taxable = parseFloat(((this.qty * this.net_rate) - (((this.qty * +this.net_rate) * this.disc) / 100)).toFixed(2));
      this.disc_amt = parseFloat((((this.qty * +this.net_rate) * this.disc) / 100).toFixed(2));
      this.calculateGST();
    } else {
      this.sch_per = parseFloat(((this.scheme * 100) / this.qty).toFixed(2));
    }
   }

   calculateDiscPer() {
     if (this.scheme_netrate === 'Scheme') {
      this.disc = parseFloat(((100 * this.disc_amt) / (this.qty * this.purchase_rate)).toFixed(2));
      this.calculateTaxable();
     } else {
      this.disc = parseFloat(((100 * this.disc_amt) / (this.qty * this.net_rate)).toFixed(2));
      this.calculateNetRate();
     }
   }

}
