import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {
  isForm = false;
  isTable = true;
  bankList;
  banks1;
  selectedBank = [];
  isEdit = false;

  ngOnInit() {
    this.getBank();
  }
  constructor(private service: DataAccessService) { }

  getBank() {
    this.service.get('master.php?type=getBankDetails')
    .subscribe(response => {
      this.bankList = response;
      this.banks1 = this.bankList;
      const element1 = document.getElementById('isAddNew') as HTMLElement;
      element1.focus();
    });
  }

  saveBank(bankData) {
    this.service.post('master.php?type=saveBank', JSON.stringify(bankData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getBank();
        alert('Bank Details Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  showForm() {
    this.isTable = false;
    this.isForm = true;
    const element1 = document.getElementById('bank_name') as HTMLElement;
    element1.focus();
  }

  viewOrder(index, value) {
    if (value === 'edit') {
      this.selectedBank = this.bankList[index];
      this.isEdit = true;
    } else {
      this.service.get('master.php?type=deleteBank&sr=' + this.bankList[index].sr).subscribe(response => {
        if (response['status'] === 'success') {
          this.getBank();
          alert('Bank Details Deleted Successfully.');
        } else {
          alert('Please Try Again');
        }
      });
    }
  }

  updateBank(bankData) {
    const temp = bankData.value;
    temp['sr'] = this.selectedBank['sr'];
    this.service.post('master.php?type=updateBank', JSON.stringify(temp))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isEdit = false;
        this.isTable = true;
        this.getBank();
        alert('Bank Details Updated Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  filterTable(value) {
    this.banks1 = [];
    let index = 0;
    const length = Object.keys(this.bankList).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.bankList[i].bank_name + ' ' + this.bankList[i].ac_no + ' ' + this.bankList[i].ac_name + ' ' + this.bankList[i].branch + ' ' + this.bankList[i].type).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.banks1[index] = this.bankList[i];
        index++;
      }
    }
  }

}

