import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';

@Component({
  selector: 'app-general-purchase-view',
  templateUrl: './general-purchase-view.component.html',
  styleUrls: ['./general-purchase-view.component.scss']
})
export class GeneralPurchaseViewComponent implements OnInit {
  from_date = '';
  to_date = '';
  distributor_name = '';
  purchases;
  purchase1;
  isFirst = false;
  selectedOrder;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getPurchases();
  }

  getPurchases() {
    this.service.get('purchase.php?type=getGeneralPurchases&id=1')
    .subscribe(response => {
      this.purchases = response;
      this.purchase1 = this.purchases;
    });
  }

  viewOrder(index, value) {
    this.selectedOrder = this.purchases[index];
    if (value === 'view') {
      this.isFirst = true;
    } else if (value === 'pdf') {
      const id = this.selectedOrder['id'];
      window.open(this.service.url1 + '/sales.php?type=orderPDF&id=' + id, '_blank');
    } else if (value === 'print') {
      const id = this.selectedOrder['id'];
      window.open(this.service.url1 + '/sales_print.php?id=' + id, '_blank');
    }
  }

  filterResult() {
    this.purchase1 = [];
    let index = 0;
    const length = Object.keys(this.purchases).length;
    if (this.from_date !== '' && this.to_date === '' && this.distributor_name === '') {
      for (let  i = 0; i < length; i++) {
        if (this.purchases[i].invoice_date >= this.from_date) {
          this.purchase1[index] = this.purchases[i];
          index++;
        }
      }
    } else if (this.from_date === '' && this.to_date !== '' && this.distributor_name === '') {
      for (let  i = 0; i < length; i++) {
        if (this.purchases[i].invoice_date <= this.to_date) {
          this.purchase1[index] = this.purchases[i];
          index++;
        }
      }
    } else if (this.from_date === '' && this.to_date === '' && this.distributor_name !== '') {
      for (let  i = 0; i < length; i++) {
        if (this.purchases[i].distributor_name === this.distributor_name) {
          this.purchase1[index] = this.purchases[i];
          index++;
        }
      }
    } else if (this.from_date !== '' && this.to_date !== '' && this.distributor_name === '') {
      for (let  i = 0; i < length; i++) {
        if (this.purchases[i].invoice_date >= this.from_date && this.purchases[i].invoice_date <= this.to_date) {
          this.purchase1[index] = this.purchases[i];
          index++;
        }
      }
    } else if (this.from_date !== '' && this.to_date === '' && this.distributor_name !== '') {
      for (let  i = 0; i < length; i++) {
        if (this.purchases[i].invoice_date >= this.from_date && this.purchases[i].distributor_name === this.distributor_name) {
          this.purchase1[index] = this.purchases[i];
          index++;
        }
      }
    } else if (this.from_date === '' && this.to_date !== '' && this.distributor_name !== '') {
      for (let  i = 0; i < length; i++) {
        if (this.purchases[i].invoice_date <= this.to_date && this.purchases[i].distributor_name === this.distributor_name) {
          this.purchase1[index] = this.purchases[i];
          index++;
        }
      }
    } else {
      this.purchase1 = this.purchases;
    }
  }

  downloadExcel() {
    // tslint:disable-next-line:max-line-length
    window.open(this.service.url1 + 'purchase.php?type=downloadExcel&distributor_name=' + this.distributor_name + '&from_date=' + this.from_date + '&to_date=' + this.to_date);
  }

  printQRcode(qty, p_no, p_name, mrp) {
    const string = p_name + '$MRP: ' + mrp;
    // tslint:disable-next-line:max-line-length
    window.open(this.service.url1 + 'barcode.php?id=' + localStorage.getItem('id') + '&qty=' + qty + '&string=' + string + '&mrp=' + mrp, '_blank');
  }
}
