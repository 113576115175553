import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';

@Component({
  selector: 'app-assets-master',
  templateUrl: './assets-master.component.html',
  styleUrls: ['./assets-master.component.scss']
})
export class AssetsMasterComponent implements OnInit {
  assets;
  isForm = false;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getAssets();
  }

  getAssets() {
    this.service.get('getdata.php?type=getAssets').subscribe(response => {
      this.assets = response;
    });
  }

  saveAssets(data) {
    this.service.post('savedata.php?type=saveAssets', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'success') {
        alert('Asset has been successfully saved.');
        this.isForm = false;
        data.reset();
        this.getAssets();
      }
    });
  }

}
