import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-generic',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.scss']
})
export class GenericComponent implements OnInit {
  isForm = false;
  isTable = true;
  genericList;
  generics1 = [];

  ngOnInit() {
    this.getGENERIC();
  }
  constructor(private service: DataAccessService) { }

  getGENERIC() {
    this.service.get('master.php?type=getGenericDetails')
    .subscribe(response => {
      this.genericList = response;
      this.generics1 = this.genericList;
      const element1 = document.getElementById('isAddNew') as HTMLElement;
      element1.focus();
    });
  }

  saveGENERIC(genericData) {
    this.service.post('master.php?type=saveGENERIC', JSON.stringify(genericData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getGENERIC();
        alert('Generic Added Successfully.');
      } else {
        alert(response['status']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  filterTable(value) {
    this.generics1 = [];
    let index = 0;
    const length = Object.keys(this.genericList).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.genericList[i].generic).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.generics1[index] = this.genericList[i];
        index++;
      }
    }
  }
}
