import { Component, OnInit } from '@angular/core';
import { DataAccessService } from './../data-access.service';
@Component({
  selector: 'app-demodash',
  templateUrl: './demodash.component.html',
  styleUrls: ['./demodash.component.css']
})
export class DemodashComponent implements OnInit {

  todays_date;
  expProducts;
  client_name;
  sales_cash;
  sales_card;
  sales_credit;
  payment_cash;
  payment_card;
  payment_check;
  debit_cash;
  debit_card;
  debit_check;
  monthly_sale;
  monthly_purchase;
  todays_purchase;
  total_dues;
  todays_dues;
  todays_profit;
  monthly_profit;
  near_expiry;
  payment_dues;

  role;
  isSalesman = false;
  isAdmin = false;
  isPurchase = false;

  constructor(private service: DataAccessService) {
    const today = new Date();
    this.todays_date = today.toISOString().substr(0, 10);
    this.client_name = localStorage.getItem('name');
    this.role = localStorage.getItem('role');
    if (this.role === 'Admin') {
      this.isAdmin = true;
      this.isSalesman = false;
      this.isPurchase = false;
    } else if (this.role === 'Salesman') {
      this.isSalesman = true;
      this.isPurchase = false;
      this.isAdmin = false;
    } else if (this.role === 'Purchase') {
      this.isPurchase = true;
      this.isSalesman = false;
      this.isAdmin = false;
    }
  }

  ngOnInit(): void {
    this.getDetails();
    this.getExProd();
    this.getSales();
  }

  getDetails() {
    // tslint:disable-next-line:max-line-length
    this.service.get('dailyclosing.php?type=getDetails&todays_date=' + this.todays_date + '&isgeneral=' + localStorage.getItem('isgeneral')).subscribe(response => {
      this.sales_cash = +(response['sales_cash']);
      this.sales_card = +(response['sales_card']);
      this.sales_credit = +(response['sales_credit']);
      this.payment_cash = +(response['payment_cash']);
      this.payment_card = +(response['payment_card']);
      this.payment_check = +(response['payment_check']);
      this.debit_cash = +(response['debit_cash']);
      this.debit_card = +(response['debit_card']);
      this.debit_check = +(response['debit_check']);
      this.monthly_sale = +(response['monthly_sale']);
      this.monthly_purchase = +(response['monthly_purchase']);
      this.todays_purchase = +(response['todays_purchase']);
      this.total_dues = +(response['total_dues']);
      this.todays_dues = +(response['todays_dues']);
      this.todays_profit = +(response['todays_profit']);
      this.monthly_profit = +(response['monthly_profit']);
      this.expProducts = response['expired_products'];
      this.near_expiry = response['near_expiry3'];
    });
  }

  getExProd() {
    this.service.get('payments.php?type=getDistributorDuePayments&isgeneral=' + localStorage.getItem('isgeneral')).subscribe(data => {
      this.payment_dues = data;
    });
  }

  sales;
  sales1 = [];
 
  customer_name = '';
  doctor_name = '';
  selectedOrder;
  isFirst = false;
  custType = 'Retail';

  
  getSales() {
    this.service.get('getdata.php?type=getSales1').subscribe(data => {
      this.sales = data;
      this.sales1 = this.sales;
      const element1 = document.getElementById('filter') as HTMLElement;
      element1.focus();
    });
  }

  viewOrder(index, value) {
    this.selectedOrder = this.sales1[index];
    if (value === 'view') {
      this.isFirst = true;
    } else if (value === 'pdf') {
      window.open(this.service.url1 + 'print/sales_bill.php?order_no=' + index + '&client_no=' + localStorage.getItem('id'), '_blank');
      // window.open(this.service.url1 + 'sales.php?type=orderPDF&order_no=' + index + '&id=' + localStorage.getItem('id'), '_blank');
    } else if (value === 'print') {
      // tslint:disable-next-line:max-line-length
      window.open(this.service.url1 + 'sales_print.php?order_no=' + index + '&id=' + localStorage.getItem('id') + '&user=' + localStorage.getItem('cl_saler_name'), '_blank');
    }
  }





  closeReport() {
    this.isFirst = false;
 
  }




}
