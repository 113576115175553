import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from './../data-access.service';


@Component({
  selector: 'app-retmargin',
  templateUrl: './retmargin.component.html',
  styleUrls: ['./retmargin.component.css']
})
export class RetmarginComponent implements OnInit {

  constructor(private router: Router,private service: DataAccessService) { }

  ngOnInit() {
    this.getDiscount();
 
  }
  didcuction = 0 ;

 
  discList;

  getDiscount() {
    this.service.get('master.php?type=getReturnDiduction')
    .subscribe(response => {
      this.discList = response;
    });
  }
  
   

 
 
  saveCredit(shelfData) {
    this.service.post('master.php?type=saveReturnDiduction', JSON.stringify(shelfData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        shelfData.reset();
        this.getDiscount();
        alert('Added Successfully.');
      } else {
        alert(response['status']);
      }
      });
  }

 



  




}
