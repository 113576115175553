import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-salesman',
  templateUrl: './salesman.component.html',
  styleUrls: ['./salesman.component.css']
})
export class SalesmanComponent implements OnInit {
   
    isForm = false;
    isTable = true;
    salesmanList;
    getCheckVal;
    isChronic;
    isOther;
    isEditForm = false;
    selectSalesmanIds;
    p_name;
    reg_id;
    login_id;
    password;
    joining_date;
    salesman1 = [];
    id
    pharmacist_name='';
    reg_no='';
    contact_no;
    email;
    address;
    isNew = false;
    ngOnInit() {
      this.getSalesman();
    }
    constructor(private service: DataAccessService) { }
  
    getSalesman() {
      this.service.get('master/salesman.php?type=getSalesmans')
      .subscribe(response => {
        this.salesmanList = response;
        this.salesman1 = this.salesmanList;
      });
    }
  
    saveSalesman(data) {
      if (!data.valid) {
        alert('Please add mandatory fields!');
        return;
      }
      this.service.post('master/salesman.php?type=saveSalesman', JSON.stringify(data.value)).subscribe(response => {
        if (response['status'] === 'success') {
          this.isForm = false;
          this.isTable = true;
          this.getSalesman();
          alert('Salesman Added Successfully.');
          const element1 = document.getElementById('isAddNew') as HTMLElement;
          element1.focus();
        } else {
          alert(response['status']);
        }
      });
    }
  
    updateSalesman(salesData) {
     this.service.post('master/salesman.php?type=editSalesman', JSON.stringify(salesData.value)).subscribe(response => {
        if (response['status'] === 'success') {
          this.isForm = false;
          this.isTable = true;
          this.getSalesman();
          alert('Customer Updated Successfully.');
        } else {
          alert('Please Try Again');
        }
      });
    }
  
    checkpatienttype(value) {
      if (value === 'Yes') {
        this.isChronic = true;
      } else {
        this.isChronic = false;
        this.isOther = false;
      }
    }
  
    getOtherVal(val) {
      if (val === 'Others' && this.getCheckVal === true) {
        this.isOther = true;
      } else {
        this.isOther = false;
      }
    }
  
    viewOrder(index, value) {
      if (value === 'edit') {
        this.isTable = false;
        this.isEditForm = true;
        this.selectSalesmanIds =  this.salesmanList[index];
      } else if (value === 'delete') {
        const id = this.salesmanList[index].id;
        this.service.get('master/salesman.php?type=deleteSalesman&id='+id).subscribe(response => {
          this.getSalesman();
        });
      }
   }
  
    filterTable(value) {
      this.salesman1 = [];
      let index = 0;
      const length = Object.keys(this.salesmanList).length;
      for (let i = 0; i < length; i++) {
        // tslint:disable-next-line:max-line-length
        const tempstring = (this.salesmanList[i].pharmacist_name + ' ' + this.salesmanList[i].reg_no).toLowerCase();
        value = value.toLowerCase();
        if ((tempstring).indexOf(value) > -1 ) {
          this.salesman1[index] = this.salesmanList[i];
          index++;
        }
      }
    }
  
    
  }
  