import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adjust-purchase-gst',
  templateUrl: './adjust-purchase-gst.component.html',
  styleUrls: ['./adjust-purchase-gst.component.scss']
})
export class AdjustPurchaseGSTComponent implements OnInit {
  isInit = true;
  from_date = '';
  to_date = '';
  month = '0';
  lists;
  selectedList;
  index = -1;
  adjustedGSTs;
  constructor(private service: DataAccessService) {
    const d = new Date();
    let n = d.getMonth();
    n = n + 1;
    let n1 = n + '';
    if (n1.length === 1) {
      n1 = '0' + n;
    }
    this.month = n1;
  }

  ngOnInit() {
    this.getAdjustedGST();
    this.getPurchaseGST();
  }

  getPurchaseGST() {
    let d = new Date();
    let n = d.getFullYear();
    this.from_date = n + '-' + this.month + '-' + '01';
    this.to_date = n + '-' + this.month + '-' + '31';
    this.service.get('purchase.php?type=getPurchaseGST&from_date=' + this.from_date + '&to_date=' + this.to_date).subscribe(response => {
      this.lists = response;
    });
  }

  editEntry(index) {
    this.index = index;
    this.isInit = false;
    this.selectedList = this.lists[index];
  }

  updateGST(value) {
    const gross_total = this.selectedList['gross_total'];
    const gst = gross_total * (value / 100);
    this.selectedList['gst'] = Math.round(gst);
    this.selectedList['cgst'] = Math.round(gst / 2);
    this.selectedList['sgst'] = Math.round(gst / 2);
    this.selectedList['net_total'] = gross_total + gst;
    this.lists[this.index] = this.selectedList;
  }

  update() {
    this.lists[this.index] = this.selectedList;
    this.index = -1;
    this.selectedList = [];
    this.isInit = true;
  }

  saveAdjustGST() {
    let d = new Date();
    let n = d.getFullYear();
    this.from_date = n + '-' + this.month + '-' + '01';
    this.to_date = n + '-' + this.month + '-' + '31';
    // tslint:disable-next-line:max-line-length
    this.service.post('adjustGST.php?type=adjustPurchaseGST&year=' + n + '&month=' + this.month + '&from_date=' + this.from_date + '&to_date=' + this.to_date, JSON.stringify(this.lists)).subscribe(response => {
      if (response['status'] === 'success') {
        alert('Adjusted GST saved successfully');
      }
    });
  }

  getAdjustedGST() {
    let d = new Date();
    let n = d.getFullYear();
    this.from_date = n + '-' + this.month + '-' + '01';
    this.to_date = n + '-' + this.month + '-' + '31';
    this.service.get('adjustGST.php?type=getAdjustedGST&from_date=' + this.from_date + '&to_date=' + this.to_date).subscribe(response => {
      this.adjustedGSTs = response;
    });
  }

  downloadexcel() {
    let d = new Date();
    let n = d.getFullYear();
    this.from_date = n + '-' + this.month + '-' + '01';
    this.to_date = n + '-' + this.month + '-' + '31';
    window.open(this.service.url1 + 'excel/adjustedpurchasegst.php?from_date=' + this.from_date + '&to_date=' + this.to_date, '_blank');
  }

}
