import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-shelf',
  templateUrl: './shelf.component.html',
  styleUrls: ['./shelf.component.scss']
})
export class ShelfComponent implements OnInit {
  isCollapsed = false;
  iconCollapse = 'icon-arrow-up';
  isForm = false;
  isTable = true;
  shelfList;
  purchases: any;
  shelfs1 = [];

  ngOnInit() {
    this.getSHELF();
  }
  constructor(private service: DataAccessService) { }

  getSHELF() {
    this.service.get('getdata.php?type=getShelfs')
    .subscribe(response => {
      this.shelfList = response;
      this.shelfs1 = this.shelfList;
      const element1 = document.getElementById('isAddNew') as HTMLElement;
      element1.focus();
    });
  }
  saveSHELF(shelfData) {
    this.service.post('master.php?type=saveSHELF', JSON.stringify(shelfData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getSHELF();
        alert('SHELF Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  filterTable(value) {
    this.shelfs1 = [];
    let index = 0;
    const length = Object.keys(this.shelfList).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.shelfList[i].shelf).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.shelfs1[index] = this.shelfList[i];
        index++;
      }
    }
  }

  viewShelf(id, value, value1) {
    if (value === 'edit') {
      let value2 = prompt("Shelf Name:", value1);
      this.service.get('master.php?type=updateShelf&sr=' + id + '&value=' + value2).subscribe(response => {
        if (response['status'] === 'success') {
          this.getSHELF();
        }
      });
    } else {
      this.service.get('master.php?type=deleteShelf&sr=' + id).subscribe(response => {
        if (response['status'] === 'success') {
          this.getSHELF();
        }
      });
    }
  }
}
