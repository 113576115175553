import { Component, OnInit, ViewChild } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
import { IOption as ngSelectOption, SelectComponent } from 'ng-select';
declare var $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @ViewChild('mfg') selectComponent: SelectComponent;
  isForm = false;
  isTable = true;
  generics;
  categories;
  packsizes;
  dosages;
  manufactures;
  isEdit =  false;
  isNewGeneric = 0;
  isNewManufacturer = 0;
  selectedResult=[];
  m_name = '';
  s_code = '';
  products1: any = [];
  distList;
  p_name='';
  p_cat='';
  d_form='';

  
  element1;

  ngOnInit() {
    //this.element1 = document.getElementById('isAddNew') as HTMLElement;
    this.getProduct();
    this.getGenericDetails();
    this.getCategoryDetails();
    this.getPacksizeDetails();
    this.getDosageDetails();
    this.getmanfDetails();
  }
  constructor(private service: DataAccessService) {
  }

  getProduct() {
    this.service.get('master/product.php?type=getProducts')
    .subscribe(response => {
      this.products1 = response;
      this.filterProducts();
      //const element1 = document.getElementById('isAddNew') as HTMLElement;
      //this.element1.focus();
    });
  }

  getGenericDetails() {
    this.service.get('master.php?type=getGenericDetails')
    .subscribe(response => {
      this.generics = response;
    });
  }
  getCategoryDetails() {
    this.service.get('master.php?type=getCategoryDetails')
    .subscribe(response => {
      this.categories = response;
    });
  }
  getPacksizeDetails() {
    this.service.get('master.php?type=getPacksizeDetails')
    .subscribe(response => {
      this.packsizes = response;
    });
  }
  getDosageDetails() {
    this.service.get('master.php?type=getDOSAGEDetails')
    .subscribe(response => {
      this.dosages = response;
    });
  }
  getmanfDetails() {
    this.service.get('master.php?type=getmanfDetails')
    .subscribe(response => {
      this.manufactures = response;
    });
  }
  saveProduct(productData) {
   this.service.post('master/product.php?type=saveProduct', JSON.stringify(productData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.isForm = false;
        this.isTable = true;
        this.getProduct();
        alert('Product Added Successfully.');
      } else {
        alert('Please Try Again');
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }


  newGeneric() {
    if (this.isNewGeneric === 0) {
      const temp = confirm('Do you want to Add new Generic Name?');
      if (temp) {
        this.isNewGeneric = 1;
        const temp1 = prompt('Generic Name');
        this.service.get('purchase.php?type=addGeneric&generic=' + temp1).subscribe(response => {
          this.isNewGeneric = 1;
          if (response['status'] === 'success') {
            this.getGenericDetails();
          }
        });
      }
    }
  }

  showModel() {
    if (this.isNewManufacturer === 0) {
      const temp = confirm('Do you want to Add new Manufacturer?');
      if (temp) {
        this.isNewManufacturer = 1;
        $('#manufacturerModal').modal('show');
      }
    }
  }

  newManufacturer() {
    $('#manufacturerModal').modal('hide');
    this.service.get('purchase.php?type=addManufacturer&m_name=' + this.m_name + '&s_code=' + this.s_code).subscribe(response => {
      this.isNewManufacturer = 1;
      if (response['status'] === 'success') {
        this.m_name = '';
        this.s_code = '';
        this.getmanfDetails();
        this.selectComponent.open();
      }
    });
  }

  filterTable(value) {
    this.products1 = [];
    let index = 0;
    const length = Object.keys(this.distList).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.distList[i].p_name + ' ' + this.distList[i].pack_size + ' ' + this.distList[i].p_cat + ' ' + this.distList[i].mrp + ' ' + this.distList[i].d_form + ' ' + this.distList[i].m_name + ' ' + this.distList[i].generic).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.products1[index] = this.distList[i];
        index++;
      }
    }
  }

  edit(index){
    this.selectedResult = this.distList[index];
    this.isEdit = true;
  }
  updateProducts(productData) {
    let temp = productData.value;
    temp['p_no']=this.selectedResult['p_no'];
    this.service.post('master/product.php?type=editProduct', JSON.stringify(productData.value))
     .subscribe(response => {
       if (response['status'] === 'success') {
         this.isEdit = false;
         this.isTable = true;
         this.getProduct();
         alert('Product Update Successfully.');
       } else {
         alert('Please Try Again');
       }
       },
     (error: Response) => {
       if (error.status === 400) {
         alert('An error has occurred.');
       } else {
         alert('An error has occurred, http status:' + error.status);
       }
     });
   }
 
   filterProducts(){
    this.distList = [];
    console.log(this.p_cat)
    console.log(this.d_form)
    for (let i = 0; i < this.products1.length; i++) {
      let prod = this.products1[i];
      if (prod['p_name'].toUpperCase().includes(this.p_name.toUpperCase()) && prod['p_cat'].toUpperCase().includes(this.p_cat.toUpperCase()) && prod['d_form'].toUpperCase().includes(this.d_form.toUpperCase())) {
        this.distList[this.distList.length] = prod;
      }
    }
  }
}

