import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  role;
  isSalesman = false;
  isAdmin = false;
  isPurchase = false;
  constructor(private router: Router) {
    this.role = localStorage.getItem('role');
    if (this.role === 'Admin') {
      this.isAdmin = true;
      this.isSalesman = false;
      this.isPurchase = false;
    } else if (this.role === 'Salesman') {
      this.isSalesman = true;
      this.isPurchase = false;
      this.isAdmin = false;
    } else if (this.role === 'Purchase') {
      this.isPurchase = true;
      this.isSalesman = false;
      this.isAdmin = false;
    }
  }

  navigateTo(value) {
      if (value) {
          this.router.navigate([value]);
      }
      return false;
  }

  ngOnInit() {
  }
}
