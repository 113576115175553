import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loan-master',
  templateUrl: './loan-master.component.html',
  styleUrls: ['./loan-master.component.scss']
})
export class LoanMasterComponent implements OnInit {
  loans;
  isForm = false;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getLoans();
  }

  getLoans() {
    this.service.get('getdata.php?type=getLoans').subscribe(response => {
      this.loans = response;
    });
  }

  saveLoan(data) {
    this.service.post('savedata.php?type=saveLoan', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'success') {
        alert('Loan has been successfully saved.');
        this.isForm = false;
        data.reset();
        this.getLoans();
      }
    });
  }

}
